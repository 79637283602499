import { Roots } from "./Root";
//Header
const Home = "Casa";
const Dominican = "Dominicano";

const Evidence = "Evidenza";
const JesusHeader = "Gesù Cristo";
const Holy = "La Santissima Trinità è Amore";
const Obey = "OBBEDIRE";
const Header = "La Verità";
const SaintsHeader = "Santi della Chiesa di Gesù Cristo";

//Footer
const Footer = "La Verità";
const Acutis = "Ispirato da Carlo Acutis";

//Home tiles
const Miracles = "Miracoli";
const MiraclesPar =
  "Non disprezzare le parole dei profeti, ma prova tutto; aggrappati a ciò che è buono. - 1 Tessalonicesi 5:20–21";

const Medal = "Medaglia";
const MedalPar =
  "Medaglia di San Benedetto, spedizione gratuita e gratuita negli Stati Uniti e in Canada";
const MedalPage1 =
  "Questo oggetto religioso è anche un simbolo cristiano dell'apertura di porte e di percorsi difficili. La tradizione vuole che protegga dalle maledizioni, dal male e dal vizio, protegga dalle malattie e protegga la buona salute. La Medaglia di San Benedetto era un dono che Padre Pio faceva spesso in dono ai suoi visitatori.";
const MedalForm = "Compila il modulo";

const Directory = "Directorio";
const DirectoryPar = "Direttorio delle Chiese";

const Map = "Directorio";
const MapPar = "Direttorio delle Chiese";

const Jesus = "Jesus";
const JesusPar =
  "Nasce il figlio di dio. Mt 1. La cronologia di Gesù Cristo Figlio di Dio";

const Jesusle = "Jesusle";
const JesuslePar = "Indovina chi lha scritto? Provalo";

const Disciple = "Discepolo";
const DisciplePar =
  "Allora Gesù disse ai suoi discepoli: Se uno vuol venire dietro a me, rinunzi a se stesso e prenda la sua croce e mi segua Matteo 16:24";

const Saints = "Santi";
const SaintsPar = "La cronologia dei santi della chiesa di Gesù Cristo";

const Quiz = "Quiz";
const QuizPar =
  "Forse l'Anticristo. '...colui che rinnega il Padre e il Figlio' 1 Giovanni 2:22";

const Verse = "Vederci";
const VersePar =
  "I versetti della Bibbia dell'Anticristo. 'Poiché appariranno falsi messia e falsi profeti e produrranno grandi segni e presagi'... Mt 24,24";

//Quiz
const Start = "Inizio";
//Result
const Restart = "Ricomincia";
const Share = "Condividi il tuo risultato";

const About = "Missione";
const Mission =
  "La nostra missione è portare alla luce l'unico vero, nostro Signore Gesù Cristo!";

//Verse
const OneJohn218 =
  "Ragazzi, è l’ultima ora. Come avete udito, l’anticristo deve venire, e di fatto già ora sono sorti molti anticristi. Da ciò conosciamo che è l’ultima ora — 1 Giovanni 2:18";
const OneJohn222 =
  "Chi è il bugiardo se non colui che nega che Gesù è il Cristo? Egli è l’anticristo, che nega il Padre e il Figlio — 1 Giovanni 2:22";
const OneJohn423 =
  "Da questo conoscete lo Spirito di Dio: ogni spirito, il quale riconosce pubblicamente che Gesù Cristo è venuto nella carne, è da Dio; 3 e ogni spirito che non riconosce pubblicamente Gesù[a], non è da Dio, ma è lo spirito dell’anticristo. Voi avete sentito che deve venire; e ora è già nel mondo — 1 Giovanni 4:2–3";
const TwoJohn217 =
  "Poiché molti seduttori sono usciti per il mondo, i quali non riconoscono pubblicamente che Gesù Cristo è venuto in carne. Quello è il seduttore e l’anticristo — 2 Giovanni 1:7";
const TwoThe214 =
  "Ora, fratelli, circa la venuta del Signore nostro Gesù Cristo e il nostro incontro con lui, vi preghiamo 2 di non lasciarvi così presto sconvolgere la mente, né turbare sia da pretese ispirazioni, sia da discorsi, sia da qualche lettera data come nostra, come se il giorno del Signore[a] fosse già presente. 3 Nessuno vi inganni in alcun modo; poiché quel giorno non verrà se prima non sia venuta l’apostasia e non sia stato manifestato l’uomo del peccato[b], il figlio della perdizione, 4 l’avversario, colui che s’innalza sopra tutto ciò che è chiamato Dio od oggetto di culto; fino al punto da porsi a sedere nel tempio di Dio [come Dio], mostrando se stesso e proclamandosi Dio — 2 Tessalonicesi 2:1–4";
const TwoThe2710 =
  "nfatti il mistero dell’empietà è già in atto, soltanto c’è chi ora lo trattiene, finché sia tolto di mezzo. 8 E allora sarà manifestato l’empio, che il Signore Gesù[a] distruggerà con il soffio della sua bocca,[b] e annienterà con l’apparizione della sua venuta. 9 La venuta di quell’empio avrà luogo, per l’azione efficace di Satana, con ogni sorta di opere potenti, di segni e di prodigi bugiardi, 10 con ogni tipo d’inganno e d’iniquità a danno di quelli che periscono perché non hanno aperto il cuore all’amore della verità[c] per essere salvati — 2 Tessalonicesi 2:7–10";
const Mat2424 =
  "perché sorgeranno falsi cristi e falsi profeti, e faranno grandi segni e prodigi da sedurre, se fosse possibile, anche gli eletti — Matteo 24:24";
const Mar132122 =
  "21 Allora, se qualcuno vi dice: “Il Cristo eccolo qui, eccolo là”[a], non credeteci; 22 perché sorgeranno falsi cristi e falsi profeti e faranno segni e prodigi per sedurre, se fosse possibile, [anche] gli eletti — Marco 13:21-22";

const Y21Nov18Jenifer =
  "Our Lord Jesus to Jennifer on November 18th, 2021:\nMy child, I ask My children that each day at the Hour of Mercy, [1] beginning on the first Sunday of Advent and continuing until the Feast Day of My Divine Mercy, that my children would recite one chaplet for those who are not prepared to meet me at their hour of death. I ask My children to also recite one Rosary, particularly at this hour, if possible, praying for protection of families while meditating on the mysteries. I ask My children to recite the St. Michael prayer for strength and protection from the enemy’s attacks. Now go forth and tell the world of my requests, for I am Jesus, and my mercy and justice will prevail. ";

//Miracles
const A1656_Cava_dei_Tirreni = "Cava dei Tirreni, 1656";

export function itLang() {
  return {
    Home,
    Dominican,
    Evidence,
    Cards,
    Header,
    Footer,
    Acutis,
    JesusHeader,
    Holy,
    Obey,
    Medal,
    MedalPar,
    Directory,
    DirectoryPar,
    Map,
    MapPar,
    MedalPage1,
    MedalForm,
    Miracles,
    MiraclesPar,
    Jesus,
    JesusPar,
    Jesusle,
    JesuslePar,
    Disciple,
    DisciplePar,
    Saints,
    SaintsPar,
    Quiz,
    QuizPar,
    Verse,
    VersePar,
    SaintsCards,
    SaintsHeader,
    Start,
    Restart,
    Share,
    About,
    Mission,
    OneJohn218,
    OneJohn222,
    OneJohn423,
    TwoJohn217,
    TwoThe214,
    TwoThe2710,
    Mat2424,
    Mar132122,
    Y21Nov18Jenifer,

    //Miracles
    A1656_Cava_dei_Tirreni,
  };
}

const Cards = [
  {
    date: "Senza tempo",
    title: "La creazione di tutto",
    subtitle: "Universo",
    par: "Dio crea tutto. Gen 1",
    image: Roots().Timeless,
  },
  {
    date: "~ 6000 BC",
    title: "Adam e Eve",
    subtitle: "Eden",
    par: "Il giardino. Gen 2-4",
    image: Roots().BC6000,
  },
  {
    date: "~ 5700 BC",
    title: "Arco di Noè",
    subtitle: "Medio Oriente",
    par: "Dio ha cancellato l'umanità con 40 giorni di pioggia. Il patto di Dio con Noè. Gen 6-9",
    image: Roots().BC5700,
  },
  {
    date: "~ 1900 BC",
    title: "Dio parla ad Abramo",
    subtitle: "Palestina",
    par: "L'obbedienza è sempre premiata. Il patto di Dio con Abramo. Gen 12",
    image: Roots().BC1900,
  },
  {
    date: "~ 1850 BC",
    title: "Il legame di Isacco",
    subtitle: "Canaan",
    par: "L'obbedienza è sempre premiata. Gen 22",
    image: Roots().BC1850,
  },
  {
    date: "~ 1800 BC",
    title: "Giacobbe si chiama Israele",
    subtitle: "Canaan",
    par: "Dio chiama Giacobbe Israele. Gen 32:28",
    image: Roots().BC1800,
  },
  {
    date: "~ 1750 BC",
    title: "Giuseppe è il secondo in Egitto",
    subtitle: "Egitto",
    par: "Il potere dei sogni. Dio parla alle persone attraverso i sogni. Gen 45",
    image: Roots().BC1750,
  },
  {
    date: "~ 1250 BC",
    title: "Mosè dieci piaghe",
    subtitle: "Egitto",
    par: "La potenza di Dio. Ex 8",
    image: Roots().BC1250,
  },
  {
    date: "~ 1000 BC",
    title: "Regno di David",
    subtitle: "Canaan",
    par: "David è unto da Samuele. Sam 16",
    image: Roots().BC1000,
  },
  {
    date: "~ 550 BC",
    title: "Esilio babilonese",
    subtitle: "Canaan",
    par: "Gli ebrei portati in esilio a Babilonia dopo la caduta di Gerusalemme. Geremia 20",
    image: Roots().BC550,
  },
  {
    date: "0",
    title: "Nasce il Cristo Promesso",
    subtitle: "Betlemme",
    par: "Nasce il figlio di dio. Mt 1",
    image: Roots().AC0,
  },
  {
    date: "33 AC",
    title: "Il Cristo Promesso",
    subtitle: "Gerusalemme",
    par: "Il figlio di dio è crocifisso. Mt 20:28",
    image: Roots().AC33,
  },
  {
    date: "37 AC",
    title: "Conversione di Paolo (Saulo di Tarso)",
    subtitle: "Palestina",
    par: "Saulo di Tarso sulla via di Damasco, Paolo. Atti 9",
    image: Roots().AC37,
  },
  {
    date: "12 Oct 40 AC",
    title:
      "La prima apparizione della Beata Vergine Maria. Nostra Signora del Pilar",
    subtitle: "Zaragoza, Spagna",
    par: "Maria, mentre viveva a Gerusalemme, apparve in modo soprannaturale all'apostolo Giacomo il Maggiore nel 40 dC mentre predicava nell'attuale Spagna",
    image: Roots().AC40,
  },
  {
    date: "331 AC",
    title: "Le cinquanta Bibbie di Costantino",
    subtitle: "Costantinopoli",
    par: "Costantino fu il primo imperatore romano a convertirsi al Cristianesimo",
    image: Roots().AC331,
  },
  {
    date: "405 AC",
    title: "Bibbia di San Girolamo",
    subtitle: "Betlemme",
    par: "Nel 390 si dedicò alla traduzione della Bibbia ebraica dall'ebraico originale. Ha completato questo lavoro entro il 405.",
    image: Roots().AC405,
  },
  {
    date: "480 AC",
    title: "San Benedetto da Norcia",
    subtitle: "Norcia, Umbria",
    par: "Benedetto fondò dodici comunità per monaci. Il principale risultato di Benedetto, la sua 'Regola di San Benedetto', contiene una serie di regole che i suoi monaci devono seguire.",
    image: Roots().AC480,
  },
  {
    date: "1182 AC",
    title: "San Francesco d'Assisi",
    subtitle: "Assisi",
    par: "Fondò l'Ordine maschile dei Frati Minori, l'Ordine femminile di S. Chiara, il Terz'Ordine di S. Francesco e la Custodia di Terra Santa. Francesco è una delle figure religiose più venerate della cristianità. Si dice che Francesco abbia avuto una visione intorno al 13 settembre 1224, festa dell'Esaltazione della Croce, a seguito della quale ricevette le stimmate.",
    image: Roots().AC1182,
  },
  {
    date: "16 July 1194",
    title: "Santa Chiara d'Assisi",
    subtitle: "Assisi",
    par: "Fu una santa italiana e una delle prime seguaci di Francesco d'Assisi. Fondò l'Ordine delle Povere Dame, un ordine religioso monastico femminile nella tradizione francescana, e scrisse la loro Regola di Vita, la prima serie di linee guida monastiche note per essere state scritte da una donna.",
    image: Roots().AC1194,
  },
  {
    date: "1415",
    title: "Medaglia di San Benedetto",
    subtitle: "Abbazia di San Michele",
    par: "La Medaglia di San Benedetto è una medaglia sacramentale cristiana contenente simboli e testi relativi alla vita di San Benedetto da Norcia.",
    image: Roots().AC1415,
  },
  {
    date: "27 Nov 1830",
    title: "La Medaglia Miracolosa - Santa Caterina Labouré",
    subtitle: "Parigi, Francia",
    par: "La Medaglia Miracolosa, nota anche come Medaglia di Nostra Signora delle Grazie, è una medaglia devozionale, il cui disegno è stato ideato da Santa Caterina Labouré in seguito alle sue apparizioni della Beata Vergine Maria in Rue du Bac, Parigi, Francia. Apocalisse 12 (E apparve in cielo una grande meraviglia: una donna vestita di sole, e la luna sotto i suoi piedi, e sul suo capo una corona di dodici stelle)",
    image: Roots().AC1830,
  },
  {
    date: "25 May 1887",
    title: "San Padre Pio da Pietrelcina",
    subtitle: "Pietrelcina, Benevento",
    par: "Padre Pio divenne famoso per aver esposto le stimmate per la maggior parte della sua vita, generando così molto interesse. Fu beatificato e canonizzato da papa Giovanni Paolo II.",
    image: Roots().AC1887,
  },
  {
    date: "25 Aug 1905",
    title: "Santa Faustina Kowalska",
    subtitle: "Glogowiec",
    par: "Per tutta la sua vita, Kowalska riferì di avere visioni di Gesù e conversazioni con lui, che annotò nel suo diario, successivamente pubblicato come Il diario di Santa Maria Faustina Kowalska: Divina Misericordia nella mia anima.",
    image: Roots().AC1905,
  },
  {
    date: "13 May 1917 - 13 Sep 1917",
    title: "Nostra Signora di Fatima",
    subtitle: "Cova da Iria, Fátima",
    par: "Nostra Signora di Fátima, è un titolo cattolico di Maria, madre di Gesù basato sulle apparizioni mariane riportate nel 1917 da tre pastorelli, Lucia, Francisco e Jacinta alla Cova da Iria, a Fátima, in Portogallo.",
    image: Roots().AC1917,
  },
  {
    date: "1961 - 1965",
    title: "Apparizioni della Beata Vergine Maria Garabandal",
    subtitle: "Garabandal, Spagna",
    par: "Le apparizioni di Garabandal sono apparizioni di San Michele Arcangelo e della Beata Vergine Maria che si dice siano avvenute dal 1961 al 1965 a quattro giovani studentesse nel villaggio rurale di San Sebastián de Garabandal nella catena montuosa della Peña Sagra nella comunità autonoma della Cantabria nel nord della Spagna.",
    image: Roots().AC1961,
  },
  {
    date: "1981 - Presente",
    title: "Nostra Signora di Medjugorje",
    subtitle: "Medjugorje, Bosnia e Herzegovina",
    par: "Nostra Signora di Medjugorje, chiamata anche Regina della Pace e Madre del Redentore, è il titolo dato alle visioni della Beata Vergine Maria iniziate nel 1981 a sei adolescenti croati a Medjugorje, in Bosnia ed Erzegovina",
    image: Roots().AC1981,
  },
];

const SaintsCards = [
  {
    date: "230 AC",
    title: "Santa Cecilia di Roma",
    subtitle: "Roma",
    par: "Martire romano venerato nelle chiese cattolica, ortodossa, anglicana e in alcune chiese luterane, come la Chiesa di Svezia. Divenne la protettrice della musica e dei musicisti, essendo scritto che, mentre i musicisti suonavano al suo matrimonio, Cecilia cantava nel suo cuore al Signore. A lei, e alla sua festa, il 22 novembre sono dedicate composizioni musicali.",
    image: Roots().AC230,
  },
  {
    date: "405 AC",
    title: "Bibbia di San Girolamo",
    subtitle: "Betlemme",
    par: "Nel 390 si dedicò alla traduzione della Bibbia ebraica dall'ebraico originale. Ha completato questo lavoro entro il 405.",
    image: Roots().AC405,
  },
  {
    date: "480 AC",
    title: "San Benedetto da Norcia",
    subtitle: "Norcia, Umbria",
    par: "Benedetto fondò dodici comunità per monaci. Il principale risultato di Benedetto, la sua 'Regola di San Benedetto', contiene una serie di regole che i suoi monaci devono seguire.",
    image: Roots().AC480,
  },
  {
    date: "1070 AC",
    title: "Sant'Isidoro il contadino",
    subtitle: "Madrid, Spagna",
    par: "Noto per la sua pietà verso i poveri e gli animali. È il patrono cattolico dei contadini. La mattina prima di andare al lavoro, Isidoro di solito assisteva alla messa in una delle chiese di Madrid. Un giorno, i suoi compagni contadini si lamentarono con il loro padrone che Isidoro era sempre in ritardo al lavoro la mattina. Dopo un'indagine il maestro trovò Isidoro in preghiera mentre un angelo stava facendo l'aratura per lui.",
    image: Roots().AC1070,
  },
  {
    date: "1182 AC",
    title: "San Francesco d'Assisi",
    subtitle: "Assisi",
    par: "Fondò l'Ordine maschile dei Frati Minori, l'Ordine femminile di S. Chiara, il Terz'Ordine di S. Francesco e la Custodia di Terra Santa. Francesco è una delle figure religiose più venerate della cristianità. Si dice che Francesco abbia avuto una visione intorno al 13 settembre 1224, festa dell'Esaltazione della Croce, a seguito della quale ricevette le stimmate.",
    image: Roots().AC1182,
  },
  {
    date: "16 Jul 1194",
    title: "Santa Chiara d'Assisi",
    subtitle: "Assisi",
    par: "Fu una santa italiana e una delle prime seguaci di Francesco d'Assisi. Fondò l'Ordine delle Povere Dame, un ordine religioso monastico femminile nella tradizione francescana, e scrisse la loro Regola di Vita, la prima serie di linee guida monastiche note per essere state scritte da una donna.",
    image: Roots().AC1194,
  },
  {
    date: "27 Nov 1830",
    title: "La Medaglia Miracolosa - Santa Caterina Labouré",
    subtitle: "Parigi, Francia",
    par: "La Medaglia Miracolosa, nota anche come Medaglia di Nostra Signora delle Grazie, è una medaglia devozionale, il cui disegno è stato ideato da Santa Caterina Labouré in seguito alle sue apparizioni della Beata Vergine Maria in Rue du Bac, Parigi, Francia. Apocalisse 12 (E apparve in cielo una grande meraviglia: una donna vestita di sole, e la luna sotto i suoi piedi, e sul suo capo una corona di dodici stelle)",
    image: Roots().AC1830,
  },
  {
    date: "25 May 1887",
    title: "San Padre Pio da Pietrelcina",
    subtitle: "Pietrelcina, Benevento",
    par: "Padre Pio divenne famoso per aver esposto le stimmate per la maggior parte della sua vita, generando così molto interesse. Fu beatificato e canonizzato da papa Giovanni Paolo II.",
    image: Roots().AC1887,
  },
  {
    date: "25 Aug 1905",
    title: "Santa Faustina Kowalska",
    subtitle: "Glogowiec",
    par: "Per tutta la sua vita, Kowalska riferì di avere visioni di Gesù e conversazioni con lui, che annotò nel suo diario, successivamente pubblicato come Il diario di Santa Maria Faustina Kowalska: Divina Misericordia nella mia anima.",
    image: Roots().AC1905,
  },
];
