import { Roots } from "./Root";

// Header
const Home = "Inicio";
const Dominican = "Dominicano";
const Evidence = "Evidencia";
const Header = "La Verdad";
const JesusHeader = "Jesucristo Rey"; //La Cronología de
const Holy = "La Santísima Trinidad es Amor";
const Obey = "OBEDECER";
const SaintsHeader = "Los santos de la iglesia de Jesucristo";

// Footer
const Footer = "La Verdad";
const Acutis = "Inspirado por Carlo Acutis";

//Home tiles
const Miracles = "Milagros";
const MiraclesPar =
  "No menospreciéis las profecías. Examinadlo todo; retened lo bueno. - 1 Tesalonicenses 5:20-21";

const Medal = "Medalla";
const MedalPar =
  "Medalla de San Benedicto, Gratis y bendecida, envios gratis a USA y Canada";
const MedalPage1 =
  "Este objeto religioso es también un símbolo cristiano de abrir puertas y abrir caminos difíciles. La tradición sostiene que protege de las maldiciones, el mal y el vicio, protege contra las enfermedades y protege la buena salud. La Medalla de San Benedicto era un obsequio que el Padre Pío solía regalar a sus visitantes.";
const MedalForm = "Llenar formulario";

const Directory = "Directorio";
const DirectoryPar = "Directorio de Iglesias";

const Map = "Directorio";
const MapPar = "Directorio de Iglesias";

const Jesus = "Jesus";
const JesusPar =
  "Ha nacido el hijo de Dios. Mt 1. La cronología de Jesucristo el Hijo de Dios";

const Jesusle = "Jesusle";
const JesuslePar = "Trivia, ¿puedes adivinar quién lo escribió? Pruébalo";

const Disciple = "Discipulo";
const DisciplePar =
  "Y dijo luego a los discípulos: ―Si alguien desea seguirme, niéguese a sí mismo, tome su cruz y sígame. Mateo 16:24";

const Saints = "Santos";
const SaintsPar = "La cronología de los santos de la iglesia de Jesucristo";

const Quiz = "Trivia";
const QuizPar =
  "La prueba del Anticristo. '...el que niega al Padre y al Hijo' 1 Juan 2:22";

const Verse = "Verso";
const VersePar =
  "Versos del Anticristo en la Biblia. 'Porque se levantarán falsos Cristos, y falsos profetas, y harán grandes señales y prodigios'... Mt 24:24";

//Quiz
const Start = "Comenzar";
//Result
const Restart = "Reinicia";
const Share = "Comparte los resultados";

const About = "Info";
const Mission =
  "Nuestra misión es sacar a la luz la única verdadera verdad, ¡nuestro Señor Jesucristo!";

//Verse
const OneJohn218 =
  "Hijitos, ya es el último tiempo; y según vosotros oísteis que el anticristo viene, así ahora han surgido muchos anticristos; por esto conocemos que es el último tiempo. — 1 Juan 2:18";
const OneJohn222 =
  "¿Quién es el mentiroso, sino el que niega que Jesús es el Cristo? Este es anticristo, el que niega al Padre y al Hijo. — 1 Juan 2:22";
const OneJohn423 =
  "En esto conoced el Espíritu de Dios: Todo espíritu que confiesa que Jesucristo ha venido en carne, es de Dios; y todo espíritu que no confiesa que Jesucristo ha venido en carne, no es de Dios; y este es el espíritu del anticristo, el cual vosotros habéis oído que viene, y que ahora ya está en el mundo. — 1 Juan 4:2–3";
const TwoJohn217 =
  "Porque muchos engañadores han salido por el mundo, que no confiesan que Jesucristo ha venido en carne. Quien esto hace es el engañador y el anticristo. — 2 Juan 1:7";
const TwoThe214 =
  "Pero con respecto a la venida de nuestro Señor Jesucristo, y nuestra reunión con él, os rogamos, hermanos,  que no os dejéis mover fácilmente de vuestro modo de pensar, ni os conturbéis, ni por espíritu, ni por palabra, ni por carta como si fuera nuestra, en el sentido de que el día del Señor está cerca. Nadie os engañe en ninguna manera; porque no vendrá sin que antes venga la apostasía, y se manifieste el hombre de pecado, el hijo de perdición, el cual se opone y se levanta contra todo lo que se llama Dios o es objeto de culto; tanto que se sienta en el templo de Dios como Dios, haciéndose pasar por Dios. — 2 Tesalonicenses 2:1-4";
const TwoThe2710 =
  "Porque ya está en acción el misterio de la iniquidad; sólo que hay quien al presente lo detiene, hasta que él a su vez sea quitado de en medio. Y entonces se manifestará aquel inicuo, a quien el Señor matará con el espíritu de su boca, y destruirá con el resplandor de su venida; inicuo cuyo advenimiento es por obra de Satanás, con gran poder y señales y prodigios mentirosos, y con todo engaño de iniquidad para los que se pierden, por cuanto no recibieron el amor de la verdad para ser salvos. — 2 Tesalonicenses 2:7-10";
const Mat2424 =
  "Porque se levantarán falsos Cristos, y falsos profetas, y harán grandes señales y prodigios, de tal manera que engañarán, si fuere posible, aun a los escogidos. — Mateo 24:24";
const Mar132122 =
  "Entonces si alguno os dijere: Mirad, aquí está el Cristo; o, mirad, allí está, no le creáis. Porque se levantarán falsos Cristos y falsos profetas, y harán señales y prodigios, para engañar, si fuese posible, aun a los escogidos. — Marco 13:21-22";

//Miracles
const Y21Nov18Jenifer =
  "Nuestro Señor Jesús a Jennifer el 18 de noviembre de 2021: \nHija Mía, les pido a Mis hijos que cada día en la Hora de la Misericordia, [1] comenzando el primer domingo de Adviento y continuando hasta la Fiesta de Mi Divina Misericordia, que mis hijos reciten una coronilla por aquellos que no están preparados para encontrarse conmigo en la hora de su muerte. Les pido a Mis hijos que también recen un Rosario, particularmente a esta hora, si es posible, rezando por la protección de las familias mientras meditan en los misterios. Les pido a Mis hijos que reciten la oración de San Miguel por fuerza y ​​protección de los ataques del enemigo. Ahora ve y cuéntale al mundo mis peticiones, porque yo soy Jesús, y mi misericordia y mi justicia prevalecerán.";

export function esLang() {
  return {
    Home,

    Dominican,
    Evidence,
    Cards,
    Header,
    Footer,
    Acutis,
    JesusHeader,
    Holy,
    Obey,
    Medal,
    MedalPar,
    Directory,
    DirectoryPar,
    Map,
    MapPar,
    MedalPage1,
    MedalForm,
    Miracles,
    MiraclesPar,
    Jesus,
    JesusPar,
    Jesusle,
    JesuslePar,
    Disciple,
    DisciplePar,
    Saints,
    SaintsPar,
    Quiz,
    QuizPar,
    Verse,
    VersePar,
    SaintsCards,
    SaintsHeader,
    Start,
    Restart,
    Share,
    About,
    Mission,
    OneJohn218,
    OneJohn222,
    OneJohn423,
    TwoJohn217,
    TwoThe214,
    TwoThe2710,
    Mat2424,
    Mar132122,

    //Miracles
    Y21Nov18Jenifer,
  };
}

const Cards = [
  {
    date: "Sin Tiempo",
    title: "La creación de todo",
    subtitle: "Universo",
    par: "Dios crea todo. Gen 1",
    image: Roots().Timeless,
  },
  {
    date: "~ 6000 BC",
    title: "Adam y Eva",
    subtitle: "Eden",
    par: "El jardín. Gen 2-4",
    image: Roots().BC6000,
  },
  {
    date: "~ 5700 BC",
    title: "El Arca de Noe",
    subtitle: "Medio Oriente",
    par: "Dios borró a la humanidad con 40 días de lluvia. El pacto de Dios con Noé. Gen 6-9",
    image: Roots().BC5700,
  },
  {
    date: "~ 1900 BC",
    title: "Dios le habla a Abraham",
    subtitle: "Palestina",
    par: "Obediencia es clave para los siervos de Dios. El pacto de Dios con Abraham. Gen 12",
    image: Roots().BC1900,
  },
  {
    date: "~ 1850 BC",
    title: "El ofrecimiento de Isaac",
    subtitle: "Canaan",
    par: "Obediencia es clave para los siervos de Dios. Gen 22",
    image: Roots().BC1850,
  },
  {
    date: "~ 1800 BC",
    title: "Jacob es llamado Israel",
    subtitle: "Canaan",
    par: "Dios renombra Jacob como Israel. Gen 32:28",
    image: Roots().BC1800,
  },
  {
    date: "~ 1750 BC",
    title: "Jose, el segundo en Egypto",
    subtitle: "Egypto",
    par: "El poder de los sueños. Dios le habla a la gente a través de los sueños. Gen 45",
    image: Roots().BC1750,
  },
  {
    date: "~ 1250 BC",
    title: "Moses y las diez plagas",
    subtitle: "Egypt",
    par: "El poder del verdadero Dios. Ex 8",
    image: Roots().BC1250,
  },
  {
    date: "~ 1000 BC",
    title: "El Rey David",
    subtitle: "Canaan",
    par: "David es ungido por Samuel. Sam 16",
    image: Roots().BC1000,
  },
  {
    date: "~ 550 BC",
    title: "El exilio en Babylonia",
    subtitle: "Canaan",
    par: "Los judíos son llevados al exilio en Babilonia después de la caída de Jerusalén. Jeremiah 20",
    image: Roots().BC550,
  },
  {
    date: "0",
    title: "El Cristo prometido nace",
    subtitle: "Belem",
    par: "Nace el hijo de Dios. Mt 1",
    image: Roots().AC0,
  },
  {
    date: "33 AC",
    title: "El Cristo Prometido",
    subtitle: "Jerusalem",
    par: "El hijo de Dios es crucificado. Mt 20:28",
    image: Roots().AC33,
  },
  {
    date: "37 AC",
    title: "La conversion de Pablo (Saul de Tarsus)",
    subtitle: "Palestina",
    par: "Saul de Tarsus en la via a Damasco, Pablo. Hechos 9",
    image: Roots().AC37,
  },
  {
    date: "12 October 40 AC",
    title:
      "La primera aparición de la Santísima Virgen María. Nuestra Señora del Pilar",
    subtitle: "Zaragoza, España",
    par: "María, mientras vivía en Jerusalén, se apareció sobrenaturalmente al apóstol Santiago el Mayor en el año 40 d.C. mientras predicaba en lo que ahora es España",
    image: Roots().AC40,
  },
  {
    date: "331 AC",
    title: "Las Cincuenta Biblias de Constantino",
    subtitle: "Constantinopla",
    par: "Constantino fue el primer emperador romano en convertirse al cristianismo.",
    image: Roots().AC331,
  },
  {
    date: "405 AC",
    title: "La Biblia de Santo Jerónimo",
    subtitle: "Belem",
    par: "Hacia el 390 se dedicó a traducir la Biblia hebrea del hebreo original. Completó este trabajo en 405.",
    image: Roots().AC405,
  },
  {
    date: "480 AC",
    title: "San Benedicto de Nursia",
    subtitle: "Nursia, Umbria",
    par: "Benedicto fundó doce comunidades de monjes. El principal logro de Benedicto XVI, su 'Regla de San Benedicto', contiene un conjunto de reglas que deben seguir sus monjes.",
    image: Roots().AC480,
  },
  {
    date: "1182 AC",
    title: "San Francisco de Asís",
    subtitle: "Asís",
    par: "Fundó la Orden de los Frailes Menores de hombres, la Orden de Santa Clara de las mujeres, la Orden Tercera de San Francisco y la Custodia de Tierra Santa. Francisco es una de las figuras religiosas más veneradas del cristianismo. Se dice que Francisco tuvo una visión alrededor del 13 de septiembre de 1224, la Fiesta de la Exaltación de la Cruz, como resultado de la cual recibió los estigmas.",
    image: Roots().AC1182,
  },
  {
    date: "16 July 1194",
    title: "Santa Clara de Asís",
    subtitle: "Asís",
    par: "Fue una santa italiana y una de las primeras seguidoras de Francisco de Asís. Ella fundó la Orden de las Pobres Damas, una orden religiosa monástica para mujeres en la tradición franciscana, y escribió su Regla de Vida, el primer conjunto de pautas monásticas que se sabe que fueron escritas por una mujer.",
    image: Roots().AC1194,
  },
  {
    date: "1415",
    title: "Medalla de San Benedicto",
    subtitle: "Abadía de San Miguel",
    par: "La Medalla de San Benedicto es una medalla sacramental cristiana que contiene símbolos y textos relacionados con la vida de San Benedicto de Nursia.",
    image: Roots().AC1415,
  },
  {
    date: "27 Noviembre 1830",
    title: "La Medalla Milagrosa - Santa Catalina Labouré",
    subtitle: "Paris, Francia",
    par: "La Medalla Milagrosa, también conocida como la Medalla de Nuestra Señora de las Gracias, es una medalla devocional, cuyo diseño fue originado por Santa Catalina Labouré después de sus apariciones de la Santísima Virgen María en la Rue du Bac, París, Francia. Apocalipsis 12 (Y apareció una gran maravilla en el cielo: una mujer vestida del sol, y la luna debajo de sus pies, y sobre su cabeza una corona de doce estrellas)",
    image: Roots().AC1830,
  },
  {
    date: "25 Mayo 1887",
    title: "Santo Padre Pio de Pietrelcina",
    subtitle: "Pietrelcina, Benevento",
    par: "Padre Pio se hizo famoso por exhibir estigmas durante la mayor parte de su vida, lo que generó mucho interés. Fue beatificado y canonizado por el Papa Juan Pablo II.",
    image: Roots().AC1887,
  },
  {
    date: "25 Agosto 1905",
    title: "Santa Faustina Kowalska",
    subtitle: "Glogowiec",
    par: "A lo largo de su vida, Kowalska relató haber tenido visiones de Jesús y conversaciones con él, lo que anotó en su diario, publicado más tarde como El Diario de Santa María Faustina Kowalska: Divina Misericordia en Mi Alma.",
    image: Roots().AC1905,
  },
  {
    date: "13 May 1917 - 13 Sep 1917",
    title: "Nuestra Señora de Fátima",
    subtitle: "Cova da Iria, Fátima",
    par: "Nuestra Señora de Fátima, es un título católico de María, madre de Jesús basado en las apariciones marianas reportadas en 1917 por tres niños pastores, Lucía, Francisco y Jacinta en la Cova da Iria, en Fátima, Portugal.",
    image: Roots().AC1917,
  },
  {
    date: "1961 - 1965",
    title: "Apariciones de la Santísima Virgen María Garabandal",
    subtitle: "Garabandal, España",
    par: "Las apariciones de Garabandal son apariciones de San Miguel Arcángel y la Santísima Virgen María que, según se afirma, ocurrieron entre 1961 y 1965 a cuatro jóvenes escolares de la aldea rural de San Sebastián de Garabandal en la sierra de Peña Sagra en la comunidad autónoma de Cantabria. en el norte de España.",
    image: Roots().AC1961,
  },
  {
    date: "1981 - Presente",
    title: "Nuestra Señora de Medjugorje",
    subtitle: "Medjugorje, Bosnia y Herzegovina",
    par: "Nuestra Señora de Medjugorje, también llamada Reina de la Paz y Madre del Redentor, es el título que se le da a las visiones de la Santísima Virgen María que comenzaron en 1981 a seis adolescentes croatas en Medjugorje, Bosnia y Herzegovina.",
    image: Roots().AC1981,
  },
];

const SaintsCards = [
  {
    date: "230 AC",
    title: "Santa Cecilia de Roma",
    subtitle: "Roma",
    par: "Noble romana, convertida al cristianismo y martirizada por su fe. Los católicos incluyen su nombre en una de las plegarias eucarísticas de la misa —la plegaria eucarística. En la Iglesia católica, es patrona de la música, de los poetas, de los ciegos.",
    image: Roots().AC230,
  },
  {
    date: "405 AC",
    title: "Santo Jerónimo",
    subtitle: "Belem",
    par: "Hacia el 390 se dedicó a traducir la Biblia hebrea del hebreo original. Completó este trabajo en 405.",
    image: Roots().AC405,
  },
  {
    date: "480 AC",
    title: "San Benedicto de Nursia",
    subtitle: "Nursia, Umbria",
    par: "Benedicto fundó doce comunidades de monjes. El principal logro de Benedicto XVI, su 'Regla de San Benedicto', contiene un conjunto de reglas que deben seguir sus monjes.",
    image: Roots().AC480,
  },
  {
    date: "1070 AC",
    title: "San Isidro Labrador",
    subtitle: "Madrid, España",
    par: "Conocido por su piedad hacia los pobres y los animales. Es el santo patrón católico de los agricultores. Por la mañana, antes de ir a trabajar, Isidro solía asistir a misa en una de las iglesias de Madrid. Un día, sus compañeros campesinos se quejaron con su amo de que Isidro siempre llegaba tarde al trabajo por la mañana. Tras la investigación, el maestro encontró a Isidro en oración mientras un ángel estaba arando por él.",
    image: Roots().AC1070,
  },
  {
    date: "1182 AC",
    title: "San Francisco de Asís",
    subtitle: "Asís",
    par: "Fundó la Orden de los Frailes Menores de hombres, la Orden de Santa Clara de las mujeres, la Orden Tercera de San Francisco y la Custodia de Tierra Santa. Francisco es una de las figuras religiosas más veneradas del cristianismo. Se dice que Francisco tuvo una visión alrededor del 13 de septiembre de 1224, la Fiesta de la Exaltación de la Cruz, como resultado de la cual recibió los estigmas.",
    image: Roots().AC1182,
  },
  {
    date: "16 July 1194",
    title: "Santa Clara de Asís",
    subtitle: "Asís",
    par: "Fue una santa italiana y una de las primeras seguidoras de Francisco de Asís. Ella fundó la Orden de las Pobres Damas, una orden religiosa monástica para mujeres en la tradición franciscana, y escribió su Regla de Vida, el primer conjunto de pautas monásticas que se sabe que fueron escritas por una mujer.",
    image: Roots().AC1194,
  },
  {
    date: "27 Noviembre 1830",
    title: "La Medalla Milagrosa - Santa Catalina Labouré",
    subtitle: "Paris, Francia",
    par: "La Medalla Milagrosa, también conocida como la Medalla de Nuestra Señora de las Gracias, es una medalla devocional, cuyo diseño fue originado por Santa Catalina Labouré después de sus apariciones de la Santísima Virgen María en la Rue du Bac, París, Francia. Apocalipsis 12 (Y apareció una gran maravilla en el cielo: una mujer vestida del sol, y la luna debajo de sus pies, y sobre su cabeza una corona de doce estrellas)",
    image: Roots().AC1830,
  },
  {
    date: "25 Mayo 1887",
    title: "Santo Padre Pio de Pietrelcina",
    subtitle: "Pietrelcina, Benevento",
    par: "Padre Pio se hizo famoso por exhibir estigmas durante la mayor parte de su vida, lo que generó mucho interés. Fue beatificado y canonizado por el Papa Juan Pablo II.",
    image: Roots().AC1887,
  },
  {
    date: "25 Agosto 1905",
    title: "Santa Faustina Kowalska",
    subtitle: "Glogowiec",
    par: "A lo largo de su vida, Kowalska relató haber tenido visiones de Jesús y conversaciones con él, lo que anotó en su diario, publicado más tarde como El Diario de Santa María Faustina Kowalska: Divina Misericordia en Mi Alma.",
    image: Roots().AC1905,
  },
];
