import React, { useState, useEffect } from 'react';
import ActionAreaCard from './../ActionAreaCard';
import { Roots } from '../../static/Root';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LangF } from '../Main';

export default function D2022_05_26_Ostia_Palpita() {

    return (
        <div style={{ backgroundColor: "lightBlue", display: "flex", justifyContent: "center", alignItems: "center", height: "", flexDirection: "column", padding: "3%" }}>
            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `MILAGRO! "LA HOSTIA ESTA VIVA, LA HOSTIA COBRA VIDA" PALPITA AL MOMENTO DE LA ADORACION EUCARISTICA`}        
                {LangF() === 'en' && `MIRACLE! "THE HOST IS ALIVE, THE HOST COMES ALIVE" THREAT AT THE MOMENT OF EUCHARISTIC ADORATION`}       
                {LangF() === 'it' && `MIRACOLO! "L'OSPITE È VIVA, L'OSPITE SI VITA" MINACCE NEL MOMENTO DELL'ADORAZIONE EUCARISTICA`}
            </Typography>

            <Grid container style={{ padding: "5%",  alignItems: "center" }} >
                <iframe width="100%" height="300" src="https://www.youtube.com/embed/_vin2X7IS50" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
            </Grid>

            <Typography  style={{ textAlign: "center"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Parroquia Santa Maria del Camino`}
                {LangF() === "en" && `Santa Maria del Camino Parish`}
                {LangF() === "it" && `Parrocchia Santa Maria del Camino`}
                <br/>
                Medellin, Colombia<br/>
                May 26, 2022
            </Typography>
 
            {/* 3 imgs */}

            <Grid container style={{ padding: "0%" }} >
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                        <ActionAreaCard url="/miracles/D2022_05_26_Ostia_Palpita" img={Roots().D2022_05_26_Ostia_Palpita_1}/>
                    </div>
                </Grid>
                    
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                        <ActionAreaCard url="/miracles/D2022_05_26_Ostia_Palpita" img={Roots().D2022_05_26_Ostia_Palpita_2}/>
                    </div>
                </Grid>
                    
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                        <ActionAreaCard url="/miracles/D2022_05_26_Ostia_Palpita" img={Roots().D2022_05_26_Ostia_Palpita_3}/>
                    </div>
                </Grid>
            </Grid>
            <br />
        </div>
    );
}