import * as React from 'react';
import ActionAreaCard from './ActionAreaCard';
import { Roots } from '../static/Root';
import { LangOb } from '../static/Languages';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function Medal() {
    return (
        <div style={{ backgroundColor: "lightBlue", display: "flex", justifyContent: "center", alignItems: "center", height: "", flexDirection: "column", padding: "10%" }}>
            <Typography gutterBottom variant="h4" component="div" style={{ textAlign: "center" }}>
                {LangOb().MedalPar}
            </Typography>
            <br />

            <Typography gutterBottom variant="h6" component="div" style={{ textAlign: "center" }}>
                {LangOb().MedalPage1}
            </Typography>
            <br />

            <Button
                sx={{ width: "100%" }}
                href="https://docs.google.com/forms/d/1rvPp6-7w8hkF51gCa3fW3TZxaa9IN-F0WtU9t4ZRwyg" target="_blank"
            >
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "6%", height: 400 }}>
                        <ActionAreaCard img={Roots().AC1415} title={LangOb().Medal} detail={LangOb().MedalForm} />
                    </div>
                </Grid>
            </Button>

        </div>
    );
}