//Summa Teo
export const st479 = `47:9. Does diligence belong to prudence?

I answer: According to Saint Isidore, in the book Etymol.: Solicitous means sagacious and quick, in that
There are those who, due to a certain ability of mind, are quick to undertake what they must do.
This corresponds to prudence, whose main act is the empire for action on what
which has previously been the subject of advice and judgment. That is why the Philosopher says in Ethic VI.
that it is advisable to act quickly once the determination has been made, but it must be taken
calmly. For that reason, diligence is properly an act of prudence. Hence it says
Saint Augustine that prudence is on guard and diligent vigilance, lest,
a bad persuasion slowly insinuating itself, makes us fall.
`;

export const st891 = `89:1. Are the virtues recovered by penance?

I answer: With penance, as has already been explained, one forgives oneself.
the sins. Now, forgiveness of sins cannot be had
more than by the infusion of grace. Then with penance
man is infused with grace. Now from grace flow all
the infused virtues, as from the essence of the soul all the
powers, as said in the Second Part. Then with penance
all virtues are recovered.
`;

export const st892 = `89:2. Does man re-emerge after penance with the same degree of virtue?

I answer: The movement of free will that occurs in the justification of the sinner is, as stated
he has just explained (a.1), the last disposition for grace. So the infusion of grace is
simultaneous with the movement of free will, as said in the Second Part,
in which movement the act of penance is included, as has already been stated. However,
It is clear that the forms susceptible to greater or lesser intensity increase and decrease according to
the diverse disposition of the subject, as said in the Second Part. Therefore, according to
Whether the movement of free will in penitence is more or less intense, the penitent will receive
more or less grace.

But it happens that the intensity of the penitent's movement is sometimes proportional to a greater
grace than that which fell through sin, others are equal and others less. And, therefore, the penitent
sometimes it reappears with greater grace than it had before, sometimes with the same and sometimes with less.
And the same is said of the virtues that accompany grace.
`;

export const st893 = `89:3. Does penance restore man to his former dignity?

I answer: Man loses two types of dignity through sin. One, main one, for which he was counted
among the children of God (Wisdom 5,5) by grace. A dignity that is recovered through penance. This remains
illustrated in Luke 15:22 in the parable of the prodigal son, whom after his repentance the father
He ordered that his best tunic, ring and sandals be restored to him. The other is secondary, that is,
innocence, of which the eldest son gloried, in the same passage (v.29), saying: In so many years
Since I have been serving you, I have never broken a command of yours. This dignity the penitent can no longer
recover. However, it sometimes recovers something better. Because, as Saint Gregory says in his Homily
De centum ovibus, those who meditate their distance from God compensate for previous damages with the
subsequent earnings. There is more joy for them in heaven, because the boss also loves more in the
battle to the soldier who, after having fled, strongly attacks the enemy, who never
he turned his back, but never attacked bravely.
`;

export const Aquino = `St. Thomas Aquinas
Summa Theologiae`;
//----------------------------------------------------------------
export const Evidence = "Evidence";
export const EviSubTitle1 =
  "The Church has recognized over one hundred eucharistic miracles, many of which occurred during times of weakened faith in transubstantiation. One of the earliest was recorded by the Desert Fathers in Egypt, who were among the first Christian monks.";
export const EviCommFact = "Eucharist Miracles Common Facts";
export const CommFactors =
  "- White cell bloods- AB Blood- Left heart ventricle tissue";
