import React, { useState, useEffect } from 'react';
import ActionAreaCard from '../ActionAreaCard';
import { Roots } from '../../static/Root';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LangF } from '../Main';

export default function D2022_05_26_Ostia_Palpita() {

    return (
        <div style={{ backgroundColor: "lightBlue", display: "flex", justifyContent: "center", alignItems: "center", height: "", flexDirection: "column", padding: "3%" }}>
            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Exudación de óleo en Jesus`}        
                {LangF() === 'en' && `Oil exudation in Jesus`}       
                {LangF() === 'it' && `Essudazione di olio su Gesù`}
            </Typography>

            <Grid container style={{ padding: "5%",  alignItems: "center" }} >
                <iframe width="100%" height="300" src="https://www.youtube.com/embed/BU0uvORLtIo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
            </Grid>

            <Typography  style={{ textAlign: "center"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `San Juan`}
                {LangF() === "en" && `San Juan`}
                {LangF() === "it" && `San Juan`}
                <br/>
                Puerto Rico<br/>
                Nov 28, 2019
            </Typography>
 
            <br />
        </div>
    );
}