import React, { useState, useEffect } from 'react'
import BasicSelect from './BasicSelect'
import { LangOb } from '../static/Languages'
import Routing from './Routing'
import ResponsiveAppBar from './ResponsiveAppBar'
import Cookies from 'universal-cookie'
import Box from '@mui/material/Box'
import { Roots } from '../static/Root'

let Langu = 'en'
export function LangF() {
  return Langu
}

export default function Main() {
  const cookies = new Cookies()
  const [lang, setLang] = React.useState('en')

  const handleChange = (event) => {
    setLang(event.target.value)
    Langu = event.target.value
    cookies.set('lang', Langu, { path: '/' })
  }

  useEffect(() => {
    if (cookies.get('lang') !== undefined) {
      Langu = cookies.get('lang')
      setLang(Langu)
    }
  }, [Langu])

  function headerFooterCross(params) {
    return (
      <Box
        sx={{
          background: 'black',
          flexGrow: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '6%',
        }}
      >
        <img src={Roots().CrossWhite} width="100px" />
      </Box>
    )
  }

  function version() {
    return new Date().getFullYear() + `.1`;
  }

  function mainRender() {
    if (
      window.location.href.toLowerCase().includes('jesusle') ||
      window.location.href.toLowerCase().includes('disciple') ||
      window.location.href.toLowerCase().includes('jc')
    ) {
      return (
        <div>
          <ResponsiveAppBar />
          <div style={{ height: '90%' }}>
            <Routing />
          </div>
        </div>
      )
    }

    return (
      <div style={{ height: '100%' }}>
        {headerFooterCross()}
        <header
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignContent: 'center',
            alignItems: 'center',
            paddingTop: 20,
            backgroundColor: '#2196f3',
          }}
        >
          <div>
            <h2> {LangOb().JesusHeader} </h2>
            <p> {LangOb().Holy} </p>
          </div>
          <BasicSelect handleChange={handleChange} lang={lang} />
        </header>
        <ResponsiveAppBar />
        <div style={{ height: '100%' }}>
          <Routing />
        </div>
        <footer className="Main-footer">
          <h1
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '2em',
            }}
          >
            {LangOb().Footer}
          </h1>
          <p style={{ textAlign: 'center' }}>{LangOb().Acutis}</p>
          <p style={{ textAlign: 'center', height: 35 }}>{version()}</p>
        </footer>
        {headerFooterCross()}
      </div>
    )
  }

  return <div>{mainRender()}</div>
}
