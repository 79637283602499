import React, { useState, useEffect } from "react";
import ActionAreaCard from "../ActionAreaCard";
import { Roots } from "../../static/Root";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { LangF } from "../Main";
import Divider from "@material-ui/core/Divider";

export default function D2022_05_26_Ostia_Palpita() {
  return (
    <div
      style={{
        backgroundColor: "lightBlue",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "",
        flexDirection: "column",
        padding: "3%",
      }}
    >
      <Typography
        style={{ textAlign: "center", padding: "0" }}
        gutterBottom
        variant="h4"
        component="div"
      >
        {LangF() === "es" && `Milagros Eucarístico de Buenos Aires`}
        {LangF() === "en" && `Eucharistic Miracles of Buenos Aires`}
        {LangF() === "it" && `Miracoli Eucaristico di Buenos Aires`}
      </Typography>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexFlow: "wrap",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            margin: "2%",
            padding: "1%",
            borderRadius: "2px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActionAreaCard
            height={400}
            img={Roots().D1996_08_18_Buenos_Aires_Church}
            detail={
              (LangF() === "en" &&
                "The Parish of Santa María de Buenos Aires has been the protagonist of no less than 3 Eucharistic Miracles that took place in 1992, 1994 and 1996. Dr. Ricardo Castañón Gómez was called by the then Archbishop of Buenos Aires, no less than the current Pope Francis, to analyze the Miracle that took place in August 1996.") ||
              (LangF() === "es" &&
                "La Parroquia de Santa María de Buenos Aires ha sido la protagonista de nada menos que 3 Milagros Eucarísticos que tuvieron lugar en 1992, 1994 y 1996. El Dr. Ricardo Castañón Gómez fue llamado por el entonces Arzobispo de Buenos Aires, nada menos que el actual Papa Francisco, para que analizara el Milagro que tuvo lugar en agosto de 1996.") ||
              (LangF() === "it" &&
                "La Parrocchia di Santa María de Buenos Aires è stata protagonista di ben 3 Miracoli Eucaristici avvenuti nel 1992, 1994 e 1996. Il Dott. Ricardo Castañón Gómez fu chiamato dall allora Arcivescovo di Buenos Aires, nientemeno che lattuale Papa Francesco, per analizzare il Miracolo avvenuto nell agosto 1996")
            }
          />
        </div>
      </div>

      {/* 1992 */}
      <div>
        <Typography
          style={{ textAlign: "center" }}
          gutterBottom
          variant="h4"
          component="div"
        >
          <br />
          May 1, 1992
        </Typography>
        <Divider style={{ backgroundColor: "white", width: "100%" }} />
        <h4
          style={{
            backgroundColor: "white",
            width: "90%",
            margin: "2%",
            padding: "1%",
            borderRadius: "2px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {(LangF() === "es" &&
            "En 1992, después de la Misa del viernes 1 de mayo, al preparar la reserva eucarística, el ministro de la Eucaristía encontró fragmentos de Hostia consagrada en el corporal. Siguiendo los preceptos de la Iglesia en estas situaciones, el sacerdote hizo que los metieran en un recipiente con agua que luego volvió a colocarse en el sagrario a la espera de que se disolvieran. Durantelos días siguientes algunos sacerdotes fueron a controlar y se dieron cuenta de que nada había cambiado. Siete días más tarde, el viernes 8 de mayo, abrieron el sagrario y vieron que los fragmentos de Hostia se habían teñido de un color rojizo que parecía sangre. El domingo siguiente, el 10 de mayo, durante las dos Misas vespertinas, se observaron pequeñas gotas de sangre en las patenas con las que los sacerdotes daban la Comunión.") ||
            (LangF() === "en" &&
              "In 1992, after the Mass on Friday, May 1, while preparing the Eucharistic reserve, the Eucharistic Minister found fragments of the consecrated Host in the corporal. Following the precepts of the Church in these situations, the priest had them placed in a container of water, which was then placed back in the tabernacle to wait for them to dissolve. During the following days some priests went to check and realized that nothing had changed. Seven days later, on Friday, May 8, they opened the tabernacle and saw that the fragments of the Host had turned a reddish color that looked like blood. The following Sunday, May 10, during the two evening Masses, small drops of blood were observed on the patens with which the priests gave Communion.") ||
            (LangF() === "it" &&
              "Nel 1992, dopo la Messa di venerdì 1 maggio, mentre preparava la riserva eucaristica, il Ministro eucaristico trovò nel corporale frammenti dell Ostia consacrata. Seguendo i precetti della Chiesa in queste situazioni, il sacerdote le faceva deporre in un recipiente d acqua, che veniva poi riposta nel tabernacolo in attesa che si dissolvessero. Nei giorni successivi alcuni sacerdoti andarono a controllare e si accorsero che nulla era cambiato. Sette giorni dopo, venerdì 8 maggio, aprirono il tabernacolo e videro che i frammenti dell Ostia avevano assunto un colore rossastro che sembrava sangue. La domenica successiva, 10 maggio, durante le due messe serali, si osservarono piccole gocce di sangue sulle patene con cui i sacerdoti facevano la Comunione.")}
        </h4>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexFlow: "wrap",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              margin: "2%",
              padding: "1%",
              borderRadius: "2px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActionAreaCard
              height={300}
              img={Roots().D1996_08_18_Buenos_Aires_Left}
              detail={
                (LangF() === "en" &&
                  "Dr. Zugibe: it is the muscle of the heart, of the myocardium, specifically it is the left ventricle”.") ||
                (LangF() === "es" &&
                  "Dr. Zugibe: es el músculo del corazón, del miocardio, concretamente es el ventrículo izquierdo") ||
                (LangF() === "it" &&
                  "Dr Zugibe: è il muscolo del cuore, del miocardio, nello specifico è il ventricolo sinistro”")
              }
            />
          </div>
        </div>
      </div>

      {/* 1994 */}
      <div>
        <Typography
          style={{ textAlign: "center" }}
          gutterBottom
          variant="h4"
          component="div"
        >
          <br />
          Jul 24, 1994
        </Typography>
        <Divider style={{ backgroundColor: "white", width: "100%" }} />
        <h4
          style={{
            backgroundColor: "white",
            width: "90%",
            margin: "2%",
            padding: "1%",
            borderRadius: "2px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {(LangF() === "es" &&
            "El domingo 24 de julio de 1994, durante la Misa de los niños, mientras el ministro de la Eucaristía cogía la píxide del sagrario, vio una gota de sangre que fluía por la pared.") ||
            (LangF() === "en" &&
              "On Sunday, July 24, 1994, during the childrens Mass, while the Eucharistic minister was holding the pyx of the tabernacle, he saw a drop of blood flowing down the wall.") ||
            (LangF() === "it" &&
              "Domenica 24 luglio 1994, durante la messa dei bambini, mentre il ministro eucaristico reggeva la pisside del tabernacolo, vide una goccia di sangue scorrere lungo il muro.")}
        </h4>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexFlow: "wrap",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              margin: "2%",
              padding: "1%",
              borderRadius: "2px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActionAreaCard
              height={300}
              img={Roots().D1996_08_18_Buenos_Aires_White_Cells}
              detail={
                (LangF() === "en" &&
                  "Dr. Lawrence studied the samples and found human skin and white blood cells. In the month of December 2000”.") ||
                (LangF() === "es" &&
                  "El Dr. Lawrence estudió las muestras y halló piel humana y glóbulos blancos. En el mes de diciembre de 2000") ||
                (LangF() === "it" &&
                  "Il dottor Lawrence ha studiato i campioni e ha trovato pelle umana e globuli bianchi. Nel mese di dicembre 2000")
              }
            />
          </div>
        </div>
      </div>

      {/* 1996 */}
      <div>
        <Typography
          style={{ textAlign: "center" }}
          gutterBottom
          variant="h4"
          component="div"
        >
          <br />
          Aug 18, 1996
        </Typography>
        <Divider style={{ backgroundColor: "white", width: "100%" }} />
        <h4
          style={{
            backgroundColor: "white",
            width: "90%",
            margin: "2%",
            padding: "1%",
            borderRadius: "2px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {(LangF() === "es" &&
            "El 15 de agosto de 1996, durante la Misa de la Asunción de la Santísima Virgen, se tuvo que volver a meter una Hostia consagrada que se había caído al suelo mientras se daba la Comunión en un recipiente con agua para que se disolviera. Unos días después, el 26 de agosto, un ministro de la Eucaristía abrió el sagrario y vio que la Hostia se había transformado en Sangre.") ||
            (LangF() === "en" &&
              "On August 15, 1996, during the Mass of the Assumption of the Blessed Virgin, a consecrated Host that had fallen to the ground while receiving Communion had to be put back into a container of water so that it would dissolve. A few days later, on August 26, a Eucharistic minister opened the tabernacle and saw that the Host had turned into Blood.") ||
            (LangF() === "it" &&
              "Il 15 agosto 1996, durante la Messa dell Assunzione della Beata Vergine, un Ostia consacrata caduta a terra mentre riceveva la Comunione doveva essere riposta in un recipiente d acqua affinché si sciogliesse. Pochi giorni dopo, il 26 agosto, un ministro eucaristico aprì il tabernacolo e vide che l Ostia si era trasformata in Sangue.")}
        </h4>

        <Grid container style={{ padding: "5%", alignItems: "center" }}>
          <iframe
            width="100%"
            height="300"
            src="https://www.youtube.com/embed/iBs-xWeWuCM"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </Grid>
      </div>

      {/* Dynamic Layout */}
      {/* <div
        style={{
          width: '100%',
          display: 'flex',
          flexFlow: 'wrap',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            width: '220px',
            margin: '2%',
            padding: '1%',
            borderRadius: '2px',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          c
        </div>
        <div
          style={{
            margin: '2%',
          }}
        >
          <ActionAreaCard
            height={400}
            img={Roots().D2022_05_26_Ostia_Palpita_1}
            detail="d"
          />
        </div>
        <div
          style={{
            backgroundColor: 'gray',
            width: '220px',
            margin: '2%',
          }}
        >
          4
        </div>
        <div
          style={{
            backgroundColor: 'darkgrey',
            width: '220px',
            margin: '2%',
          }}
        >
          6
        </div>
        <div
          style={{
            backgroundColor: 'gray',
            width: '220px',
            margin: '2%',
            height: '40px',
          }}
        >
          220px x 40px
        </div>
        <div
          style={{
            backgroundColor: 'darkgrey',
            width: '220px',
            margin: '2%',
            height: '30px',
          }}
        >
          220px x 30px
        </div>
        <div
          style={{
            backgroundColor: 'darkgrey',
            width: '220px',
            margin: '2%',
            height: '30px',
          }}
        >
          220px x 30px
        </div>
        <div
          style={{
            backgroundColor: 'gray',
            width: '220px',
            margin: '2%',
            height: '40px',
          }}
        >
          220px x 40px
        </div>
        <div
          style={{
            backgroundColor: 'gray',
            width: '220px',
            margin: '2%',
            height: '40px',
          }}
        >
          cc
        </div>
      </div> */}
    </div>
  );
}
