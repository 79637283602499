import { LangOb } from '../static/Languages';
import Quiz from '../components/Quiz/Quiz'

function QuizComp(props) {
  return (
    <div style={{ backgroundColor: "lightBlue", height: 500, display: "flex", justifyContent: "center", alignItems: "center", }}>
      <Quiz  >
      </Quiz>
    </div>
  );
}

function AntiChristQuiz() {
  return (<QuizComp />)
}

export { AntiChristQuiz };