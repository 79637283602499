const quizDataEs = {
  title: 'Domande sull Anticristo',
  description:
    "Il nome Anticristo si riferisce a una figura che avrebbe adempiuto le profezie bibliche riguardanti l'antagonista di Cristo. L'uso di questa parola appare cinque volte nella Bibbia sia in forma singolare che plurale.",
  // img: 'start-example.jpg',
  questions: [
    {
      id: 1,
      text: "Si alzerà per unire il mondo sotto un unico governo durante la Tribolazione?",
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 2,
      text: 'Questo dittatore globale si poserà come un angelo di luce?',
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 3,
      text: 'Il diavolo è il padre dell anticristo?',
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 4,
      text: 'Si opporrà apertamente a Cristo e allo stesso tempo si atteggia a Cristo?',
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 5,
      text: 'Negherà che Gesù è il Cristo?',
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 6,
      text: 'Rinnegherà il Padre e il Figlio?',
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 7,
      text: 'Si oppone ed esalta se stesso al di sopra di ogni presunto dio o oggetto di culto, così da sedere nel tempio di Dio e dichiararsi Dio?',
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 8,
      text: 'Quando entrerà in scena, le persone si affacceranno a lui come le mosche al miele e faranno tutto ciò che chiederà?',
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 9,
      text: "Dirà parole pompose contro l'Altissimo?",
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 10,
      text: "Catturerà l'attenzione e l'amministrazione del mondo?",
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 11,
      text: 'Parlerà anche con arroganza contro Dio?',
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 12,
      text: 'Gli fu data una bocca che dicesse grandi cose e bestemmie?',
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 13,
      text: "Di chi era l'aspetto più grande dei suoi coetanei?",
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 14,
      text: "In termini di aspetto esteriore, quest'uomo sarà sorprendentemente attraente?",
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 15,
      text: 'La combinazione della sua personalità magnetica, della sua capacità di parlare e della sua estrema bellezza lo renderanno praticamente irresistibile per le masse?',
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 16,
      text: "Agisce dicendoci che ogni persona vivente dovrebbe adorare quest'uomo?",
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 17,
      text: "Gli fu dato il potere di dare respiro all'immagine della bestia, in modo che l'immagine della bestia parlasse e facesse uccidere chiunque non avesse adorato l'immagine della bestia?",
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 18,
      text: "Durante gli ultimi 3 anni e mezzo della Tribolazione, l'Anticristo impersonerà Satana stesso?",
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 19,
      text: 'La venuta del malvagio è opera di Satana, con ogni potenza, segni e prodigi bugiardi?',
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 20,
      text: "Passo dopo passo, l'Anticristo si promuoverà da leader europeo, a leader mondiale, a tirannico dittatore globale e infine a dio?",
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 21,
      text: "Satana è la prima persona e il padre della trinità empia. L'Anticristo sarà il secondo membro e figlio dell'empia Trinità. Riuscirà il Profeta Non è vero, il terzo membro della trinità empia, a ricondurre il culto e la lode del popolo all'Anticristo?",
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 22,
      text: "Qualunque potere abbia l'Anticristo, l'autorità suprema di Dio Onnipotente gli permette di averlo. Per quanto grave sia la Tribolazione, non sarà mai fuori dal controllo di Dio. Satana ha un guinzaglio e Dio lo tiene. Satana potrà fare solo ciò che Dio gli permette di fare durante il periodo della Tribolazione?",
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 23,
      text: "L'Anticristo è un leader di una setta?",
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
    {
      id: 24,
      text: 'Intenderà cambiare i tempi e la legge?',
      alternatives: [
        {
          id: 1,
          text: 'Vero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Non è vero',
          isCorrect: false,
        },
      ],
    },
  ],
  results: [
    {
      id: 1,
      range: {
        from: 0,
        to: 20,
      },
      title: 'Solo tienes unas pocas preguntas correctas.',
      description:
        '¿Quién es el mentiroso, sino el que niega que Jesús es el Cristo? Este es anticristo, el que niega al Padre y al Hijo. — 1 Juan 2:22',
    },
    {
      id: 2,
      range: {
        from: 21,
        to: 23,
      },
      title: 'Tienes más de la mitad del cuestionario correcto!',
      description:
        '¿Quién es el mentiroso, sino el que niega que Jesús es el Cristo? Este es anticristo, el que niega al Padre y al Hijo. — 1 Juan 2:22',
    },
    {
      id: 3,
      range: {
        from: 24,
        to: 24,
      },
      title: 'Felicidades, tienes todo bien!',
      description:
        '¿Quién es el mentiroso, sino el que niega que Jesús es el Cristo? Este es anticristo, el que niega al Padre y al Hijo. — 1 Juan 2:22',
      // img: 'result-example.jpg',
    },
  ],
};

export default quizDataEs;