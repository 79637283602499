import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'

export default function ActionAreaCard(props) {
  const [height, setHeight] = React.useState(345)

  useEffect(() => {
    props.title ? setHeight(345) : setHeight(200)
    if (props.height) setHeight(props.height)
  })

  return (
    <Card sx={{ height: height, width: 345, padding: '3px', margin: '0%' }}>
      <CardActionArea href={props.url}>
        <CardMedia
          component="img"
          height="190"
          image={props.img}
          // alt="green iguana"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {props.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {props.detail}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
