import React, { useState, useEffect } from "react";
import BasicSelect from "./BasicSelect";
import { LangOb, GetSt } from "../static/Languages";
import Routing from "./Routing";
import ResponsiveAppBar from "./ResponsiveAppBar";
import Box from "@mui/material/Box";
import { Roots } from "../static/Root";
import Grid from "@mui/material/Grid";

let Langu = "en";
export function LangF() {
  return Langu;
}

export default function Main() {
  function renTitle(params) {
    return (
      <div style={{ height: "100%" }}>
        <div
          style={{
            backgroundColor: "lightBlue",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "",
            flexDirection: "column",
            padding: "3%",
          }}
        >
          <h1>{params}</h1>
        </div>
      </div>
    );
  }

  function renGrid(params) {
    return (
      <Grid container style={{ padding: "0%" }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "6%",
              height: 400,
            }}
          >
            <h3>{params}</h3>
          </div>
        </Grid>
      </Grid>
    );
  }

  function mainRender() {
    return (
      <div>
        {renTitle(GetSt("Evidence"))}
        {renGrid(GetSt("EviSubTitle1"))}
        {renTitle(GetSt("EviCommFact"))}
        {renGrid(GetSt("CommFactors"))}
      </div>
    );
  }

  return <div>{mainRender()}</div>;
}
