//Summa Teo
export const st479 = `47:9. La diligenza appartiene alla prudenza?

Rispondo: Secondo sant'Isidoro, nel libro Etimol.: Sollecito significa sagace e svelto, in quanto
C'è chi, per una certa capacità d'animo, è pronto a intraprendere ciò che deve fare.
Ciò corrisponde alla prudenza, il cui atto principale è l'impero dell'azione su cosa
che è stato precedentemente oggetto di consulenza e giudizio. Ecco perché il Filosofo dice nell'Etica VI.
che è opportuno agire rapidamente una volta presa la decisione, ma è necessario adottarla
con calma. Per questo motivo la diligenza è propriamente un atto di prudenza. Quindi dice
Sant'Agostino che la prudenza sta vigilante e diligente vigilanza, affinché,
Una cattiva persuasione, insinuandosi gradualmente, ci fa cadere.
`;

export const st891 = `89:1. Le virtù si recuperano con la punizione?

Rispondo: Con la punizione, come è già stato spiegato, si perdona se stessi.
i peccati. Ora, il perdono dei peccati non si può avere
più che per l'infusione della grazia. Poi con dispiacere
l'uomo è infuso di grazia. Ora tutto scorre dalla grazia
le virtù infuse, come dall'essenza dell'anima tutta la
poteri, come detto nella Parte Seconda. Poi con dispiacere
tutte le virtù sono recuperate.
`;

export const st892 = `89:2. L'uomo riemerge dopo la punizione con lo stesso grado di virtù?

Rispondo: Il movimento del libero arbitrio che avviene nella giustificazione del peccatore è, come detto
ha appena spiegato (a.1), l'ultima disposizione della grazia. Così è l'infusione della grazia
contemporaneamente al movimento del libero arbitrio, come detto nella Parte Seconda,
nel quale movimento è compreso l'atto punitivo, come già si è detto. Tuttavia,
È chiaro che le forme suscettibili di maggiore o minore intensità aumentano e diminuiscono a seconda
la diversa disposizione della materia, come detto nella Parte Seconda. Pertanto, secondo
Sia che il movimento del libero arbitrio nella penitenza sia più o meno intenso, il penitente riceverà
più o meno grazia.

Ma accade che l'intensità del movimento del penitente sia talvolta proporzionale a un maggiore
grazia rispetto a quella caduta a causa del peccato, altri sono uguali e altri minori. E quindi il penitente
a volte riappare con maggiore grazia di prima, a volte con la stessa, a volte con meno.
E lo stesso si dice delle virtù che accompagnano la grazia.
`;

export const st893 = `89:3. La penitenza restituisce all'uomo la sua antica dignità?

Rispondo: A causa del peccato l'uomo perde due tipi di dignità. Uno, quello principale, per il quale è stato conteggiato
tra i figli di Dio (Sapienza 5,5) per grazia. Una dignità che si recupera attraverso la penitenza. Questo rimane
illustrato in Luca 15:22 nella parabola del figliol prodigo, che dopo il suo pentimento il padre
Ordinò che gli fossero restituiti la tunica migliore, l'anello e i sandali. L'altro è secondario, cioè
innocenza, di cui si vantava il figlio maggiore, nello stesso brano (v.29), dicendo: In tanti anni
Da quando ti servo, non ho mai violato un tuo comando. Questa dignità il penitente non la può più
recuperare. Tuttavia, a volte recupera qualcosa di meglio. Perché, come dice San Gregorio nella sua Omelia
De centum ovibus, chi medita la propria lontananza da Dio risarcisce i danni pregressi con la
guadagni successivi. C'è più gioia per loro in cielo, perché anche il capo ama di più in cielo
battaglia al soldato che, dopo essere fuggito, attacca con forza il nemico, che mai
voltò le spalle, ma non attaccò mai coraggiosamente.
`;

export const Aquino = `San Tommaso d'Aquino
Summa Theologiae`;
//----------------------------------------------------------------
export const Evidence = "Evidenza";
export const EviSubTitle1 =
  "La Chiesa ha riconosciuto oltre cento miracoli eucaristici, molti dei quali avvenuti durante periodi di indebolimento della fede nella transustanziazione. Uno dei primi è stato registrato dai Padri del deserto in Egitto, che furono tra i primi monaci cristiani.";
export const EviCommFact = "Miracoli eucaristici Fatti Comuni";
export const CommFactors =
  "- White cell bloods- AB Blood- Left heart ventricle tissue";
