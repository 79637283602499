import React, { useState, useEffect } from 'react'
import ActionAreaCard from '../ActionAreaCard'
import { Roots } from '../../static/Root'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { LangF } from '../Main'

// https://www.aciprensa.com/noticias/un-nuevo-milagro-eucaristico-ocurrio-en-mexico-56495

export default function D2022_05_26_Ostia_Palpita() {
  return (
    <div
      style={{
        backgroundColor: 'lightBlue',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '',
        flexDirection: 'column',
        padding: '3%',
      }}
    >
      <Typography
        style={{ textAlign: 'center', padding: '0' }}
        gutterBottom
        variant="h4"
        component="div"
      >
        {LangF() === 'es' &&
          `Milagro en mexico mientras el padre Carlos Spahn exponía el santísimo la hostia empezó a palpitar.`}
        {LangF() === 'en' &&
          `Miracle in Mexico while Father Carlos Spahn was exposing the Blessed Sacrament, the host began to throb.`}
        {LangF() === 'it' &&
          `Miracolo in Messico mentre padre Carlos Spahn esponeva il Santissimo Sacramento, l'ostia cominciò a pulsare.`}
      </Typography>

      <Grid container style={{ padding: '5%', alignItems: 'center' }}>
        <iframe
          width="100%"
          height="300"
          src="https://www.youtube.com/embed/747s-WgS7a0"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Grid>

      <Typography
        style={{ textAlign: 'center' }}
        gutterBottom
        variant="h4"
        component="div"
      >
        {LangF() === 'es' && `Parroquia Nuestra Señora del Rosario`}
        {LangF() === 'en' && `Nuestra Señora del Rosario Parish`}
        {LangF() === 'it' && `Parrocchia Nuestra Señora del Rosario`}
        <br />
        Guadalajara, Mexico
        <br />
        Jul 22, 2022
      </Typography>

      {/* 3 imgs */}

      {/* <Grid container style={{ padding: "0%" }} >
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                        <ActionAreaCard url="/miracles/D2022_05_26_Ostia_Palpita" img={Roots().D2022_05_26_Ostia_Palpita_1}/>
                    </div>
                </Grid>
                    
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                        <ActionAreaCard url="/miracles/D2022_05_26_Ostia_Palpita" img={Roots().D2022_05_26_Ostia_Palpita_2}/>
                    </div>
                </Grid>
                    
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                        <ActionAreaCard url="/miracles/D2022_05_26_Ostia_Palpita" img={Roots().D2022_05_26_Ostia_Palpita_3}/>
                    </div>
                </Grid>
            </Grid> */}
      <br />
    </div>
  )
}
