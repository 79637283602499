//Summa Teo
export const st479 = `47:9. ¿Pertenece la diligencia a la prudencia?

Respondo: Según San Isidoro, en el libro Etymol.: Solícito significa sagaz y rápido, en cuanto que 
hay quien, por cierta habilidad de ánimo, es rápido para emprender lo que debe obrar. 
Esto corresponde a la prudencia, cuyo acto principal es el imperio para la acción sobre lo 
que previamente ha sido objeto del consejo y del juicio. Por eso dice el Filósofo en VI Ethic. 
que conviene obrar rápidamente una vez tomada la determinación, pero ésta se ha de tomar 
con calma. Por esa razón, la diligencia es propiamente acto de la prudencia. De ahí que diga 
San Agustín que la prudencia está en guardia y en vigilancia diligente, no sea que, 
insinuándose poco a poco una mala persuasión, nos haga caer.
`;

export const st891 = `89:1. ¿Quedan recuperadas las virtudes por la penitencia?

Respondo: Con la penitencia, como se ha expuesto ya, se perdonan
los pecados. Ahora bien, el perdón de los pecados no se puede tener
más que por la infusión de la gracia. Luego con la penitencia al 
hombre se le infunde la gracia. Ahora bien, de la gracia fluyen todas 
las virtudes infusas, como de la esencia del alma fluyen todas las 
potencias, según se dijo en la Segunda Parte. Luego con la penitencia 
se recuperan todas las virtudes.
`;

export const st892 = `89:2. ¿Resurge el hombre después de la penitencia con el mismo grado de virtud?

Respondo: El movimiento del libre albedrío que se da en la justificación del pecador es, como se 
acaba de exponer (a.1), la última disposición para la gracia. Por lo que la infusión de la gracia es 
simultánea con el movimiento del libre albedrío, como se dijo en la Segunda Parte, 
en cuyo movimiento está comprendido el acto de la penitencia, como se ha afirmado ya. Ahora bien, 
es claro que las formas susceptibles de una mayor o menor intensidad aumentan y disminuyen según 
la diversa disposición del sujeto, como se dijo en la Segunda Parte. Por lo que, según 
que el movimiento del libre albedrío en la penitencia sea más o menos intenso, el penitente recibirá 
más o menos gracia.

Pero acontece que la intensidad del movimiento del penitente a veces es proporcionada a una mayor 
gracia que aquella de la que cayó por el pecado, otras es igual y otras menor. Y, por eso, el penitente 
algunas veces resurge con mayor gracia de la que antes tenía, otras con igual y otras con menor. 
Y lo mismo se diga de las virtudes que acompañan a la gracia.
`;

export const st893 = `89:3. ¿Restituye la penitencia al hombre en su precedente dignidad?

Respondo: El hombre pierde por el pecado dos tipos de dignidad. Una, principal, por la que era contado 
entre los hijos de Dios (Sab 5,5) por la gracia. Una dignidad que recupera por la penitencia. Esto queda 
ilustrado en Lc 15,22 en la parábola del hijo pródigo, a quien después de su arrepentimiento el padre 
mandó que se le restituyeran la mejor túnica, el anillo y las sandalias. La otra es secundaria, o sea, 
la inocencia, de la que se gloriaba el hijo mayor, en el mismo pasaje (v.29), diciendo: En tantos años 
como vengo sirviéndote nunca quebranté un mandato tuyo. Esta dignidad el penitente ya no la puede 
recuperar. Sin embargo, recupera alguna vez algo mejor. Porque, como dice San Gregorio en su Homilía 
De centum ovibus, los que meditan su alejamiento de Dios compensan los daños anteriores con las 
ganancias posteriores. Hay más alegría por ellos en el cielo, porque también el jefe ama más en la 
batalla al soldado que, después de haber huido, ataca fuertemente al enemigo, que a quien nunca 
dio la espalda, pero nunca atacó con valentía.
`;

export const Aquino = `S. Tomás de Aquino
Suma Teológica`;

//----------------------------------------------------------------
export const Evidence = "Evidencia";
export const EviSubTitle1 =
  "La Iglesia ha reconocido más de cien milagros eucarísticos, muchos de los cuales ocurrieron durante tiempos de fe debilitada en la transustanciación. Uno de los primeros fue registrado por los Padres del Desierto en Egipto, quienes estuvieron entre los primeros monjes cristianos.";
export const EviCommFact = "Factores comunes de los milagros Eucaristícos";
export const CommFactors =
  "- White cell bloods- AB Blood- Left heart ventricle tissue";
