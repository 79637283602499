// religionenlibertad.com/america_latina/5959565/abrio-sagrario-corporal-tenia-sangre-humana-nuevo-milagro-eucaristico-gracias-honduras.html

import React, { useState, useEffect } from 'react'
import ActionAreaCard from './ActionAreaCard'
import { Roots } from '../static/Root'
import { LangOb } from '../static/Languages'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { LangF } from './Main'

export default function Miracles() {
  return (
    <div
      style={{
        backgroundColor: 'lightBlue',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '',
        flexDirection: 'column',
        padding: '3%',
      }}
    >
      <h1>
        {(LangF() === 'es' && 'Milagros') ||
          (LangF() === 'en' && 'Miracles') ||
          (LangF() === 'it' && 'Miracoli')}
      </h1>

      <Grid container style={{ padding: '0%' }}>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D2022_09_11_Sonora"
              img={Roots().D2022_09_11_Sonora}
              title={
                (LangF() === 'es' &&
                  `Imagen de la Virgen María llora aceite`) ||
                (LangF() === 'en' && `Image of the Virgin Mary cries oil`) ||
                (LangF() === 'it' && `Immagine della Vergine Maria piange olio`)
              }
              detail={'Sonora, Mexico. Sep 11, 2022'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D2022_07_24_Guadalajara"
              img={Roots().D2022_07_24_Guadalajara}
              title={
                (LangF() === 'es' && 'Ostia Palpita') ||
                (LangF() === 'en' && 'Palpitating Eucharist') ||
                (LangF() === 'it' && 'Ostia pulsa')
              }
              detail={'Guadalajara, Mexico. Jul 22, 2022'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D2022_05_30_Mary_Cries_Toronto"
              img={Roots().D2022_05_30_Mary_Cries_Toronto}
              title={
                (LangF() === 'es' && 'Virgen de Fatima Llora') ||
                (LangF() === 'en' && 'Virgin of Fatima Tears') ||
                (LangF() === 'it' && 'Lacrime della Vergine di Fatima')
              }
              detail={'Toronto, Canada. May 30, 2022'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D2022_05_26_Ostia_Palpita"
              img={Roots().D2022_05_26_Ostia_Palpita}
              title={
                (LangF() === 'es' && 'Ostia Palpita') ||
                (LangF() === 'en' && 'Palpitating Eucharist') ||
                (LangF() === 'it' && 'Ostia pulsa')
              }
              detail={
                'Santa Maria del Camino, Medellin, Colombia. May 26, 2022'
              }
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D2019_11_28_San_Juan"
              img={Roots().D2019_11_28_San_Juan}
              title={
                (LangF() === 'es' && 'Exudación de óleo en Jesus') ||
                (LangF() === 'en' && 'Oil exudation in Jesus') ||
                (LangF() === 'it' && 'Essudazione di olio su Gesù')
              }
              detail={'San Juan, Puerto Rico. Nov 28, 2019'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D2018_10_30_California"
              img={Roots().D2018_10_30_California}
              title={
                (LangF() === 'es' && 'Manifestacion en un crucifijo') ||
                (LangF() === 'en' && 'Manifestation on a crucifix') ||
                (LangF() === 'it' && 'Manifestazione su un crocifisso')
              }
              detail={'California, US. Oct 30, 2018'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D2015_09_15_San_Jose"
              img={Roots().D2015_09_15_San_Jose}
              title={
                (LangF() === 'es' && 'Manifestación en Imagen religiosa') ||
                (LangF() === 'en' && 'Manifestation in Religious Image') ||
                (LangF() === 'it' && "Manifestazione nell'immagine religiosa")
              }
              detail={'San Jose, Costa Rica. Sep 15, 2015'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D2013_12_25_Legnica"
              img={Roots().D2013_12_25_Legnica}
              title={
                (LangF() === 'es' && 'Milagro Eucarístico') ||
                (LangF() === 'en' && 'Eucharist Miracle') ||
                (LangF() === 'it' && 'Miracolo Eucaristico')
              }
              detail={'Legnica, Poland. Dec 25, 2013'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D2008_10_12_Sokolka"
              img={Roots().D2008_10_12_Sokolka}
              title={
                (LangF() === 'es' && 'Milagro Eucarístico') ||
                (LangF() === 'en' && 'Eucharist Miracle') ||
                (LangF() === 'it' && 'Miracolo Eucaristico')
              }
              detail={'Sokolka, Poland. Oct 12, 2008'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D2006_10_21_Tixtla"
              img={Roots().D2006_10_21_Tixtla}
              title={
                (LangF() === 'es' && 'Milagro Eucarístico') ||
                (LangF() === 'en' && 'Eucharist Miracle') ||
                (LangF() === 'it' && 'Miracolo Eucaristico')
              }
              detail={'Tixtla, Mexico. Oct 21, 2006'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D1996_08_18_Buenos_Aires"
              img={Roots().D1996_08_18_Buenos_Aires}
              title={
                (LangF() === 'es' && 'Milagro Eucarístico') ||
                (LangF() === 'en' && 'Eucharist Miracle') ||
                (LangF() === 'it' && 'Miracolo Eucaristico')
              }
              detail={'Buenos Aires, Argentina. Aug 18, 1996'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D1991_12_08_Betania"
              img={Roots().D1991_12_08_Betania}
              title={
                (LangF() === 'es' && 'Milagro Eucarístico') ||
                (LangF() === 'en' && 'Eucharist Miracle') ||
                (LangF() === 'it' && 'Miracolo Eucaristico')
              }
              detail={'Betania, Venezuela. Dec 8, 1991'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D1969_07_25_San_Mauro_La_Bruca"
              img={Roots().D1969_07_25_San_Mauro_La_Bruca}
              title={
                (LangF() === 'es' && 'Milagro Eucarístico') ||
                (LangF() === 'en' && 'Eucharist Miracle') ||
                (LangF() === 'it' && 'Miracolo Eucaristico')
              }
              detail={'San Mauro La Bruca, Italy. Jul 25, 1969'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D1948_04_04_Rosano"
              img={Roots().D1948_04_04_Rosano}
              title={
                (LangF() === 'es' && 'Lágrimas y Sangre de Jesús') ||
                (LangF() === 'en' && 'Jesus Tears & Blood') ||
                (LangF() === 'it' && 'Le lacrime e il sangue di Gesù')
              }
              detail={'Rosano, Italy. Apr 4, 1948'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D1907_03_25_Silla"
              img={Roots().D1907_03_25_Silla}
              title={
                (LangF() === 'es' && 'Milagro Eucarístico') ||
                (LangF() === 'en' && 'Eucharist Miracle') ||
                (LangF() === 'it' && 'Miracolo Eucaristico')
              }
              detail={'Silla, Spain. Mar 25, 1907'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D1631_08_03_Dronero"
              img={Roots().D1631_08_03_Dronero}
              title={
                (LangF() === 'es' && 'Milagro Eucarístico') ||
                (LangF() === 'en' && 'Eucharist Miracle') ||
                (LangF() === 'it' && 'Miracolo Eucaristico')
              }
              detail={'Dronero, Italy. Aug 3, 1631'}
            />
          </div>
        </Grid>

        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles/D750_Lanciano"
              img={Roots().D750_Lanciano}
              title={
                (LangF() === 'es' && 'Milagro Eucarístico') ||
                (LangF() === 'en' && 'Eucharist Miracle') ||
                (LangF() === 'it' && 'Miracolo Eucaristico')
              }
              detail={'Lanciano, Italy. 750 AC'}
            />
          </div>
        </Grid>

        {/* 1656 Cava dei Tirreni
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "6%", height: 400 }}>
                            <ActionAreaCard url="/miracles" img={Roots().D1656_Cava_dei_Tirreni} title={"Cava dei Tirreni"} detail={"Italia 1656"} />
                        </div>
                    </Grid> */}
      </Grid>

      <Typography gutterBottom variant="h5" component="div">
        {/* {LangOb().Y21Nov18Jenifer} */}
      </Typography>
      <br />
    </div>
  )
}
