import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { VerseGenerator } from './jesusle/VerseGenerator'
import Cookies from 'universal-cookie'
import { Tasks } from './disciple/Tasks'

import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import DoneIcon from '@mui/icons-material/Done'

let done = []
let index = 0
let lastDone = 0

let sec = 1000
let min1 = sec * 60
let min5 = min1 * 5
let hour = min1 * 60
let day = hour * 24
let week = day * 7

let tasks = []

export default function Disciple() {
  tasks = Tasks()

  const cookies = new Cookies()
  const [verse, setVerse] = React.useState('Loading')
  const [question, setQuestion] = React.useState('')

  useEffect(() => {
    for (let i = 0; i < tasks.length; i++) {
      const element = tasks[i]
      index = i
      if (!cookies.get(element.id)) {
        showCurQuestion()
        break
      } else {
        addToDone()
      }
    }
    scriptureVerse()
  }, [question])

  useEffect(() => {
    showCurQuestion()
  }, [question])

  function addToDone() {
    const f = done.find((item) => item.id === tasks[index].id)
    if (!f) done.unshift(tasks[index])
  }

  function areAllAssignmentsComplete() {
    return index >= tasks.length - 1 && done.length === tasks.length
  }

  function clearAllIdsOnCookies() {
    for (let i = 0; i < tasks.length; i++) {
      const element = tasks[i]
      cookies.remove(element.id)
    }
    window.location.reload()
  }

  function clickOnAccept() {
    addToDone()

    if (index >= tasks.length) {
      //end of tasks
      console.log('end')
    } else {
      lastDone = Date.now()
      cookies.set(tasks[index].id, Date.now())
      index++
      showCurQuestion()
    }
    console.log('index af:', index)
  }

  function isDev() {
    return window.location.href.toLowerCase().includes('http://localhost:3000')
  }

  function onCoolDownTime() {
    let c = cookies.get(tasks[index].id)
    if (c) lastDone = c
    console.log('c', c)

    let dif = Date.now() - lastDone
    console.log('dif', dif)

    // 5 sec
    if (isDev()) return dif < 1000 * 5
    else return dif < day
  }

  function progress() {
    let ind = areAllAssignmentsComplete() ? tasks.length : index
    return 'Progress: ' + ind + ' / ' + tasks.length
  }

  function scriptureVerse() {
    let v = VerseGenerator()
    setVerse(v)
  }

  function showCurQuestion() {
    if (areAllAssignmentsComplete()) {
      console.log('end')
      setQuestion('You are all Done for now! Keep checking for new assignments')
      return
    }
    if (onCoolDownTime()) {
      console.log('onCoolDownTime')
      setQuestion(
        'On reflection time. You are all Done for now! Keep checking for new assignments. Get much time for reflection',
      )
      return
    }
    setQuestion(tasks[index].question)
  }

  function renderAccept(q) {
    let item = tasks[index]
    return (
      <div>
        <h3>{q}</h3>
        {!areAllAssignmentsComplete() && !onCoolDownTime() && (
          <div>
            <div style={{ padding: '2%', fontWeight: 'bold' }}>
              {item.instruction}
            </div>
            <Button
              sx={{ width: '50%' }}
              onClick={clickOnAccept}
              variant="contained"
            >
              accept
            </Button>
          </div>
        )}
      </div>
    )
  }

  function renderListItem(item) {
    return (
      <div>
        <ListItem key={item.id}>
          <ListItemAvatar>
            <Avatar style={{ backgroundColor: 'green' }}>
              <DoneIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={item.title} secondary={item.desc} />
        </ListItem>
        <p
          style={{
            margin: '0px 5px 0px 62px',
            textAlign: 'left',
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          {item.instruction}
        </p>
      </div>
    )
  }

  return (
    <div
      style={{
        backgroundColor: 'lightBlue',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      {isDev() && (
        <Button
          sx={{ width: '50%' }}
          onClick={clearAllIdsOnCookies}
          variant="contained"
        >
          reset
        </Button>
      )}
      <p>Short readiness to wander and above all to sin</p>
      <Typography
        gutterBottom
        component="div"
        style={{ padding: '1%', textAlign: 'center' }}
      >
        Disciple Instruction - {progress()}
      </Typography>
      <br />
      {renderAccept(question)}
      <Typography
        gutterBottom
        variant="h7"
        component="div"
        style={{ padding: '5%', textAlign: 'center' }}
      >
        {verse}
      </Typography>
      <br />
      {done.length > 0 && (
        <div style={{ paddingBottom: 10 }}>
          Done:
          <List
            sx={{
              width: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
              borderRadius: 10,
            }}
          >
            {done.map((item, i) => {
              return renderListItem(item)
            })}
          </List>
        </div>
      )}
    </div>
  )
}
