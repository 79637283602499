const quizDataEn = {
  title: 'The Antichrist Quiz',
  description:
    "The Antichrist, or anti-Christ, refers to people prophesied by the Bible to oppose Jesus Christ and substitute themselves in Christ's place before the Second Coming. The term Antichrist is found five times in the New Testament.",
  // img: 'start-example.jpg',
  questions: [
    {
      id: 1,
      text: 'Will rise up to unite the world under one government during the Tribulation?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 2,
      text: 'This global dictator will pose as an angel of light?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 3,
      text: 'Is his father the devil?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 4,
      text: 'He will overtly oppose Christ and at the same time pass himself off as Christ?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 5,
      text: 'Denies that Jesus is the Christ?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 6,
      text: 'He will deny the Father and the Son?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 7,
      text: 'He opposes and exalts himself above every so-called god or object of worship, so that he takes his seat in the temple of God, declaring himself to be God?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 8,
      text: 'When he comes on the scene, people will flock to him like flies to honey, and they will do anything he asks?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 9,
      text: 'He shall speak pompous words against the Most High?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 10,
      text: 'Will capture the attention and administration of the world?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 11,
      text: 'Will also speak arrogantly against God?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 12,
      text: 'He was given a mouth speaking great things and blasphemies?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 13,
      text: 'Whose appearance was greater than his fellows?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 14,
      text: 'In terms of his outward appearance, this man will be strikingly attractive?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 15,
      text: 'The combination of his magnetic personality, speaking ability, and extreme good looks will make him virtually irresistible to the masses?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 16,
      text: 'Acts by telling us that every living person will be required to worship this man?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 17,
      text: 'He was granted power to give breath to the image of the beast, that the image of the beast should both speak and cause as man as would not worship the image of the beast to be killed?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 18,
      text: 'During the last 3½ years of the Tribulation, the Antichrist will personify Satan himself?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 19,
      text: 'The coming of the lawless one is according to the working of Satan, with all power, signs, and lying wonders?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 20,
      text: 'Step by step, the Antichrist will promote himself from a European leader, to a world leader, to a tyrannical global dictator, and finally to a god?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 21,
      text: 'Satan is the first person and the father of the unholy trinity. The Antichrist will be the second member and the son of the unholy trinity. The False Prophet, the third member of the unholy trinity, will direct the worship and praise of the people back to the Antichrist?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 22,
      text: 'Whatever power the Antichrist has, he is allowed to have it by the overriding authority of Almighty God. As bad as the Tribulation will be, it will never be out of God’s control. There is a leash on Satan, and God is holding it. Satan will be able to do only that which God allows him to do during the Tribulation period?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 23,
      text: 'The Antichrist is a cultic leader?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
    {
      id: 24,
      text: 'Shall intend to change times and law?',
      alternatives: [
        {
          id: 1,
          text: 'True',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'False',
          isCorrect: false,
        },
      ],
    },
  ],
  results: [
    {
      id: 1,
      range: {
        from: 0,
        to: 20,
      },
      title: 'You only got a few questions right.',
      description:
        'Who is the liar but the one who denies that Jesus is the Christ? This is the antichrist, the one who denies the Father and the Son. — 1 John 2:22',
    },
    {
      id: 2,
      range: {
        from: 21,
        to: 23,
      },
      title: 'You got more than half the quiz right!',
      description:
        'Who is the liar but the one who denies that Jesus is the Christ? This is the antichrist, the one who denies the Father and the Son. — 1 John 2:22',
    },
    {
      id: 3,
      range: {
        from: 24,
        to: 24,
      },
      title: 'Congratulations, you got everything right!',
      description:
        'Who is the liar but the one who denies that Jesus is the Christ? This is the antichrist, the one who denies the Father and the Son. — 1 John 2:22',
      // img: 'result-example.jpg',
    },
  ],
};

export default quizDataEn;
