import React, { useState, useEffect } from 'react';
import ActionAreaCard from '../ActionAreaCard';
import { Roots } from '../../static/Root';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LangF } from '../Main';

// https://unpasoaldia.com/2020/04/26/la-hostia-sangrante-de-tixtla-un-corazon-vivo-que-sufre-milagro-eucaristico-mexico-21-de-octubre-2006/
// https://conexionmigrante.com/2022-/02-/16/que-es-el-milagro-eucaristico-de-tixtla-que-falta-para-se-le-reconozca/
// https://es.churchpop.com/2020/10/21/el-milagro-eucaristico-que-ocurrio-despues-del-fallecimiento-de-carlo-acutis/

export default function D2022_05_26_Ostia_Palpita() {

    return (
        <div style={{ backgroundColor: "lightBlue", display: "flex", justifyContent: "center", alignItems: "center", height: "", flexDirection: "column", padding: "3%" }}>

            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Milagro Eucarístico de Tixtla`}        
                {LangF() === 'en' && `Eucharistic Miracle of Tixtla`}       
                {LangF() === 'it' && `Miracolo Eucaristico di Tixtla`}
            </Typography>
            
            <Grid container style={{ padding: "5%",  alignItems: "center" }} >
                <iframe width="100%" height="300" src="https://www.youtube.com/embed/tdtqlpxxkQk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
            </Grid>

            <Typography  style={{ textAlign: "center"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Tixtla`}
                {LangF() === "en" && `Tixtla`}
                {LangF() === "it" && `Tixtla`}
                <br/>
                Mexico<br/>
                Oct 21, 2006
            </Typography>
 
            <br />
            <br />

            {/* Jul 13, 2021
            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Impactantes Imágenes de la Virgen de Fatima llorando en Toronto Canada, Ella nos pide Conversión`}        
                {LangF() === 'en' && `Impressive Images of the Virgin of Fatima crying in Toronto Canada, She asks us for Conversion`}       
                {LangF() === 'it' && `Immagini impressionanti della Vergine di Fatima che piange a Toronto in Canada, ci chiede la Conversione`}
            </Typography>
            
            <Grid container style={{ padding: "5%",  alignItems: "center" }} >
                <iframe width="100%" height="300" src="https://www.youtube.com/embed/UPuTh5btYb8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
            </Grid>

            <Typography  style={{ textAlign: "center"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Parroquia Santa Maria del Camino`}
                {LangF() === "en" && `Santa Maria del Camino Parish`}
                {LangF() === "it" && `Parrocchia Santa Maria del Camino`}
                <br/>
                Toronto, Canada<br/>
                May 30, 2022
            </Typography> */}



            {/* 3 imgs

            <Grid container style={{ padding: "0%" }} >
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                        <ActionAreaCard url="/miracles/D2022_05_26_Ostia_Palpita" img={Roots().D2022_05_26_Ostia_Palpita_1}/>
                    </div>
                </Grid>
                    
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                        <ActionAreaCard url="/miracles/D2022_05_26_Ostia_Palpita" img={Roots().D2022_05_26_Ostia_Palpita_2}/>
                    </div>
                </Grid>
                    
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                        <ActionAreaCard url="/miracles/D2022_05_26_Ostia_Palpita" img={Roots().D2022_05_26_Ostia_Palpita_3}/>
                    </div>
                </Grid>
            </Grid> */}
            <br />
        </div>
    );
}