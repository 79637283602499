import { BreakfastDiningOutlined } from '@mui/icons-material'

let verseEn = [
  //bible verses
  'If those days had not been cut short, no one would survive, but for the sake of the elect those days will be shortened. Matthew 24:22',
  'Ask and it will be given to you; seek and you will find; knock and the door will be opened to you. Matthew 7:7',
  'And unto man he said, Behold, the fear of the Lord, that is wisdom; and to depart from evil is understanding. Job 28:28',
  'No one will be able to stand against you all the days of your life. As I was with Moses, so I will be with you; I will never leave you nor forsake you. Joshua 1:5',
  // "But I tell you that anyone who looks at a woman lustfully has already committed adultery with her in his heart. Matthew 5:28",
  'So in everything, do to others what you would have them do to you, for this sums up the Law and the Prophets. Matthew 7:12',
  'Do not judge, or you too will be judged. For in the same way you judge others, you will be judged, and with the measure you use, it will be measured to you. Matthew 7:1-2',
  'I, even I, am he who blots out your transgressions, for my own sake, and remembers your sins no more . Isaiah 43:25',
  'The Lord our God is merciful and forgiving, even though we have rebelled against him. Daniel 9:9',
  'For I will forgive their wickedness and will remember their sins no more. Hebrews 8:12',
  'But if you do not forgive others their sins, your Father will not forgive your sins. Matthew 6:15',
  'You, Lord, are forgiving and good, abounding in love to all who call to you. Psalm 86:5',
  'Then Peter came to Him and said, “Lord, how often shall my brother sin against me, and I forgive him? Up to seven times?” Jesus said to him, “I do not say to you, up to seven times, but up to seventy times seven. Matthew 18:21-22',
  'Do not judge, and you will not be judged. Do not condemn, and you will not be condemned. Forgive, and you will be forgiven. Luke 6:37',
  'For if you forgive other people when they sin against you, your heavenly Father will also forgive you. Matthew 6:14',
  'Come near to God and he will come near to you. Wash your hands, you sinners, and purify your hearts, you double-minded. James 4:8',
  'Those whom I love I rebuke and discipline. So be earnest and repent. Revelation 3:19',
  'I tell you that in the same way there will be more rejoicing in heaven over one sinner who repents than over ninety-nine righteous persons who do not need to repent. Luke 15:7',
  'For I take no pleasure in the death of anyone, declares the Sovereign Lord. Repent and live!. Ezekiel 18:32',
  'In the past God overlooked such ignorance, but now he commands all people everywhere to repent. Acts 17:30',
  'I have not come to call the righteous, but sinners to repentance. Luke 5:32',
  "'Return to me,' declares the Lord Almighty, 'and I will return to you,' says the Lord Almighty. Zechariah 1:3",
  'I tell you, no! But unless you repent, you too will all perish. Luke 13:3',
  'Repent at my rebuke! Then I will pour out my thoughts to you, I will make known to you my teachings. Proverbs 1:23',
  'If we confess our sins, he is faithful and just and will forgive us our sins and purify us from all unrighteousness. 1 John 1:9',
  'Whoever conceals their sins does not prosper, but the one who confesses and renounces them finds mercy. Proverbs 28:13',
  'Produce fruit in keeping with repentance. Matthew 3:8',
  'For the Lord your God is gracious and compassionate. He will not turn his face from you if you return to him. 2 Chronicles 30:9',
  'The Lord is not slow in keeping his promise, as some understand slowness. Instead he is patient with you, not wanting anyone to perish, but everyone to come to repentance. 2 Peter 3:9',
  "From that time on Jesus began to preach, 'Repent, for the kingdom of heaven has come near.' Matthew 4:17",
  'If my people, who are called by my name, will humble themselves and pray and seek my face and turn from their wicked ways, then I will hear from heaven, and I will forgive their sin and will heal their land. 2 Chronicles 7:14',
  'For God so loved the world that he gave his one and only Son, that whoever believes in him shall not perish but have eternal life. John 3:16',
  'That they should seek God, in the hope that they might feel their way toward him and find him. Yet he is actually not far from each one of us. Acts 17:27',
  'You will seek me and find me, when you seek me with all your heart. Jeremiah 29:13',
  'I love those who love me, and those who seek me diligently find me. Proverbs 8:17',
  'But from there you will seek the Lord your God and you will find him, if you search after him with all your heart and with all your soul. Deuteronomy 4:29',
  'Call to me and I will answer you, and will tell you great and hidden things that you have not known. Jeremiah 33:3',
  'For at one time you were darkness, but now you are light in the Lord. Walk as children of light. Ephesians 5:8',
  'But seek first the kingdom of God and his righteousness, and all these things will be added to you. Matthew 6:33',
  'The young lions suffer want and hunger; but those who seek the Lord lack no good thing. Psalm 34:10',
  "But may all who seek you rejoice and be glad in you; may those who love your salvation say continually, 'Great is the Lord!'. Psalm 40:16",
  'And without faith it is impossible to please him, for whoever would draw near to God must believe that he exists and that he rewards those who seek him. Hebrews 11:6',
  'Blessed are those who keep his testimonies, who seek him with their whole heart. Psalm 119:2',
  'Delight yourself in the Lord, and he will give you the desires of your heart. Psalm 37:4',
  'Husbands, love your wives, as Christ loved the church and gave himself up for her. Ephesians 5:25',
  'Therefore a man shall leave his father and his mother and hold fast to his wife, and they shall become one flesh. Genesis 2:24',
  'Though one may be overpowered, two can defend themselves. A cord of three strands is not quickly broken. Ecclesiastes 4:12',
  'Therefore what God has joined together, let no one separate. Mark 10:9',
  'Do everything in love. 1 Corinthians 16:14',
  'My command is this: Love each other as I have loved you. John 15:12',
  'And now these three remain: faith, hope and love. But the greatest of these is love. 1 Corinthians 13:13',
  'Love is patient, love is kind. It does not envy, it does not boast, it is not proud. It does not dishonor others, it is not self-seeking, it is not easily angered, it keeps no record of wrongs. 1 Corinthians 13:4-5',
  'Owe no one anything, except to love each other, for the one who loves another has fulfilled the law. Romans 13:8',
  'Whoso findeth a wife findeth a good thing, and obtaineth favour of the Lord. Proverbs 18:22',
  'However, let each one of you love his wife as himself, and let the wife see that she respects her husband. Ephesians 5:33',
  'House and riches are the inheritance of fathers: and a prudent wife is from the Lord. Proverbs 19:14',
  'House and riches are the inheritance of fathers: and a prudent wife is from the Lord. Proverbs 19:14',
  //  "Let marriage be held in honor among all, and let the marriage bed be undefiled, for God will judge the sexually immoral and adulterous. Hebrews 13:4",
  "Therefore, as God's chosen people, holy and dearly loved, clothe yourselves with compassion, kindness, humility, gentleness and patience. Colossians 3:12",
  "But he gives us more grace. That is why Scripture says: 'God opposes the proud but shows favor to the humble.' James 4:6",
  'Humble yourselves before the Lord, and he will lift you up. James 4:10',
  'For all those who exalt themselves will be humbled, and those who humble themselves will be exalted. Luke 14:11',
  'When pride comes, then comes disgrace, but with humility comes wisdom. Proverbs 11:2',
  'The way of fools seems right to them, but the wise listen to advice. Proverbs 12:15',
  "Wisdom's instruction is to fear the LORD, and humility comes before honor. Proverbs 15:33",
  'Humility is the fear of the LORD; its wages are riches and honor and life. Proverbs 22:4',
  'Let someone else praise you, and not your own mouth; an outsider, and not your own lips. Proverbs 27:2',
  'He guides the humble in what is right and teaches them his way. Psalm 25:9',
  'For the LORD takes delight in his people; he crowns the humble with victory. Psalm 149:4',
  'Live in harmony with one another. Do not be proud, but be willing to associate with people of low position. Do not be conceited. Romans 12:16',
  'Do nothing out of selfish ambition or vain conceit. Rather, in humility value others above yourselves, not looking to your own interests but each of you to the interests of the others. Philippians 2:3-4',
  'Nor are you to be called instructors, for you have one Instructor, the Messiah. The greatest among you will be your servant. For those who exalt themselves will be humbled, and those who humble themselves will be exalted. Matthew 23:10',
  'Nor are you to be called instructors, for you have one Instructor, the Messiah. Matthew 23:10',
  'The greatest among you will be your servant. Matthew 23:11',
  'For those who exalt themselves will be humbled, and those who humble themselves will be exalted. Matthew 23:12',
  'Why, you do not even know what will happen tomorrow. What is your life? You are a mist that appears for a little while and then vanishes. James 4:14',
  'Now if you obey me fully and keep my covenant, then out of all nations you will be my treasured possession. Although the whole earth is mine. Exodus 19:5',
  'Love the LORD your God and keep his requirements, his decrees, his laws and his commands always. Deuteronomy 11:1',
  'As the Father has loved me, so have I loved you. Now remain in my love. John 15:9',
  'You are my friends if you do what I command. John 15:14',
  'If you love me, keep my commands. John 14:15',
  "He replied, 'Blessed rather are those who hear the word of God and obey it.' Luke 11:28",
  'For just as through the disobedience of the one man the many were made sinners, so also through the obedience of the one man the many will be made righteous. Romans 5:19',
  'As obedient children, do not conform to the evil desires you had when you lived in ignorance. 1 Peter 1:14',
  'But just as he who called you is holy, so be holy in all you do. 1 Peter 1:15',
  'But whoever looks intently into the perfect law that gives freedom, and continues in it—not forgetting what they have heard, but doing it—they will be blessed in what they do. James 1:25',
  'Wisdom is more precious than rubies; nothing you desire can compare with her. Proverbs 3:15',
  'The fruit of the righteous is a tree of life, and he who wins souls is wise. Proverbs 11:30',
  'Whoever is wise, let him heed these things and consider the great love of the LORD. Psalm 107:43',
  'Fear of the Lord is the foundation of true knowledge, but fools despise wisdom and discipline. Proverbs 1:7',
  //
  'The Lord is my light and my salvation; whom shall I fear? the Lord is the strength of my life; of whom shall I be afraid? Psalms 27:1',
  'And he sat down, and called the twelve, and saith unto them, If any man desire to be first, the same shall be last of all, and servant of all. Mark 9:35',
  'So ought men to love their wives as their own bodies. He that loveth his wife loveth himself. Ephesians 5:28',
  'Honor your father and your mother so that your life will be long on the fertile land that the Lord your God is giving you. Exodus 20:12',
  'As a mother comforts her child, so I will comfort you; in Jerusalem you will be comforted. Isaiah 66:13',
  "Parents, don't provoke your children in a way that ends up discouraging them. Colossians 3:21",
  "My son, keep your father's command; don't abandon your mother's instruction. Proverbs 6:20",
  'Friends love all the time, and kinsfolk are born for times of trouble. Proverbs 17:17',
  "Those who say, 'I love God' and hate their brothers or sisters are liars. After all, those who don't love their brothers or sisters whom they have seen can hardly love God whom they have not seen! 1 John 4:20",
  'Those who trouble their family will inherit the wind. The fool will be servant to the wise. Proverbs 11:29',
  "Train children in the way they should go; when they grow old, they won't depart from it. Proverbs 22:6",
  'Be tolerant with each other and, if someone has a complaint against anyone, forgive each other. As the Lord forgave you, so also forgive each other. Colossians 3:13',
  "As for parents, don't provoke your children to anger, but raise them with discipline and instruction about the Lord. Ephesians 6:4",
  'We love because God first loved us. 1 John 4:19',
  'Look at how good and pleasing it is when families live together as one. Psalm 133:1',
  "But if someone doesn't provide for their own family, and especially for a member of their household, they have denied the faith. They are worse than those who have no faith. 1 Timothy 5:8",
  "No doubt about it: children are a gift from the Lord; the fruit of the womb is a divine reward. The children born when one is young are like arrows in the hand of a warrior. The person who fills a quiver full with them is truly happy! They won't be ashamed when arguing with their enemies in the gate. Psalm 127:3-5",
  'In the same way, though there are many of us, we are one body in Christ, and individually we belong to each other. Romans 12:5',
  //Forgiveness
  'Repay no one evil for evil, but give thought to do what is honorable in the sight of all. Romans 12:17',
  'For by grace you have been saved through faith. And this is not your own doing; it is the gift of God. Ephesian 2:8',
  'A soft answer turns away wrath, but a harsh word stirs up anger. Proverbs 15:1',
  'My child, if your heart is wise, then my heart too will be happy. Proverbs 23:15',
  'Blessed are the merciful, for they shall receive mercy. Matthew 5:7',
  'For I will be merciful toward their iniquities, and I will remember their sins no more. Hebrews 8:12',
  'A new commandment I give to you, that you love one another: just as I have loved you, you also are to love one another. John 13:34',
  'If your brother or sister sins against you, rebuke them; and if they repent, forgive them. Luke 17:3',
  'To him all the prophets bear witness that everyone who believes in him receives forgiveness of sins through his name. Acts 10:43',
  'So if you are offering your gift at the altar and there remember that your brother has something against you, leave your gift there before the altar and go. First be reconciled to your brother, and then come and offer your gift. Matthew 5:23-24',
  'He is the propitiation for our sins, and not for ours only but also for the sins of the whole world. 1 John 2:2',
  'Above all, keep loving one another earnestly, since love covers a multitude of sins. 1 Peter 4:8',
  'And when you stand praying, if you hold anything against anyone, forgive them, so that your father in heaven may forgive you your sins. Mark 11:25',
  'Blessed is the one whose transgressions are forgiven, whose sins are covered. Psalm 32:1',
  'Love prospers when a fault is forgiven, but dwelling on it separates close friends. Proverbs 17:9',
  'Brothers, if anyone is caught in any transgression, you who are spiritual should restore him in a spirit of gentleness. Keep watch on yourself, lest you too be tempted. Galatians 6:1',
  'Therefore, confess your sins to one another and pray for one another, that you may be healed. The prayer of a righteous person has great power as it is working. James 5:16',
  'But I say to you who hear:  love your enemies, do good to those who hate you. Luke 6:27',
  'If your brother sins against you, go and tell him his fault, between you and him alone. If he listens to you, you have gained your brother. Matthew 18:15',
  'Do not repay evil for evil or reviling for reviling, but on the contrary, bless, for to this you were called, that you may obtain a blessing. 1 Peter 3:9',
  'For the wages of sin is death, but the free gift of God is eternal life in Christ Jesus our Lord. Romans 6:23',
  'Rend your heart and not your garments. Return to the Lord your God, for he is gracious and compassionate, slow to anger and abounding in love, and he relents from sending calamity. Joel 2:13',
  'For his unfailing love toward those who fear him is as great as the height of the heavens above the earth. He has removed our sins as far from us as the east is from the west. Psalm 103:11-12',
  'He has delivered us from the domain of darkness and transferred us to the kingdom of his beloved son, in whom we have redemption, the forgiveness of sins. Colossians 1:13-14',
  'And Jesus said, “Father, forgive them, for they know not what they do.” And they cast lots to divide his garments. Luke 23:34',
  'And so I tell you, every kind of sin and slander can be forgiven, but blasphemy against the spirit will not be forgiven. Matthew 12:31',
  'Repent, then, and turn to God, so that your sins may be wiped out, that times of refreshing may come from the Lord. Acts 3:19',
  'Let all bitterness and wrath and anger and clamor and slander be put away from you, along with all malice. Ephesians 4:31',
  'And forgive us our debts, as we also have forgiven our debtors. Matthew 6:12',
  'Hatred stirs old quarrels, but love overlooks insults. Proverbs 10:12',
  'If we confess our sins, he is faithful and just to forgive us our sins and to cleanse us from all unrighteousness. 1 John 1:9',
  'Therefore, if anyone is in Christ, he is a new creation. The old has passed away; behold, the new has come. 2 Corinthians 5:17',
  'You, Lord, are forgiving and good, abounding in love to all who call to you. Psalm 86:5',
  "Put on then, as God's chosen ones, holy and beloved, compassionate hearts, kindness, humility, meekness, and patience, bearing with one another and, if one has a complaint against another, forgiving each other; as the Lord has forgiven you, so you also must forgive. Colossians 3:12-13",
  'Therefore whoever confesses Me before men, him I will also confess before My Father who is in heaven. But whoever denies Me before men, him I will also deny before My Father who is in heaven. Matthew 10:32-33',
  'Whoever has ears, let them hear. Matthew 13:9',
  'Christ does not force our will, He only takes what we give Him. But He does not give Himself entirely until He sees that we yield ourselves entirely to Him. St. Teresa of Avila',
  'Love your neighbor as yourself. Matthew 22:39',
  'Be at peace with your own soul, then heaven and earth will be at peace with you. St. Jerome',
  'Blessed are the peacemakers. Matthew 5:9',
  'Jesus replied, “Very truly I tell you, everyone who sins is a slave to sin. John 8:34',
  'You have been set free from sin and have become slaves to righteousness. Romans 6:18',
  'For the Spirit God gave us does not make us timid, but gives us power, love and self-discipline. 2 Timothy 1:7',
  'But you will receive power when the Holy Spirit comes on you; and you will be my witnesses in Jerusalem, and in all Judea and Samaria, and to the ends of the earth. Acts 1:8',
  'You are the light of the world. A town built on a hill cannot be hidden. Matthew 5:14',
  'As for you, always be sober. Endure hardships, do the work of preaching the gospel, and carry out your ministry to the fullest extent. 2 Timothy 4:5',
  'Therefore, we are ambassadors for Christ, since God is appealing to you through us. We implore you through Christ to be reconciled to God. 2 Corinthians 5:20',
  'For I am not ashamed of the gospel, since it is the power of God that offers salvation to everyone who has faith—to Jews first, and then to Gentiles as well. Romans 1:16',
  'Peter answered, “Repent, and be baptized, every one of you, in the name of Jesus Christ so that your sins may be forgiven, and you will receive the gift of the Holy Spirit. Acts 2:38',
  '',
  'Tribulation is the divine medicine. St. Augustine of Hippo',
  'It is time now for us to rise from sleep. The sleepy like to make excuses. St. Benedict of Nursia',
  // https://kidadl.com/articles/st-francis-of-assisi-quotes-patron-saint-of-animals
  'Because whoever has joined forces with God obtains three great privileges: omnipotence without power, intoxication without wine, and life without death. St. Francis of Assisi',
  'If God can work through me, he can work through anyone. St. Francis of Assisi',
  'And it is in death that we are born to eternal life. St. Francis of Assisi',
  'And it is in death that we are born to eternal life. St. Francis of Assisi',
  'He that covereth his sins shall not prosper: but whoso confesseth and forsaketh them shall have mercy. Proverbs 28:13',
  'He that covereth his sins shall not prosper: but whoso confesseth and forsaketh them shall have mercy. Proverbs 28:13',
  'He that covereth his sins shall not prosper: but whoso confesseth and forsaketh them shall have mercy. Proverbs 28:13',
  'He that covereth his sins shall not prosper: but whoso confesseth and forsaketh them shall have mercy. Proverbs 28:13',
  // , "We must not indulge in sexual immorality as some of them did, and twenty-three thousand fell in a single day. 1 Corinthians 10:8",
  // , "The woman talked to Joseph every day, but he refused to have sexual relations with her or even spend time with her. Genesis 39:10",
  // , "Flee from sexual immorality. Every other sin a person commits is outside the body, but the sexually immoral person sins against his own body. 1 Corinthians 6:18",
  'For I am the Lord. I do not change. Malachi 3:6',
  'For I am the Lord. I do not change. Malachi 3:6',
  // , "The body is not meant for sexual immorality, but for the Lord, and the Lord for the body. 1 Corinthians 6:12",
  'My sheep listen to my voice; I know them, and they follow me. John 10:27',
  'While you are proclaiming peace with your lips, be careful to have it even more fully in your heart. St. Francis of Assisi',
  'Nothing is anything more to me; everything is nothing to me, but Jesus: neither things nor persons, neither ideas nor emotions, neither honor nor sufferings. Jesus is for me honor, delight, heart and soul. St. Bernadette',
  'Bear the cross and do not make the cross bear you. St. Philip Neri',
  'The Rosary is THE WEAPON. St. Padre Pio',
  'O Mary, Conceived without Sin, Pray for Us Who Have Recourse to Thee. Blessed Virgin Mary',
  'If God causes you to suffer much, it is a sign that He has great designs for you, and that He certainly intends to make you a saint. St. Ignatius of Loyola',
  'Your tears were collected by the angels and were placed in a golden chalice, and you will find them when you present yourself before God. St. Padre Pio',
  'Satan fears and trembles before humble souls. Saint Pio',
  'Patience, Prayer & Silence… these are what give strength to the soul. St. Faustina',
  'I hear a voice inside that constantly says to me: Sanctify and sanctify! St. Padre Pio',
  'Never be in a hurry; do everything quietly and in a calm spirit. Do not lose your inner peace for anything whatsoever, even if your whole world seems upset. St. Francis of Sales',
  'Each Mass earns you a higher degree of glory in Heaven! St. Padre Pio',
  'Blessed is he who comes in the name of the Lord!. Matthew 21:9',
  'Therefore if any man be in Christ, he is a new creature: old things are passed away; behold, all things are become new. 2 Corinthians 5:17',
  'When I die, I will send down a shower of roses from the heavens, I will spend my heaven by doing good on earth. St. Therese of Lisieux',
  'The greatest method of praying is to pray the rosary. St. Francis of Sales',
  'As mariners are guided into port by the shining of a star, so Christians are guided to heaven by Mary. St. Thomas Aquinas',
  'Where there is no prayer and fasting, there are the demons. St. Theophan the Recluse',
  "There are no bad young people, there are young people who don't know they can be good and someone has to tell them. St. Juan Bosco",
  'Prayer is the oxygen of the soul. St. Padre Pio',
  'Miracles are not contrary to nature, but only contrary to what we know about nature. St. Augustine',
  'Prayer is the best weapon we possess, the key that opens the heart of God. St. Padre Pio',
  'The closer one approaches to God, the simpler one becomes. St. Teresa of Avila',
  "Start being brave about everything. Drive out darkness and spread light. Don't look at your weaknesses. Realize instead that in Christ crucified you can do everything. St. Catherine of Siena",
  "Don't allow the sad sight of human injustice to sadden your soul; someday you will see the unfailing justice of God triumph over it! St. Padre Pio",
  'No one heals himself by wounding another. St. Ambrose of Milan',
  'I am the bread of life: he that cometh to me shall never hunger; and he that believeth on me shall never thirst. John 6:35',
  'Those whose hearts are pure are temples of the Holy Spirit. St. Lucy',
  'Believe me, the writing of pious books, the composing of the sublimest poetry; all that does not equal the smallest act of self-denial. St. Therese of Lisiuex',
  'If the world could see the beauty of a soul without sin, all sinners, all non-believers would instantly convert. St. Padre Pio',
  // #200
  'Faith has to do with things that are not seen and hope with things that are not at hand. St. Thomas Aquinas',
  'The more a man uses moderation in his life, the more he is at peace, for he is not full of cares for many things. St. Anthony the Great',
  'If anyone wants to be a follower of mine, let him renounce himself and take up his cross and follow me. Mark 8:34',
  'Three things are necessary for the salvation of man: to know what he ought to believe; to know what he ought to desire; and to know what he ought to do. St. Thomas Aquinas',
  'To love is to will the good of another. St. Thomas Aquinas',
  'From this moment on, anything concerning me is no longer of any interest to me. I must belong entirely to God & God alone. Never to myself. St. Bernadette',
  'Tell everyone that God grants graces through the Immaculate Heart of Mary. St. Jacinta Marto',
  'In Jesus everything has an answer. Without him, only a big void. St. Padre Pio',
  'To maintain a joyful family: Each member of the family has to become, in a special way,the servant of the others. St. John Paul II',
  'Blessed be you, my God, for having created me. St. Clare of Assisi',
  'Let nothing Disturb you, Let nothing frighten you, Though all things pass, God does not change. Patience wins all things. But he lacks nothing who possesses God; For God alone suffices. St. Teresa of Avila',
  'Temptations are like soap, which, when spread over the clothes, seems to stain them, but in reality, purifies them. St. Francis of Sales',
  'For my flesh is meat indeed, and my blood is drink indeed. John 6:55',
  'We become what we love and who we love shapes what we become. St. Clare of Assisi',
  'Anxiety is the greatest evil that can befall a soul except sin.  God commands you to pray, but He forbids you to worry. St. Francis of Sales',
  "Today's society does not pray. That is why it is falling apart. St. Padre Pio",
  'Jesus is honey in the mouth, music in the ear, and a shout of joy in the heart. St. Bernard of Clairvaux',
  'Happy is the soul that reaches the level of perfection that God desires! St. Padre Pio',
  "If the soul would know the merit which one acquires in temptations suffered in patience and conquered, it would be tempted to say: 'Lord, send me temptations.' St. Padre Pio",
  'That which comes from satan begins with calmness and ends in storm, indifference and apathy. St. Padre Pio',
  //220
  'Every time we look at the Blessed Sacrament our place in heaven is raised forever. St. Gertrude the Great',
  'Humility is the virtue that requires the greatest amount of effort. St. Rose Philippine Duchesne',
  'The ones who have a voice must speak for those who are voiceless. St. Oscar Romero',
  'It is time now for us to rise from sleep. St. Benedict of Nursia',
  'Leave it all to Him, let go of yourself, lose yourself on the Cross, and you will find yourself entirely. St. Catherine of Siena',
  'The universe is not rich enough to buy the vote of an honest man. Pope St. Gregory the Great',
  'Even if the world were to capsize, if everything were to become dark, hazy, tumultuous, God would still be with us. St. Padre Pio',
  'Put yourself in God’s hands; He abandons no one. St. Andre Bessette',
  'After my death I will do more. My real mission will begin after my death. St. Padre Pio',
  'Never will anyone who says his Rosary every day become a formal heretic or be led astray by the devil. St. Louis de Montfort',
  //230
  'Each Holy Mass heard with devotion produces marvelous effects in our souls, abundent spiritual and material graces, that we ourselves do not know. St. Padre Pio',
  'Have patience with all things, but chiefly have patience with yourself. St. Francis of Sales',
  'Concentrate on pleasing God alone… and if He is pleased, so too should you be pleased. St. Padre Pio',
  'It is not enough for us to wear a cross. We must bear it in our hearts. St. Gemma Galgani',
  'Do not bestow your love on the world. St. Gabriel Possenti',
  'As the family goes, so goes the nation and so goes the whole world in which we live. St. John Paul II',
  'The more you allow yourself to be rooted in holy humility, the more intimate your soul`s communication with God will be. St. Padre Pio',
  'Remember that the devil has only one door by which to enter the soul: the will. St. Padre Pio',
]

let authorEn = [
  'Blessed Virgin Mary',
  'Genesis',
  'Deuteronomy',
  'Joshua',
  'Job',
  'Psalm',
  'Proverbs',
  'Ecclesiastes',
  'Jeremiah',
  'Joel',
  'Chronicles',
  'Daniel',
  'Isaiah',
  'Ezekiel',
  'Malachi',
  'Matthew',
  'Mark',
  'Luke',
  'John',
  'Corinthians',
  'Acts',
  'Timothy',
  'Romans',
  'Colossians',
  'Ephesians',
  'Peter',
  'James',
  'Galatians',
  'Exodus',
  'Philippians',
  'Revelation',
  'Hebrews',
  'Pope St. Gregory the Great',
  'St. Andre Bessette',
  'St. Anthony the Great',
  'St. Benedict of Nursia',
  'St. Bernadette',
  'St. Bernard of Clairvaux',
  'St. Catherine of Siena',
  'St. Clare of Assisi',
  'St. Dominic',
  'St. Faustina',
  'St. Francis of Sales',
  'St. Francis of Assisi',
  'St. Gabriel Possenti',
  'St. Gemma Galgani',
  'St. Gertrude the Great',
  'St. Ignatius of Loyola',
  'St. Jacinta Marto',
  'St. Jerome',
  'St. John Paul II',
  'St. Juan Bosco',
  'St. Louis de Montfort',
  'St. Lucy',
  'St. Oscar Romero',
  'St. Padre Pio',
  'St. Philip Neri',
  'St. Rose Philippine Duchesne',
  'St. Teresa of Avila',
  'St. Theophan the Recluse',
  'St. Therese of Lisiuex',
  'St. Thomas Aquinas',
]

export function VerseGenerator() {
  let i = getRandomArbitrary(0, verseEn.length)
  return verseEn[i]
}

function getRandomArbitrary(min, max) {
  return Math.ceil(Math.random() * (max - min) + min)
}

export function ReturnAuthors(verse) {
  let author
  for (let i = 0; i < authorEn.length; i++) {
    if (verse.includes(authorEn[i])) {
      author = authorEn[i]
      break
    }
  }
  return GiveMeAuthors(author)
}

function GiveMeAuthors(startWith) {
  let res = [startWith]

  while (res.length < 7) {
    let i = getRandomArbitrary(0, authorEn.length)
    if (authorEn[i] && res.indexOf(authorEn[i]) === -1) res.push(authorEn[i])
  }

  return res
}
