import React, { useState, useEffect } from 'react';
import ActionAreaCard from '../ActionAreaCard';
import { Roots } from '../../static/Root';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LangF } from '../Main';

export default function D2022_05_26_Ostia_Palpita() {

    return (
        <div style={{ backgroundColor: "lightBlue", display: "flex", justifyContent: "center", alignItems: "center", height: "", flexDirection: "column", padding: "3%" }}>

            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Milagro Eucarístico de San Mauro La Bruca`}        
                {LangF() === 'en' && `Eucharistic Miracle of San Mauro La Bruca`}       
                {LangF() === 'it' && `Miracolo Eucaristico di San Mauro La Bruca`}
            </Typography>
            
            <Grid item lg={4} md={6} sm={12} xs={12}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                    <ActionAreaCard url="/miracles/D1969_07_25_San_Mauro_La_Bruca" img={Roots().D1969_07_25_San_Mauro_La_Bruca}/>
                </div>
            </Grid>

            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h5" component="div" >
                {LangF() === "es" && `El Milagro Eucarístico sucedidoen Siena en 1730 es similar al deSan Mauro la Bruca, acontecidoen 1969. Unos ladrones entrarona la iglesia parroquial y se apode-raron de algunos objetos sagrados,entre ellos la píside con Partículasconsagradas. Éstos botaron lashostias que a la mañana siguientefueron encontradas por un niño.Las partículas se mantienenintactas y su culto ha sido autori-zado por las autoridades eclesiales.`}        
                {LangF() === 'en' && `At San Mauro la Bruca unknown thieves secretly enteredthe church and stole severalsacred objects - one was the pyx (ciborium) that containedconsecrated Hosts. The thievesthrew the Hosts aside; a childdiscovered them. The Hosts arepreserved to this day.`}       
                {LangF() === 'it' && `Nel 1969, a San Mauro La Bruca ignoti ladri, penetrati di nascosto nella chiesa parrocchiale, s’impossessarono di alcuni oggetti sacri, tra cui la pisside contenente delle Particole consacrate. Le Ostie furono ritrovate la mattina seguente e ancora oggi si mantengono intatte.`}
            </Typography>

            <Grid item lg={4} md={6} sm={12} xs={12}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                    <ActionAreaCard url="/miracles/D1969_07_25_San_Mauro_La_Bruca" img={Roots().D1969_07_25_San_Mauro_La_Bruca_1}/>
                </div>
            </Grid>
            
            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h6" component="div" >
                {LangF() === "es" && `En la noche del 25 de julio de 1969, unos ladrones  entraron  furtivamente  dentrode la iglesia parroquial de San Mauro laBruca con la intención de robar los objetos máspreciosos. Después de haber roto el tabernáculo,se  llevaron  la  píside  con  numerosas  Hostias consagradas. Apenas salieron de la iglesia, bota-ron las Hostias en un callejón. Al día siguiente,un niño notó que en una esquina del callejón pordonde  pasaba  estaban  las  Hostias.  Después  dehaberlas  recogido,  las  entregó  de  inmediato  alpárroco.  Sólo  en  1994,  después  de  25  años  de exhaustivos análisis, Monseñor Biagio D’Agostino,Obispo  del  Valle  de  la  Lucania,  reconoció  laconservación  milagrosa  de  las  Partículas  autori-zando  consecuentemente  su  culto.  Se  sabe  quegracias a la experiencia de análisis realizados por científicos, luego de seis meses la harina ácima se deteriora gradualmente hasta el punto que luegode  un  par  de  años  se  reduce  a  una  materia pegajosa y más tarde en polvo.`}        
                {LangF() === 'en' && `On the night of July 25, 1969, some thievesbroke into the parish church of San Maurola Bruca with the intention of stealingsome of the more precious objects. After they hadpried open the tabernacle, they took a ciboriumcontaining  many  consecrated  Hosts.  Once  theyleft the church, the thieves emptied the ciboriumand threw the Hosts on a footpath. On the follo-wing morning, a child noticed the pile of Hostsat the intersection of the road and gathering themup,  he  immediately  gave  them  to  the  pastor.  Itwas  only  in  1994,  after  25  years  of  detailed analysis, that Msgr. Biagio D’Agostino, Bishop ofVallo  della  Lucania,  acknowledged  the  miracu-lous preservation of the Hosts and authorized thecult. The conclusion of any chemical and scienti-fic analysis acknowledges that after just 6 monthswheat  flour  severely  deteriorates  and  in  a  fewyears turns gelatinous and then, finally, to dust.`}       
                {LangF() === 'it' && `Nella notte del 25 luglio 1969 ignoti ladri, sprezzanti di ogni senso del Sacro, penetravano nella Chiesa Parrocchiale di San Mauro La Bruca e rubarono, oltre alle Reliquie dei Santi Patroni e a tanti oggetti Sacri, anche il Calice d’oro, che conteneva le Ostie consacrate, custodito nel Tabernacolo. Appena usciti dalla Chiesa i ladri gettarono le Sante Particole (Ostie), ed il coperchio del Calice che le conteneva, su un muricciolo davanti alla porta laterale. La mattina seguente, le Ostie profanate furono trovate dalla piccola Gerardina Amato. Il parroco Don Pasquale Allegro, avvertito del ritrovamento, raccolse le Ostie, in numero di 63, ricollocandole nel Tabernacolo e avverti il Vescovo di Vallo della Lucania, Mons. Biagio D’Agostino, che con decreto del 25 luglio 1970, stabilì che le Ostie profanate fossero conservate in perpetua Adorazione e Riparazione nella Chiesa Parrocchiale; stabilì inoltre che il 25 luglio di ogni anno fosse celebrata una solenne Giornata Eucaristica di riparazione. Donò infine alla Chiesa che Egli aveva elevato alla dignità di Santuario Eucaristico l’ostensorio, per custodirvi le Sacre Ostie profanate, che furono sigillate in esso.  Da oltre 40 anni le Sante Ostie sono conservate intatte.`}
            </Typography>

            <br/>

            <Typography  style={{ textAlign: "center"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `San Mauro La Bruca`}
                {LangF() === "en" && `San Mauro La Bruca`}
                {LangF() === "it" && `San Mauro La Bruca`}
                <br/>
                Italy<br/>
                Jul 25, 1969
            </Typography>
           
            <br />
        </div>
    );
}