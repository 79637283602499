import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./Home";
import Verse from "./Verse";
import Miracles from "./Miracles";
import Medal from "./Medal";
import Directory from "./Directory";
import Jesusle from "./Jesusle";
import Disciple from "./Disciple";
import Evidence from "./Evidence";
import Dominican from "./Dominican";

import { GeneralTimeline } from "./VerticalTimelineComp";
import { SaintsTimeline } from "./VerticalTimelineComp";
import { AntiChristQuiz } from "./QuizComp";

import { LangOb } from "../static/Languages";

//miracles reporting
import D2022_09_11_Sonora from "./miracles/D2022_09_11_Sonora";
import D2022_07_24_Guadalajara from "./miracles/D2022_07_24_Guadalajara";
import D2022_05_30_Mary_Cries_Toronto from "./miracles/D2022_05_30_Mary_Cries_Toronto";
import D2022_05_26_Ostia_Palpita from "./miracles/D2022_05_26_Ostia_Palpita";
import D2019_11_28_San_Juan from "./miracles/D2019_11_28_San_Juan";
import D2018_10_30_California from "./miracles/D2018_10_30_California";
import D2015_09_15_San_Jose from "./miracles/D2015_09_15_San_Jose";
import D2013_12_25_Legnica from "./miracles/D2013_12_25_Legnica";
import D2008_10_12_Sokolka from "./miracles/D2008_10_12_Sokolka";
import D2006_10_21_Tixtla from "./miracles/D2006_10_21_Tixtla";
import D1996_08_18_Buenos_Aires from "./miracles/D1996_08_18_Buenos_Aires";
import D1991_12_08_Betania from "./miracles/D1991_12_08_Betania";
import D1969_07_25_San_Mauro_La_Bruca from "./miracles/D1969_07_25_San_Mauro_La_Bruca";
import D1948_04_04_Rosano from "./miracles/D1948_04_04_Rosano";
import D1907_03_25_Silla from "./miracles/D1907_03_25_Silla";
import D1631_08_03_Dronero from "./miracles/D1631_08_03_Dronero";
import D750_Lanciano from "./miracles/D750_Lanciano";

export default function Routing() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" caseSensitive={false} element={<Miracles />} />
        <Route
          exact
          path="/jesusle"
          caseSensitive={false}
          element={<Jesusle />}
        />
        <Route
          exact
          path="/disciple"
          caseSensitive={false}
          element={<Disciple />}
        />
        <Route exact path="/jc" caseSensitive={false} element={<Disciple />} />
        <Route
          exact
          path="/jesus"
          caseSensitive={false}
          element={<GeneralTimeline />}
        />
        <Route
          exact
          path="/saints"
          caseSensitive={false}
          element={<SaintsTimeline />}
        />
        <Route
          exact
          path="/quiz"
          caseSensitive={false}
          element={<AntiChristQuiz />}
        />
        <Route exact path="/verse" caseSensitive={false} element={<Verse />} />
        <Route
          exact
          path="/miracles"
          caseSensitive={false}
          element={<Miracles />}
        />
        <Route exact path="/medal" caseSensitive={false} element={<Medal />} />
        <Route
          exact
          path="/directory"
          caseSensitive={false}
          element={<Directory />}
        />
        <Route exact path="/about" caseSensitive={false} element={<About />} />
        <Route
          exact
          path="/evidence"
          caseSensitive={false}
          element={<Evidence />}
        />
        <Route
          exact
          path="/dominican"
          caseSensitive={false}
          element={<Dominican />}
        />
        <Route exact path="/home" caseSensitive={false} element={<Home />} />
        <Route path="*" caseSensitive={false} element={<Miracles />} />
        <Route
          exact
          path="/miracles/D2022_09_11_Sonora"
          caseSensitive={false}
          element={<D2022_09_11_Sonora />}
        />
        <Route
          exact
          path="/miracles/D2022_07_24_Guadalajara"
          caseSensitive={false}
          element={<D2022_07_24_Guadalajara />}
        />
        <Route
          exact
          path="/miracles/D2022_05_30_Mary_Cries_Toronto"
          caseSensitive={false}
          element={<D2022_05_30_Mary_Cries_Toronto />}
        />
        <Route
          exact
          path="/miracles/D2022_05_26_Ostia_Palpita"
          caseSensitive={false}
          element={<D2022_05_26_Ostia_Palpita />}
        />
        <Route
          exact
          path="/miracles/D2019_11_28_San_Juan"
          caseSensitive={false}
          element={<D2019_11_28_San_Juan />}
        />
        <Route
          exact
          path="/miracles/D2018_10_30_California"
          caseSensitive={false}
          element={<D2018_10_30_California />}
        />
        <Route
          exact
          path="/miracles/D2015_09_15_San_Jose"
          caseSensitive={false}
          element={<D2015_09_15_San_Jose />}
        />
        <Route
          exact
          path="/miracles/D2013_12_25_Legnica"
          caseSensitive={false}
          element={<D2013_12_25_Legnica />}
        />
        <Route
          exact
          path="/miracles/D2008_10_12_Sokolka"
          caseSensitive={false}
          element={<D2008_10_12_Sokolka />}
        />
        <Route
          exact
          path="/miracles/D2006_10_21_Tixtla"
          caseSensitive={false}
          element={<D2006_10_21_Tixtla />}
        />
        <Route
          exact
          path="/miracles/D1996_08_18_Buenos_Aires"
          caseSensitive={false}
          element={<D1996_08_18_Buenos_Aires />}
        />
        <Route
          exact
          path="/miracles/D1991_12_08_Betania"
          caseSensitive={false}
          element={<D1991_12_08_Betania />}
        />
        <Route
          exact
          path="/miracles/D1969_07_25_San_Mauro_La_Bruca"
          caseSensitive={false}
          element={<D1969_07_25_San_Mauro_La_Bruca />}
        />
        <Route
          exact
          path="/miracles/D1948_04_04_Rosano"
          caseSensitive={false}
          element={<D1948_04_04_Rosano />}
        />
        <Route
          exact
          path="/miracles/D1907_03_25_Silla"
          caseSensitive={false}
          element={<D1907_03_25_Silla />}
        />
        <Route
          exact
          path="/miracles/D1631_08_03_Dronero"
          caseSensitive={false}
          element={<D1631_08_03_Dronero />}
        />
        <Route
          exact
          path="/miracles/D750_Lanciano"
          caseSensitive={false}
          element={<D750_Lanciano />}
        />
      </Routes>
    </Router>
  );
}

function About() {
  return (
    <div>
      <h2
        style={{
          backgroundColor: "lightBlue",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "6%",
          height: 400,
        }}
      >
        {LangOb().Mission}
      </h2>
    </div>
  );
}
