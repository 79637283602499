const quizDataEs = {
  title: 'Trivia del Anticristo',
  description:
    "El nombre Anticristo se refiere a una figura que cumpliría con las profecías bíblicas concernientes al antagonista de Cristo. El uso de este vocablo aparece cinco veces en la Biblia tanto en forma singular como en plural.",
  // img: 'start-example.jpg',
  questions: [
    {
      id: 1,
      text: '¿Se levantará para unir al mundo bajo un solo gobierno durante la Tribulación?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 2,
      text: '¿Este dictador global se hará pasar por un ángel de luz?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 3,
      text: '¿Es su padre el diablo?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 4,
      text: '¿Se opondrá abiertamente a Cristo y al mismo tiempo se hará pasar por Cristo?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 5,
      text: '¿Negará que Jesús es el Cristo?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 6,
      text: '¿Negará al Padre y al Hijo?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 7,
      text: '¿Se opone y se exalta a sí mismo por encima de todo supuesto dios u objeto de adoración, de modo que se sienta en el templo de Dios y se declara Dios?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 8,
      text: 'Cuando entra en escena, la gente acudirá a él como moscas a la miel, y harán todo lo que les pida?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 9,
      text: 'Hablará palabras pomposas contra el Altísimo?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 10,
      text: 'Captará la atención y la administración del mundo?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 11,
      text: 'También hablará con arrogancia contra Dios?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 12,
      text: 'Se le dio una boca que hablaba grandes cosas y blasfemias?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 13,
      text: 'Cuya apariencia era más grande que la de sus compañeros?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 14,
      text: 'En términos de su apariencia exterior, este hombre será sorprendentemente atractivo?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 15,
      text: 'La combinación de su personalidad magnética, su habilidad para hablar y su extrema belleza lo harán virtualmente irresistible para las masas?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 16,
      text: 'Actúa diciéndonos que toda persona viva deberá adorar a este hombre?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 17,
      text: 'Se le concedió el poder de dar aliento a la imagen de la bestia, para que la imagen de la bestia hablara y causara que, cualquier hombre, que no adoraría la imagen de la bestia, podra ser asesinado?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 18,
      text: 'Durante los últimos 3 años y medio de la Tribulación, el Anticristo personificará al mismo Satanás?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 19,
      text: 'La venida del inicuo es obra de Satanás, con todo poder, señales y prodigios mentirosos?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 20,
      text: 'Paso a paso, el Anticristo se promoverá a sí mismo de un líder europeo, a un líder mundial, a un dictador global tiránico y finalmente a un dios?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 21,
      text: 'Satanás es la primera persona y el padre de la trinidad impía. El Anticristo será el segundo miembro y el hijo de la trinidad impía. El Falso Profeta, el tercer miembro de la trinidad impía, dirigirá la adoración y la alabanza del pueblo de regreso al Anticristo?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 22,
      text: 'Cualquiera que sea el poder que tenga el Anticristo, la autoridad suprema del Dios Todopoderoso le permite tenerlo. Por muy mala que sea la Tribulación, nunca estará fuera del control de Dios. Satanás tiene una correa y Dios la está sujetando. Satanás podrá hacer solo lo que Dios le permita hacer durante el período de la Tribulación?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 23,
      text: 'El Anticristo es un líder de culto?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
    {
      id: 24,
      text: 'Tendrá la intención de cambiar los tiempos y la ley?',
      alternatives: [
        {
          id: 1,
          text: 'Verdadero',
          isCorrect: true,
        },
        {
          id: 2,
          text: 'Falso',
          isCorrect: false,
        },
      ],
    },
  ],
  results: [
    {
      id: 1,
      range: {
        from: 0,
        to: 20,
      },
      title: 'Solo tienes unas pocas preguntas correctas.',
      description:
        '¿Quién es el mentiroso, sino el que niega que Jesús es el Cristo? Este es anticristo, el que niega al Padre y al Hijo. — 1 Juan 2:22',
    },
    {
      id: 2,
      range: {
        from: 21,
        to: 23,
      },
      title: 'Tienes más de la mitad del cuestionario correcto!',
      description:
        '¿Quién es el mentiroso, sino el que niega que Jesús es el Cristo? Este es anticristo, el que niega al Padre y al Hijo. — 1 Juan 2:22',
    },
    {
      id: 3,
      range: {
        from: 24,
        to: 24,
      },
      title: 'Felicidades, tienes todo bien!',
      description:
        '¿Quién es el mentiroso, sino el que niega que Jesús es el Cristo? Este es anticristo, el que niega al Padre y al Hijo. — 1 Juan 2:22',
      // img: 'result-example.jpg',
    },
  ],
};

export default quizDataEs;