import * as React from 'react'
import ActionAreaCard from './ActionAreaCard'
import { Roots } from '../static/Root'
import { LangOb } from '../static/Languages'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

export default function Home() {
  return (
    <div
      style={{
        backgroundColor: 'lightBlue',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '',
        flexDirection: 'row',
      }}
    >
      <Grid container style={{ padding: '5%' }}>
        {/* Miracles */}
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/miracles"
              img={Roots().Miracles}
              title={LangOb().Miracles}
              detail={LangOb().MiraclesPar}
            />
          </div>
        </Grid>

        {/* Jesus */}
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/jesus"
              img={Roots().AC0}
              title={LangOb().Jesus}
              detail={LangOb().JesusPar}
            />
          </div>
        </Grid>

        {/* Jesusle */}
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/jesusle"
              img={Roots().BC1000}
              title={LangOb().Jesusle}
              detail={LangOb().JesuslePar}
            />
          </div>
        </Grid>

        {/* Disciple */}
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/disciple"
              img={Roots().Disciples}
              title={LangOb().Disciple}
              detail={LangOb().DisciplePar}
            />
          </div>
        </Grid>

        {/* Saints */}
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/saints"
              img={Roots().AC1194}
              title={LangOb().Saints}
              detail={LangOb().SaintsPar}
            />
          </div>
        </Grid>

        {/* Antichrist quiz */}
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/quiz"
              img={Roots().Anti}
              title={LangOb().Quiz}
              detail={LangOb().QuizPar}
            />
          </div>
        </Grid>

        {/* Antichrist verse */}
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/verse"
              img={Roots().Verse}
              title={LangOb().Verse}
              detail={LangOb().VersePar}
            />
          </div>
        </Grid>

        {/* Directory */}
        {/* <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
              height: 400,
            }}
          >
            <ActionAreaCard
              url="/directory"
              img={Roots().Library}
              title={LangOb().Directory}
              detail={LangOb().DirectoryPar}
            />
          </div>
        </Grid> */}

      </Grid>
    </div>
  )
}
