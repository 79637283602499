import React, { useState, useEffect } from 'react';
import ActionAreaCard from '../ActionAreaCard';
import { Roots } from '../../static/Root';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LangF } from '../Main';

export default function D2022_05_26_Ostia_Palpita() {
    return (
        <div style={{ backgroundColor: "lightBlue", display: "flex", justifyContent: "center", alignItems: "center", height: "", flexDirection: "column", padding: "3%" }}>

            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Milagro Eucarístico de Legnica`}        
                {LangF() === 'en' && `Eucharistic Miracle of Legnica`}       
                {LangF() === 'it' && `Miracolo Eucaristico di Legnica`}
            </Typography>
            
            <Grid container style={{ padding: "5%",  alignItems: "center" }} >
                <iframe width="100%" height="300" src="https://www.youtube.com/embed/m0xFDvTbt64" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
            </Grid>

            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h5" component="div" >
                {LangF() === "es" && `El 25 de diciembre de 2013, en la iglesia dedicada a San Jacinto en Legnica (Polonia), durante la Santa Misa, en el momento de la Comunión, una Hostia se cayó al suelo inadvertidamente. Se colocó de inmediato dentro de un recipiente con agua y se cerró en el tabernáculo como prescribe el Código de Derecho Canónico en estos casos. Algunos días después, apareció sobre la zona una mancha roja que el entonces obispo de Legnica, Stefan Cichy, decidió mandar a analizar de inmediato a una comisión científica. Los resultados fueron sorprendentes y confirmaron que se trataba de un tejido similar al músculo cardiaco humano, con alteraciones que aparecen a menudo durante una agonía.`}        
                {LangF() === 'en' && `In St. Hyacinth’s church in Legnica, during Holy Mass, on December 25, 2013, a Host accidentally fell on the ground, during the distribution of Communion. It was immediately placed in a container with water and closed in the tabernacle as required by code of Canon Law. A few days later, a red spot appeared on Its surface. The Bishop of Legnica at the time, Stefan Cichy, decided to establish a commission for a scientific analysis. The results were surprising, and confirmed that It had similarities to “human heart muscle with alterations that often appear during the agony.`}       
                {LangF() === 'it' && `Il 25 dicembre del 2013, nella chiesa dedicata a San Giacinto a Legnica, durante la Santa Messa,  al momento della distribuzione della Comunione, un’Ostia cadde a terra inavvertitamente e venne subito riposta dentro un recipiente contenente acqua e chiuso nel tabernacolo come prescrive il Codice di Diritto Canonico in questi casi. Alcuni giorni dopo, sulla sua superficie apparve una macchia rossa che l’allora Vescovo di Legnica, Stefan Cichy, decise subito di fare analizzare da un’apposita commissione scientifica. I risultati furono sorprendenti, e confermarono che si trattava di tessuto simile a «muscolo cardiaco umano con alterazioni che appaiono di frequente durante un’agonia.`}
            </Typography>
            <br/>

            {/* <Grid item lg={4} md={6} sm={12} xs={12}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                    <ActionAreaCard url="/miracles/D1969_07_25_San_Mauro_La_Bruca" img={Roots().D1969_07_25_San_Mauro_La_Bruca_1}/>
                </div>
            </Grid> */}

            <br />
            <Typography  style={{ textAlign: "center"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Legnica`}
                {LangF() === "en" && `Legnica`}
                {LangF() === "it" && `Legnica`}
                <br/>
                Poland<br/>
                Dec 25, 2013
            </Typography>
 
            <br />

            <br />
        </div>
    );
}