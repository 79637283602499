import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function BasicSelect(props) {

    return (
        <Box>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Lang</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={props.lang}
                    label="Lang"
                    onChange={props.handleChange}
                >
                    <MenuItem value={"en"}>English</MenuItem>
                    <MenuItem value={"es"}>Español</MenuItem>
                    <MenuItem value={"it"}>Italiano</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}