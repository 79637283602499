import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import {
  ReturnAuthors,
  VerseGenerator,
} from '../elements/jesusle/VerseGenerator'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Cookies from 'universal-cookie'

export default function Jesusle() {
  const cookies = new Cookies()
  
  const [verse, setVerse] = React.useState('Loading')
  const [author, setAuthor] = React.useState()
  const [authors, setAuthors] = React.useState('Loading')

  const [answer, setAnswer] = React.useState()
  const [answerLabel, setAnswerLabel] = React.useState('The answer is:')
  const [tokens, setTokens] = React.useState(3)

  useEffect(() => {
    scriptureVerse()
    if (cookies.get(tokenName()) !== undefined) {
     let t = cookies.get(tokenName())
     setTokens(t)
    }
  }, [])

  async function scriptureVerse() {
    let v = VerseGenerator()
    let authors = ReturnAuthors(v)
    setAuthor(authors[0])
    v = removeAuthorFromVerse(v, authors[0])
    setVerse(v)

    authors.sort()
    console.log('authors', authors)

    let data = []
    for (let i = 0; i < authors.length; i++) {
      data.push({ label: authors[i] })
    }
    setAuthors(data)
  }

  const removeAuthorFromVerse = (verse, auth) => {
    let a = verse.split(auth)
    console.log('a', a)

    let ini = a[0].trim()
    ini = ini.substring(0, ini.length - 1)
    console.log('ini', ini)

    return ini
  }

  function isNumeric(str) {
    return (
      !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    ) // ...and ensure strings of whitespace fail
  }

  const clickOnGuess = (e) => {
    console.log('answer', answer)

    if (answer && author === answer.label) {
      console.log('OK')
      setAnswerLabel('You got it!:')
      cookies.set(tokenName(), 0, { path: '/' })
      setTokens(0)
    } else {
      console.log('Wrong')
      cookies.set(tokenName(), tokens - 1, { path: '/' })
      setTokens(tokens - 1)
    }
  }

  function tokenName() {
    let d = new Date();
    return 'tokens' + "-" + d.toDateString();
  }

  return (
    <div
      style={{
        backgroundColor: 'lightBlue',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '',
        flexDirection: 'column',
      }}
    >
      <Typography
        gutterBottom
        component="div"
        style={{ padding: '5%', textAlign: 'center' }}
      >
        Guess {tokens} tokens remaining
      </Typography>
      <br />
      <Typography
        gutterBottom
        variant="h6"
        component="div"
        style={{ padding: '5%', textAlign: 'center' }}
      >
        {verse}
      </Typography>
      <br />
      {tokens > 0 && (
        <div>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={authors}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Guess the bible book or Saint" />
            )}
            onChange={(event, newValue) => {
              setAnswer(newValue)
            }}
          />
          <Button sx={{ width: '100%' }} onClick={clickOnGuess}>
            Guess
          </Button>
        </div>
      )}
      {tokens === 0 && (
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          style={{ textAlign: 'center' }}
        >
          {answerLabel}
          <br />
          {author}
        </Typography>
      )}
      <Button sx={{ width: '100%' }}>
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '6%',
            }}
          >
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              style={{ textAlign: 'center' }}
            >
              Love Generation
              <br />
              The Sound of Freedom
              <br />A time of peace
            </Typography>
          </div>
        </Grid>
      </Button>
      Beta
    </div>
  )
}
