import React, { useState, useEffect } from 'react';
import ActionAreaCard from '../ActionAreaCard';
import { Roots } from '../../static/Root';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LangF } from '../Main';

export default function D2022_05_26_Ostia_Palpita() {

    return (
        <div style={{ backgroundColor: "lightBlue", display: "flex", justifyContent: "center", alignItems: "center", height: "", flexDirection: "column", padding: "3%" }}>

            {/* May 30, 2022 */}
            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `La VIRGEN de FATIMA Se MANIFIESTA LLORANDO y Da IMPORTANTE MENSAJE de la EUCARISTIA a la HUMANIDAD`}        
                {LangF() === 'en' && `The VIRGIN of FATIMA MANIFESTS CRYING and Gives IMPORTANT MESSAGE from the EUCHARIST to HUMANITY`}       
                {LangF() === 'it' && `LA VERGINE di FATIMA MANIFESTA PIANGENDO e DÀ MESSAGGIO IMPORTANTE dell'EUCARISTIA all'UMANITÀ`}
            </Typography>
            
            <Grid container style={{ padding: "5%",  alignItems: "center" }} >
                <iframe width="100%" height="300" src="https://www.youtube.com/embed/fk5MkyI7Ju0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
            </Grid>

            <Typography  style={{ textAlign: "center"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Franciscanos de Maria Toronto`}
                {LangF() === "en" && `Franciscans of Mary Toronto`}
                {LangF() === "it" && `Francescani di Mary Toronto`}
                <br/>
                Canada<br/>
                May 30, 2022
            </Typography>
  
            <br />
        </div>
    );
}