import * as React from 'react';
import ActionAreaCard from './ActionAreaCard';
import { Roots } from '../static/Root';
import { LangOb } from '../static/Languages';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function Verse() {
    return (
        <div style={{ backgroundColor: "lightBlue", display: "flex", justifyContent: "center", alignItems: "center", height: "", flexDirection: "column", padding: "10%" }}>
            <Typography gutterBottom variant="h5" component="div">
                {LangOb().OneJohn218}
            </Typography>
            <br />
            <Typography gutterBottom variant="h5" component="div">
                {LangOb().OneJohn222}
            </Typography>
            <br />
            <Typography gutterBottom variant="h5" component="div">
                {LangOb().OneJohn423}
            </Typography>
            <br />
            <Typography gutterBottom variant="h5" component="div">
                {LangOb().TwoJohn217}
            </Typography>
            <br />
            <Typography gutterBottom variant="h5" component="div">
                {LangOb().TwoThe214}
            </Typography>
            <br />
            <Typography gutterBottom variant="h5" component="div">
                {LangOb().TwoThe2710}
            </Typography>
            <br />
            <Typography gutterBottom variant="h5" component="div">
                {LangOb().Mat2424}
            </Typography>
            <br />
            <Typography gutterBottom variant="h5" component="div">
                {LangOb().Mar132122}
            </Typography>
            <br />
        </div>
    );
}