import React, { useState, useEffect } from 'react';
import ActionAreaCard from '../ActionAreaCard';
import { Roots } from '../../static/Root';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LangF } from '../Main';

export default function D2022_05_26_Ostia_Palpita() {

    return (
        <div style={{ backgroundColor: "lightBlue", display: "flex", justifyContent: "center", alignItems: "center", height: "", flexDirection: "column", padding: "3%" }}>

            <Typography  style={{ textAlign: "center" , padding: "3% 0 0 0"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Lágrimas y Sangre de Jesús en Rosano`}        
                {LangF() === 'en' && `Jesus Tears & Blood, Rosano`}       
                {LangF() === 'it' && `Le lacrime e il sangue di Gesù, Rosano`}
            </Typography>
            
            <Grid item lg={4} md={6} sm={12} xs={12}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                    <ActionAreaCard url="/miracles/D1948_04_04_Rosano" img={Roots().D1948_04_04_Rosano}/>
                </div>
            </Grid>
            
            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h6" component="div" >
                {LangF() === "es" && `El obispo Luciano Giovanntti escribió una carta explicando el milagro eucarístico en Rosano, Italia. El domingo de Pascua, 4 de abril de 1948, un grupo de personas que oraban notaron una lágrima que caía del ojo de una estatua del Sagrado Corazón de Jesús. La estatua era de tamaño natural y fue donada por una persona devota durante la Segunda Guerra Mundial. Presentaba el rostro de Cristo con una expresión que invita a todos a la oración, y el Corazón de Cristo estaba en el centro de Su pecho con la corona de espinas. Unos meses después, en junio, algunas monjas y otras personas presenciaron que la sangre fluía de la estatua nuevamente, y continuó desde 1948 hasta 1950.. La madre IIldegarde Cabitza, monja jefa de la comunidad, también fue testigo de ello, y decidieron trasladar la estatua a un cuarto secreto en los archivos del monasterio. Aquí también se guardaban todas las toallas para los dedos y los purificadores que estaban empapados con la Sangre de la estatua. Más tarde, el obispo de la diócesis de Arezzo se enteró y el Santo Oficio envió al padre Luigi Romoli para entrevistar a las monjas. No estuvo de acuerdo con las monjas acerca de mantener la estatua en una habitación secreta. El 14 de noviembre de 1950, el Santo Oficio ordenó sacar la estatua del cuarto secreto y más tarde en 1952 fue devuelta a Rosano. La comunidad de monjas de Rosano vivió los hechos con alegría y gran devoción.`}        
                {LangF() === 'en' && `Bishop Luciano Giovanntti wrote a letter explaining the Eucharistic miracle in Rosano, Italy. On Easter Sunday, April 4, 1948, a group of people praying noticed a tear drop that ran down from the eye of a statue of the Sacred Heart of Jesus. The statue was life-size and was donated by a devout person during WWII. It featured Christ’s face with an expression that invites all to prayer, and Christ’s Heart was in the center of His chest with the crown of thorns. A few months later in June, some nuns and others witnessed blood flowing from the statue again, and it continued from 1948 to 1950. Mother IIldegarde Cabitza, the head nun of the community, also witnessed it, and they decided to move the statue to a secret room at the monastery archives. This is also where all the finger towels and purificators that were soaked with the Blood from the statue were kept. Later the Bishop of the diocese of Arezzo learned about it, and the Holy Office sent Fr Luigi Romoli to interview the nuns. He disagreed with the nuns about keeping the statue in a secret room. On November 14, 1950, Holy Office ordered the statue out of the secret room and later in 1952 it was sent back to Rosano. The community of nuns in Rosano experienced the events with joy and great devotion.`}       
                {LangF() === 'it' && `Il vescovo Luciano Giovannitti ha scritto una lettera in cui spiegava il miracolo eucaristico a Rosano, in Italia. La domenica di Pasqua, 4 aprile 1948, un gruppo di persone in preghiera notò una lacrima che scendeva dall'occhio di una statua del Sacro Cuore di Gesù. La statua era a grandezza naturale ed è stata donata da un devoto durante la seconda guerra mondiale. Presentava il volto di Cristo con un'espressione che invita tutti alla preghiera e il Cuore di Cristo era al centro del Suo petto con la corona di spine. Alcuni mesi dopo, nel mese di giugno, alcune suore e altre hanno assistito di nuovo alla fuoriuscita di sangue dalla statua, che continuò dal 1948 al 1950. Ne fu testimone anche Madre Ildegarde Cabitza, la suora capo della comunità, che decisero di spostare la statua in una stanza segreta presso l'archivio del monastero. Questo è anche il luogo in cui venivano conservati tutti gli asciugamani per le dita e i purificatori imbevuti del Sangue della statua. In seguito ne venne a conoscenza il Vescovo della diocesi di Arezzo, e il Sant'Uffizio mandò don Luigi Romoli ad intervistare le monache. Non era d'accordo con le suore sul mantenere la statua in una stanza segreta. Il 14 novembre 1950 il Sant'Uffizio ordinò la statua fuori dalla stanza segreta e successivamente nel 1952 fu rispedita a Rosano. La comunità delle monache di Rosano ha vissuto gli eventi con gioia e grande devozione.`}
            </Typography>

            <Grid item lg={4} md={6} sm={12} xs={12}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                    <ActionAreaCard url="/miracles/D1948_04_04_Rosano" img={Roots().D1948_04_04_Rosano_1}/>
                </div>
            </Grid>

            <br/>

            <Typography  style={{ textAlign: "center"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Rosano`}
                {LangF() === "en" && `Rosano`}
                {LangF() === "it" && `Rosano`}
                <br/>
                Italy<br/>
                Apr 4, 1948
            </Typography>
           
            <br />
        </div>
    );
}