import { Roots } from "./Root";
//Header
const Home = "Home";
const Dominican = "Dominican";
const Evidence = "Evidence";
const JesusHeader = "Jesus Christ";
const Holy = "The Holy Trinity is Love";
const Obey = "OBEY";
const Header = "The Truth";
const SaintsHeader = "Jesus Christ's Church Saints";

//Footer
const Footer = "The Truth";
const Acutis = "Inspired by Carlo Acutis";

//Home tiles
const Miracles = "Miracles";
const MiraclesPar =
  "Do not despise the words of prophets, but test everything; hold fast to what is good. - 1 Thessalonians 5:20–21";

const Medal = "Medal";
const MedalPar =
  "Saint Benedict's Medal, free, and free shipping to USA and Canada";
const MedalPage1 =
  "This religious object is also a Christian symbol of opening doors and opening difficult paths. Tradition holds that it protects from curses, evil and vice, protects against diseases and protects good health. The Saint Benedict Medal was a gift Padre Pio often gave as a present to his visitors.";
const MedalForm = "Fill in the form";

const Directory = "Directory";
const DirectoryPar = "Churches Directory";

const Map = "Map";
const MapPar = "Churches Map";

const Jesus = "Jesus";
const JesusPar =
  "The son of god is born. Mt 1. The timeline of Jesus Christ the Son of God";

const Jesusle = "Jesusle";
const JesuslePar = "Quiz, can you guess who wrote it? Try it out";

const Disciple = "Disciple";
const DisciplePar =
  'Whoever wants to be my disciple must deny themselves and take up their cross and follow me." Matthew 16:24';

const Saints = "Saints";
const SaintsPar = "The timeline of Jesus Christ's church saints";

const Quiz = "Quiz";
const QuizPar =
  "The Antichrist quiz. '...the one who denies the Father and the Son' 1 John 2:22";

const Verse = "Verse";
const VersePar =
  "The Antichrist Bible verses. 'For false messiahs and false prophets will appear and produce great signs and omens'... Mt 24:24";

//Quiz
const Start = "Start";
//Result
const Restart = "Restart";
const Share = "Share your result";

const About = "About";
const Mission =
  "Our mission is to bring to light the only true, our Lord Jesus Christ!";

//Verse
const OneJohn218 =
  "Little children, it is the last hour: and as you have heard that Antichrist cometh, even now there are become many Antichrists: whereby we know that it is the last hour. — 1 John 2:18";
const OneJohn222 =
  "Who is the liar but the one who denies that Jesus is the Christ? This is the antichrist, the one who denies the Father and the Son. — 1 John 2:22";
const OneJohn423 =
  "By this you know the Spirit of God: every spirit that confesses that Jesus Christ has come in the flesh is from God, and every spirit that does not confess Jesus is not from God. And this is the spirit of the antichrist, of which you have heard that it is coming; and now it is already in the world. — 1 John 4:2–3 NRSV";
const TwoJohn217 =
  "Many deceivers have gone out into the world, those who do not confess that Jesus Christ has come in the flesh; any such person is the deceiver and the antichrist! — 2 John 1:7";
const TwoThe214 =
  "As to the coming of our Lord Jesus Christ and our being gathered together to him, we beg you, brothers and sisters, not to be quickly shaken in mind or alarmed, either by spirit or by word or by letter, as though from us, to the effect that the day of the Lord is already here. Let no one deceive you in any way; for that day will not come unless the rebellion comes first and the lawless one is revealed, the one destined for destruction. He opposes and exalts himself above every so-called god or object of worship, so that he takes his seat in the temple of God, declaring himself to be God. — 2 Thessalonians 2:1–4";
const TwoThe2710 =
  "For the mystery of lawlessness is already at work, but only until the one who now restrains it is removed. And then the lawless one will be revealed, whom the Lord Jesus will destroy with the breath of his mouth, annihilating him by the manifestation of his coming. The coming of the lawless one is apparent in the working of Satan, who uses all power, signs, lying wonders, and every kind of wicked deception for those who are perishing, because they refused to love the truth and so be saved. — 2 Thessalonians 2:7–10";
const Mat2424 =
  "For false messiahs and false prophets will appear and produce great signs and omens, to lead astray, if possible, even the elect. — Matthew 24:24";
const Mar132122 =
  "And if anyone says to you at that time, ‘Look! Here is the Messiah!’[a] or ‘Look! There he is!’—do not believe it. False messiahs[b] and false prophets will appear and produce signs and omens, to lead astray, if possible, the elect. — Mark 13:21-22";

const Y21Nov18Jenifer =
  "Our Lord Jesus to Jennifer on November 18th, 2021:\nMy child, I ask My children that each day at the Hour of Mercy, [1] beginning on the first Sunday of Advent and continuing until the Feast Day of My Divine Mercy, that my children would recite one chaplet for those who are not prepared to meet me at their hour of death. I ask My children to also recite one Rosary, particularly at this hour, if possible, praying for protection of families while meditating on the mysteries. I ask My children to recite the St. Michael prayer for strength and protection from the enemy’s attacks. Now go forth and tell the world of my requests, for I am Jesus, and my mercy and justice will prevail. ";

//Miracles
const A1656_Cava_dei_Tirreni = "Cava dei Tirreni, 1656";

export function enLang() {
  return {
    Home,
    Evidence,
    Dominican,
    Cards,
    Header,
    Footer,
    Acutis,
    JesusHeader,
    Holy,
    Obey,
    Medal,
    MedalPar,
    Directory,
    DirectoryPar,
    Map,
    MapPar,
    MedalPage1,
    MedalForm,
    Miracles,
    MiraclesPar,
    Jesus,
    JesusPar,
    Jesusle,
    JesuslePar,
    Disciple,
    DisciplePar,
    Saints,
    SaintsPar,
    Quiz,
    QuizPar,
    Verse,
    VersePar,
    SaintsCards,
    SaintsHeader,
    Start,
    Restart,
    Share,
    About,
    Mission,
    OneJohn218,
    OneJohn222,
    OneJohn423,
    TwoJohn217,
    TwoThe214,
    TwoThe2710,
    Mat2424,
    Mar132122,
    Y21Nov18Jenifer,

    //Miracles
    A1656_Cava_dei_Tirreni,
  };
}

const Cards = [
  {
    date: "Timeless",
    title: "The Creation of everything",
    subtitle: "Universe",
    par: "God creates everything. Gen 1",
    image: Roots().Timeless,
  },
  {
    date: "~ 6000 BC",
    title: "Adam and Eve",
    subtitle: "Eden",
    par: "The Garden. Gen 2-4",
    image: Roots().BC6000,
  },
  {
    date: "~ 5700 BC",
    title: "Noah's Arc",
    subtitle: "Middle East",
    par: "God erased humanity with 40 days of rain. God's Covenant With Noah. Gen 6-9",
    image: Roots().BC5700,
  },
  {
    date: "~ 1900 BC",
    title: "God talks to Abraham",
    subtitle: "Palestine",
    par: "Obidience is always rewarded. God's Covenant With Abraham. Gen 12",
    image: Roots().BC1900,
  },
  {
    date: "~ 1850 BC",
    title: "The binding of Isaac",
    subtitle: "Canaan",
    par: "Obidience is always rewarded. Gen 22",
    image: Roots().BC1850,
  },
  {
    date: "~ 1800 BC",
    title: "Jacob is named Israel",
    subtitle: "Canaan",
    par: "God names Jacob Israel. Gen 32:28",
    image: Roots().BC1800,
  },
  {
    date: "~ 1750 BC",
    title: "Joseph is the second in Egypt",
    subtitle: "Egypt",
    par: "The power of dreams. God talks to people thru dreams. Gen 45",
    image: Roots().BC1750,
  },
  {
    date: "~ 1250 BC",
    title: "Moses ten plagues",
    subtitle: "Egypt",
    par: "The power of God. Ex 8",
    image: Roots().BC1250,
  },
  {
    date: "~ 1000 BC",
    title: "Reign of David",
    subtitle: "Canaan",
    par: "David is annointed by Samuel. Sam 16",
    image: Roots().BC1000,
  },
  {
    date: "~ 550 BC",
    title: "Babylonian exile",
    subtitle: "Canaan",
    par: "The Jews taken into exile in Babylonia after the fall of Jerusalem. Jeremiah 20",
    image: Roots().BC550,
  },
  {
    date: "0",
    title: "The Promised Christ is born",
    subtitle: "Bethelem",
    par: "The son of god is born. Mt 1",
    image: Roots().AC0,
  },
  {
    date: "33 AC",
    title: "The Promised Christ",
    subtitle: "Jerusalem",
    par: "The son of god is crucified. Mt 20:28",
    image: Roots().AC33,
  },
  {
    date: "37 AC",
    title: "Conversion of Paul (Saul of Tarsus)",
    subtitle: "Palestine",
    par: "Saul of Tarsus in the Road to Damascus, Paul. Acts 9",
    image: Roots().AC37,
  },
  {
    date: "12 October 40 AC",
    title:
      "The First Apparition of The Blessed Virgin Mary. Our Lady of the Pillar",
    subtitle: "Zaragoza, Spain",
    par: "Mary, while living in Jerusalem, supernaturally appeared to the Apostle James the Greater in AC 40 while he was preaching in what is now Spain",
    image: Roots().AC40,
  },
  {
    date: "331 AC",
    title: "The Fifty Bibles of Constantine",
    subtitle: "Constantinople",
    par: "Constantine was the first Roman emperor to convert to Christianity.",
    image: Roots().AC331,
  },
  {
    date: "405 AC",
    title: "Saint Jerome's Bible",
    subtitle: "Bethlehem",
    par: "By 390 he turned to translating the Hebrew Bible from the original Hebrew. He completed this work by 405.",
    image: Roots().AC405,
  },
  {
    date: "480 AC",
    title: "Saint Benedict of Nursia",
    subtitle: "Norcia, Umbria",
    par: "Benedict founded twelve communities for monks. Benedict's main achievement, his 'Rule of Saint Benedict', contains a set of rules for his monks to follow.",
    image: Roots().AC480,
  },
  {
    date: "1182 AC",
    title: "Saint Francis of Assisi",
    subtitle: "Assisi",
    par: "He founded the men's Order of Friars Minor, the women's Order of St. Clare, the Third Order of St. Francis and the Custody of the Holy Land. Francis is one of the most venerated religious figures in Christianity. Francis is said to have had a vision on or about 13 September 1224, the Feast of the Exaltation of the Cross, as a result of which he received the stigmata.",
    image: Roots().AC1182,
  },
  {
    date: "16 July 1194",
    title: "Saint Clare of Assisi",
    subtitle: "Assisi",
    par: "She was an Italian saint and one of the first followers of Francis of Assisi. She founded the Order of Poor Ladies, a monastic religious order for women in the Franciscan tradition, and wrote their Rule of Life, the first set of monastic guidelines known to have been written by a woman.",
    image: Roots().AC1194,
  },
  {
    date: "1415",
    title: "Saint Benedict Medal",
    subtitle: "St. Michael's Abbey",
    par: "The Saint Benedict Medal is a Christian sacramental medal containing symbols and text related to the life of Saint Benedict of Nursia.",
    image: Roots().AC1415,
  },
  {
    date: "27 November 1830",
    title: "The Miraculous Medal - Saint Catherine Labouré",
    subtitle: "Paris, France",
    par: "The Miraculous Medal, also known as the Medal of Our Lady of Graces, is a devotional medal, the design of which was originated by Saint Catherine Labouré following her apparitions of the Blessed Virgin Mary in Rue du Bac, Paris, France. Revelation 12 (And there appeared a great wonder in heaven; a woman clothed with the sun, and the moon under her feet, and upon her head a crown of twelve stars)",
    image: Roots().AC1830,
  },
  {
    date: "25 May 1887",
    title: "Saint Padre Pio of Pietrelcina",
    subtitle: "Pietrelcina, Benevento",
    par: "Padre Pio became famous for exhibiting stigmata for most of his life, thereby generating much interest. He was both beatified and canonized by Pope John Paul II.",
    image: Roots().AC1887,
  },
  {
    date: "25 August 1905",
    title: "Saint Faustina Kowalska",
    subtitle: "Glogowiec",
    par: "Throughout her life, Kowalska reported having visions of Jesus and conversations with him, which she noted in her diary, later published as The Diary of Saint Maria Faustina Kowalska: Divine Mercy in My Soul.",
    image: Roots().AC1905,
  },
  {
    date: "13 May 1917 - 13 Sep 1917",
    title: "Our Lady of Fátima",
    subtitle: "Cova da Iria, Fátima",
    par: "Our Lady of Fátima, is a Catholic title of Mary, mother of Jesus based on the Marian apparitions reported in 1917 by three shepherd children, Lucia, Francisco and Jacinta at the Cova da Iria, in Fátima, Portugal.",
    image: Roots().AC1917,
  },
  {
    date: "1961 - 1965",
    title: "Blessed Virgin Mary Garabandal apparitions",
    subtitle: "Garabandal, Spain",
    par: "The Garabandal apparitions are apparitions of Saint Michael the Archangel and the Blessed Virgin Mary that are claimed to have occurred from 1961 to 1965 to four young schoolgirls in the rural village of San Sebastián de Garabandal in the Peña Sagra mountain range in the autonomous community of Cantabria in Northern Spain.",
    image: Roots().AC1961,
  },
  {
    date: "1981 - Present",
    title: "Our Lady of Medjugorje",
    subtitle: "Medjugorje, Bosnia and Herzegovina",
    par: "Our Lady of Medjugorje, also called Queen of Peace and Mother of the Redeemer, is the title given to the visions of the Blessed Virgin Mary which began in 1981 to six Croatian teenagers in Medjugorje, Bosnia and Herzegovina",
    image: Roots().AC1981,
  },
];

const SaintsCards = [
  {
    date: "230 AC",
    title: "Saint Cecilia of Rome",
    subtitle: "Rome",
    par: "Roman martyr venerated in Catholic, Orthodox, Anglican, and some Lutheran churches, such as the Church of Sweden. She became the patroness of music and musicians, it being written that, as the musicians played at her wedding, Cecilia sang in her heart to the Lord. Musical compositions are dedicated to her, and her feast, on 22 November.",
    image: Roots().AC230,
  },
  {
    date: "405 AC",
    title: "Saint Jerome's Bible",
    subtitle: "Bethlehem",
    par: "By 390 he turned to translating the Hebrew Bible from the original Hebrew. He completed this work by 405.",
    image: Roots().AC405,
  },
  {
    date: "480 AC",
    title: "Saint Benedict of Nursia",
    subtitle: "Norcia, Umbria",
    par: "Benedict founded twelve communities for monks. Benedict's main achievement, his 'Rule of Saint Benedict', contains a set of rules for his monks to follow.",
    image: Roots().AC480,
  },
  {
    date: "1070 AC",
    title: "Saint Isidore the Farmer",
    subtitle: "Madrid, Spain",
    par: "Known for his piety toward the poor and animals. He is the Catholic patron saint of farmers. In the morning before going to work, Isidore would usually attend Mass at one of the churches in Madrid. One day, his fellow farm workers complained to their master that Isidore was always late for work in the morning. Upon investigation the master found Isidore at prayer while an angel was doing the ploughing for him.",
    image: Roots().AC1070,
  },
  {
    date: "1182 AC",
    title: "Saint Francis of Assisi",
    subtitle: "Assisi",
    par: "He founded the men's Order of Friars Minor, the women's Order of St. Clare, the Third Order of St. Francis and the Custody of the Holy Land. Francis is one of the most venerated religious figures in Christianity. Francis is said to have had a vision on or about 13 September 1224, the Feast of the Exaltation of the Cross, as a result of which he received the stigmata.",
    image: Roots().AC1182,
  },
  {
    date: "16 July 1194",
    title: "Saint Clare of Assisi",
    subtitle: "Assisi",
    par: "She was an Italian saint and one of the first followers of Francis of Assisi. She founded the Order of Poor Ladies, a monastic religious order for women in the Franciscan tradition, and wrote their Rule of Life, the first set of monastic guidelines known to have been written by a woman.",
    image: Roots().AC1194,
  },
  {
    date: "27 November 1830",
    title: "The Miraculous Medal - Saint Catherine Labouré",
    subtitle: "Paris, France",
    par: "The Miraculous Medal, also known as the Medal of Our Lady of Graces, is a devotional medal, the design of which was originated by Saint Catherine Labouré following her apparitions of the Blessed Virgin Mary in Rue du Bac, Paris, France. Revelation 12 (And there appeared a great wonder in heaven; a woman clothed with the sun, and the moon under her feet, and upon her head a crown of twelve stars)",
    image: Roots().AC1830,
  },
  {
    date: "25 May 1887",
    title: "Saint Padre Pio of Pietrelcina",
    subtitle: "Pietrelcina, Benevento",
    par: "Padre Pio became famous for exhibiting stigmata for most of his life, thereby generating much interest. He was both beatified and canonized by Pope John Paul II.",
    image: Roots().AC1887,
  },
  {
    date: "25 August 1905",
    title: "Saint Faustina Kowalska",
    subtitle: "Glogowiec",
    par: "Throughout her life, Kowalska reported having visions of Jesus and conversations with him, which she noted in her diary, later published as The Diary of Saint Maria Faustina Kowalska: Divine Mercy in My Soul.",
    image: Roots().AC1905,
  },
];
