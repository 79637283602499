import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Card from "./Card"
import { LangOb } from '../static/Languages';

function VerticalTimelineComp(props) {
  
  function renderTimeline() {
    let cards = [];
    for (let i = 0; i < props.cards.length; i++) {
      const element = props.cards[i];
      cards.push(
        <Card
          date={element.date}
          title={element.title}
          subtitle={element.subtitle}
          par={element.par}
          image={element.image}
        />
      );
    }
    return cards;
  };

  return (
    <div style={{ backgroundColor: "lightBlue" }}>
      <VerticalTimeline >
        <header className="Main-header">
          <h1 style={{ display: "flex", justifyContent: "center", fontSize: "2em" }}>{props.header}</h1>
        </header>
        {renderTimeline()}
      </VerticalTimeline>
    </div>
  );
}

function GeneralTimeline() {
  return (<VerticalTimelineComp cards={LangOb().Cards} header={LangOb().Header} />)
}

function SaintsTimeline() {
  return (<VerticalTimelineComp cards={LangOb().SaintsCards} header={LangOb().SaintsHeader} />)
}

export { GeneralTimeline, SaintsTimeline };