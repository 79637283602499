let tasks = [
  {
    id: 'agree',
    question: 'Do you want to be a Disciple of Jesus Christ?',
    title: 'Disciple Agreement',
    desc: 'I want to be a Disciple of Jesus Christ',
    instruction:
      'Then Jesus said to his disciples, "Whoever wants to be my disciple must deny themselves and take up their cross and follow me." Matthew 16:24',
  },
  {
    id: 'ourFather',
    question: 'Pray Our Father everyday',
    title: 'Our Father',
    desc: 'I pray everyday Our Father',
    instruction:
      'Our Father, Who art in heaven, hallowed be Thy name; Thy kingdom come; Thy will be done on earth as it is in heaven. Give us this day our daily bread; and forgive us our trespasses as we forgive those who trespass against us; and lead us not into temptation, but deliver us from evil',
  },
  {
    id: 'gospelsRead',
    question: 'Read the Gospels everyday',
    title: 'Daily Gospels',
    desc: 'I read everyday the Gospels',
    instruction:
      'Read one page of the Gospels daily, early in the morning as soon you wake up. That will keep the influence of the evil away from you all day.',
  },
  {
    id: 'bless',
    question: 'Would you bless everyone?',
    title: 'Start blessing the world!',
    desc: 'I bless everyone',
    instruction:
      'Bless everyone that comes your way. Make it a priority, then will become a habit, lastly a virtue. Proverbs 11:25 “Whoever brings blessing will be enriched, and one who waters will himself be watered.”',
  },
  {
    id: 'james4:8',
    question: 'Do you want to get close to God?',
    title: 'Approach God',
    desc: 'I wrote 7 actions to get near God',
    instruction:
      'Sit down and make a list of 7 actions you can do to get closer to God. James 4:8 Come near to God and he will come near to you',
  },
  {
    id: 'matthewGospelsRead',
    question: 'Would you read Matthew Gospels everyday?',
    title: 'One Chapter of Matthew Daily',
    desc: 'I read everyday One Chapter of Matthew',
    instruction:
      'Read one chapter of the Matthew Gospels daily, early in the morning as soon you wake up. It has 28 chapters. You will be done in 4 weeks!',
  },
  {
    id: 'startJournal',
    question: 'Would you start a spiritual journal?',
    title: 'Starting my spiritual journal',
    desc: 'Now I have a spiritual journal',
    instruction:
      'God talks to you thru your faith and daily life. God uses everything to convey you to heaven. Write everything here specially your dreams. Ask the Eternal Father in the name of his son Jesus Christ, to give you the interpreation of the dream',
  },
  {
    id: 'readRomans6',
    question: 'Would you read Romans Chapter 6?',
    title: 'One Chapter of Matthew Daily',
    desc: 'I read Romans Chapter 6',
    instruction:
      'Dead to Sin, Alive to God. Please read it all times you need. Jesus Christ died so you can be set free from the real slavery: sin.',
  },
  {
    id: 'stopSinAgree',
    question: 'Do you want to be set free of Sin?',
    title: 'Sin Freedom Agreement',
    desc: 'I want to be free of Sin forever',
    instruction:
      'Write an agreement on a sheet of paper and signing it. Stating you will be from now on free from sin. Dying to sin and becoming a slave of righteousness. Read Romans 6 again please.',
  },
  {
    id: 'write10Comm',
    question: 'Would you write the Ten Commandments?',
    title: 'Ten Commandments',
    desc: 'I wrote the Ten Commandments',
    instruction:
      'In the front page of your spiritual journal write the Ten Commandments',
  },
  {
    id: 'morningRoutine',
    question: 'Do you have a Morning Routine?',
    title: 'Morning Routine',
    desc: 'I created a Morning Routine',
    instruction:
      'In your spiritual journal write the Morning Routine. Items can be added there: Pray, Read the Gospels, Reflect, Silence, Peace, Enjoy nature, Working out, Go out for a walk, Listen to the Gospels while work out',
  },
  {
    id: 'writeJesus2Comm',
    question: 'Would you write Jesus Two Commandments?',
    title: 'Jesus Two Commandments',
    desc: 'I wrote Jesus Two Commandments',
    instruction:
      'In the front page of your spiritual journal write the Jesus Two Commandments. Title: Jesus Two Commandments. “Jesus replied: "Love the Lord your God with all your heart and with all your soul and with all your mind." This is the first and greatest commandment. And the second is like it: "Love your neighbour as yourself."',
  },
  {
    id: 'listenToJames',
    question: 'Would you listen to James Epistle?',
    title: 'James Epistle',
    desc: 'I have listened to the Epistle of James',
    instruction:
      'You need only 15min to listen to it. https://www.youtube.com/watch?v=vGlLthMA2Jw',
  },
  {
    id: 'cutViceGrowVirtue',
    question: 'Are you ready to turn your life around?',
    title: 'Cut Vice, Grow Virtues',
    desc: 'I am cutting all vices and growing all virtues',
    instruction:
      'You need to cut all this capital sins: pride, greed, wrath, envy, lust, gluttony and sloth, and grow this virtues: chastity, temperance, charity, diligence, kindness, patience, and humility',
  },
  {
    id: 'lustChastity',
    question: 'Are you ready to turn lust into chastity?',
    title: 'Cut Lust, Grow Chastity',
    desc: 'I am cutting lust and growing chastity',
    instruction:
      'Chastity, also known as purity, is a virtue related to temperance. Someone who is chaste refrains either from carnal activity considered immoral or any carnal activity, according to their state of life.',
  },
  {
    id: 'glutonyTemperance',
    question: 'Are you ready to turn glutony into temperance?',
    title: 'Cut Glutony, Grow Temperance',
    desc: 'I am cutting glutony and growing temperance',
    instruction:
      'Temperance in its modern use is defined as moderation or voluntary self-restraint. It is typically described in terms of what an individual voluntarily refrains from doing.',
  },
  {
    id: 'greedCharity',
    question: 'Are you ready to turn greed into charity?',
    title: 'Cut Greed, Grow Charity',
    desc: 'I am cutting greed and growing charity',
    instruction:
      'The practice of charity is the voluntary giving of help to those in need, as a humanitarian act, unmotivated by self-interest.',
  },
  {
    id: 'slothDiligence',
    question: 'Are you ready to turn sloth into diligence?',
    title: 'Cut Sloth, Grow Diligence',
    desc: 'I am cutting sloth and growing diligence',
    instruction:
      'Diligence—carefulness and persistent effort or work—is one of the seven heavenly virtues. It is indicative of a work ethic, the belief that work is good in itself.',
  },
  {
    id: 'wrathPatience',
    question: 'Are you ready to turn wrath into patience?',
    title: 'Cut Wrath, Grow Patience',
    desc: 'I am cutting wrath and growing patience',
    instruction:
      'Patience is the ability to endure difficult circumstances. Patience may involve perseverance in the face of delay; tolerance of provocation without responding in disrespect/anger; or forbearance when under strain, especially when faced with longer-term difficulties.',
  },
  {
    id: 'envyKindness',
    question: 'Are you ready to turn envy into kindness?',
    title: 'Cut Envy, Grow Kindness',
    desc: 'I am cutting envy and growing kindness',
    instruction:
      'Kindness is a type of behavior marked by acts of generosity, consideration, rendering assistant or concern for others, without expecting praise or reward in return.',
  },
  {
    id: 'prideHumility',
    question: 'Are you ready to turn pride into humility?',
    title: 'Cut Pride, Grow Humility',
    desc: 'I am cutting pride and growing humility',
    instruction:
      'Do nothing out of selfish ambition or vain conceit. Rather, in humility value others above yourselves, not looking to your own interests but each of you to the interests of the others. In your relationships with one another, have the same mindset as Christ Jesus.',
  },
  //Intro to all Rosary prayers
  {
    id: 'iBelieveInGod',
    question: 'Pray I Believe in God everyday',
    title: 'I Believe in God',
    desc: 'I pray everyday I Believe in God',
    instruction:
      'I believe in God, the Father Almighty, Creator of heaven and earth, and in Jesus Christ, His only Son, our Lord, who was conceived by the Holy Spirit, born of the Virgin Mary, suffered under Pontius Pilate,was crucified, died and was buried; He descended into hell; on the third day He rose again from the dead; He ascended into heaven, and is seated at the right hand of God the Father Almighty; from there He will come to judge the living and the dead. I believe in the Holy Spirit, the Holy Catholic Church, the communion of Saints, the forgiveness of sins, the resurrection of the body, and life everlasting. Amen.',
  },
  {
    id: 'gloryBe',
    question: 'Pray Glory Be everyday',
    title: 'Glory Be',
    desc: 'I pray everyday Glory Be',
    instruction:
      'Glory be to the Father, and to the Son, and to the Holy Spirit, as it was in the beginning, is now, and ever shall be, world without end. Amen.',
  },
  {
    id: 'fatimaPrayer',
    question: 'Pray Fatima Prayer everyday',
    title: 'Fatima Prayer',
    desc: 'I pray everyday Fatima Prayer',
    instruction:
      'O my Jesus, forgive us our sins, save us from the fires of hell, and lead all souls to Heaven, especially those in most need of Thy mercy. Amen.',
  },
  {
    id: 'hailHolyQueen',
    question: 'Pray Hail Holy Queen everyday',
    title: 'Hail Holy Queen',
    desc: 'I pray everyday Hail Holy Queen',
    instruction:
      'Hail, holy Queen, Mother of mercy, hail, our life, our sweetness and our hope. To thee do we cry, poor banished children of Eve: to thee do we send up our sighs, mourning and weeping in this vale of tears. Turn then, most gracious Advocate, thine eyes of mercy toward us, and after this our exile, show unto us the blessed fruit of thy womb, Jesus, O merciful, O loving, O sweet Virgin Mary! Amen.',
  },
  {
    id: 'oGodWhose',
    question: 'Pray O God Whose everyday',
    title: 'O God Whose',
    desc: 'I pray everyday O God Whose',
    instruction:
      'O God, whose only begotten Son, by His life, death, and resurrection, has purchased for us the rewards of eternal salvation. Grant, we beseech Thee, that while meditating on these mysteries of the most holy Rosary of the Blessed Virgin Mary, that we may both imitate what they contain and obtain what they promise, through Christ our Lord. Amen.',
  },
  //The Five Stones of Medjugorje
  {
    id: 'prayTheRosary',
    question: 'Are you ready to Pray the Rosary?',
    title: 'Learn how to pray the Rosary',
    desc: 'I pray one Rosary today',
    instruction:
      'Read this carefully and do a whole Rosary, keep track of the Hail Marys with your fingers. newadvent.org/images/rosary.pdf',
  },
  {
    id: 'fasting',
    question: 'Are you ready to start Fasting?',
    title: 'Lets fast on bread and water',
    desc: 'I am fasting on bread and water',
    instruction:
      'Let the people perform strict fasting on Wednesdays and Fridays, except the sick”, “The best fasting is on bread and water.',
  },
  {
    id: 'readBible',
    question: 'Are you ready to read the Bible everyday?',
    title: 'Reading the Holy Scripture every day',
    desc: 'I am Reading the Holy Scripture every day',
    instruction: 'This is key to get closer to the unique true God.',
  },
  {
    id: 'confession',
    question: 'Are you ready to Confess once a month?',
    title: 'Confession once a month',
    desc: 'I am confessing once a month',
    instruction: 'In confession, many blessings are received.',
  },
  {
    id: 'holyMass',
    question: 'Are you ready to go to Holy Mass?',
    title: 'Go to Holy Mass Weekly',
    desc: 'I am going to Holy Mass Weekly',
    instruction: 'The Holy Mass is the climax of prayer.',
  },
  //pray daily rosary
  {
    id: 'prayTheRosaryDaily',
    question: 'Are you ready to Pray the Rosary daily?',
    title: 'Pray the Rosary daily',
    desc: 'I pray one Rosary daily',
    instruction:
      'Fatima Portugal 1917, Virgin Mary: Pray the Rosary every day to achieve peace in the world and the end of war.',
  },
]

export function Tasks() {
  console.log('tasks.length', tasks.length)
  return tasks
}
