import { enLang } from "./En";
import { esLang } from "./Es";
import { itLang } from "./It";
import { LangF } from "../elements/Main";

import * as EN from "./En2";
import * as ES from "./Es2";
import * as IT from "./It2";

export function LangOb() {
  if (LangF() === "it") return itLang();
  if (LangF() === "es") return esLang();
  return enLang();
}

export function GetSt(string) {
  if (LangF() === "it") return IT[string];
  if (LangF() === "es") return ES[string];
  return EN[string];
}

//Siempre tenlo presente, que entre mas dura la prueba Mayor sera la Bendicion. Padre Pio
//Jesus no te pide que lleves con el la pesada cruz, sino solo un pequenno trozo de ella. Padre Pio
//Huye de la tristeza, porque esta entra en los corazones que estan apegados a las cosas mundanas. Padre Pio
//Recuerda que uno no gana la batalla sin oracion. La eleccion es tuya. Padre Pio

// 1. La sociedad de hoy no reza, por eso se está desmoronando.

// 2. La oración es la mejor arma que poseemos, la llave que abre el corazón de Dios.

// 3. Ora, espera y no te preocupes. La preocupación es inútil. Nuestro Señor misericordioso escuchará tu oración.

// 4. Sería más fácil para el mundo existir sin el sol que sin la Santa Misa.

// 5. Mil años de disfrutar de la gloria humana no valen ni una hora en dulce comunión con Jesús en el Santísimo Sacramento.

// 6. En la vida espiritual, el que no avanza retrocede. Sucede como con un barco que siempre debe seguir adelante. Si se detiene, el viento lo devolverá.

// 7. Debes hablar a Jesús también con el corazón, además de los labios; de hecho, en ciertos casos debes hablar con Él solo con el corazón.

// 8. Siempre debemos tener coraje, y si nos llega alguna languidez espiritual, corramos a los pies de Jesús en el Santísimo Sacramento y ubiquémonos en medio de los perfumes celestiales, y sin duda recuperaremos nuestra fuerza.

// 9. ¿Hace algún tiempo que no amas al Señor? ¿No lo amas ahora? ¿No anhelas amarlo para siempre? Por lo tanto, ¡no temas! Aún admitiendo que has cometido todos los pecados de este mundo, Jesús te repite: “¡Muchos pecados te son perdonados porque has amado mucho!”.

// 10. No te preocupes por las cosas que generan preocupación, desorden y ansiedad. Una sola cosa es necesaria: Elevar tu espíritu y amar a Dios.

// 11. Donde no hay obediencia, no hay virtud; no hay bondad ni amor. Y donde no hay amor, no hay Dios. Sin Dios, no podemos alcanzar el Cielo. Estas virtudes forman una escalera; si falta un paso, nos caemos.

// 12. Los mejores medios para protegerte de la tentación son los siguientes: cuida tus sentidos para salvarlos de la tentación peligrosa, evita la vanidad, no dejes que tu corazón se exalte, convéncete del mal de la complacencia, huye del odio, reza cuando sea posible. Si el alma supiera el mérito que uno adquiere en las tentaciones sufridas en la paciencia y conquistado, estaría tentado a decir: Señor, envíame tentaciones.

// 13. Es necesario proteger todos tus sentidos, especialmente tus ojos: son los medios por los cuales toda la fascinación y el encanto de la belleza y la voluptuosidad entran en el corazón. Cuando la moda, como en nuestro tiempo, es hacia la provocación y expone lo que antes era incorrecto pensar, se debe tener precaución y autocontrol. Siempre que sea necesario, debes mirar sin ver y ver sin pensarlo.

// 14. Debes recordar que tienes en el Cielo no solo un Padre sino también una Madre. Entonces recurramos a María. Ella es toda dulzura, misericordia, bondad y amor para nosotros porque es nuestra Madre.

// 15. El amor y el miedo deben ir unidos, el miedo sin amor se convierte en cobardía. El amor sin miedo se convierte en presunción. Cuando hay amor sin miedo, el amor corre sin prudencia y sin restricción, sin preocuparse por dónde va.
