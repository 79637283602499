import * as React from 'react'
import ActionAreaCard from './ActionAreaCard'
import { Roots } from '../static/Root'
import { LangOb } from '../static/Languages'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'

export default function Directory() {
  return (
    <div
      style={{
        backgroundColor: 'lightBlue',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '',
        flexDirection: 'column',
        padding: '3%',
      }}
    >
      <h2>Catholic Churches Directory</h2>
      <h3>Kitchener</h3>
      <p>
        <StickyHeadTable />
      </p>
    </div>
  )
}

const columns = [
  { id: 'name', label: 'Name' },
  { id: 'website', label: 'Web\u00a0Site' },
  { id: 'email', label: 'Email' },
  {
    id: 'phone',
    label: 'Phone',
    // align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'address',
    label: 'Address',
    format: (value) => value.toLocaleString('en-US'),
  },
]

function createData(name, website, email, phone, address) {
  return { name, website, email, phone, address }
}

const rows = [
  //Kitchener
  createData(
    "St. Mark's Catholic Church",
    'stmarkcatholicparish.org',
    '',
    '(519) 571-9200',
    '55 Driftwood Dr, Kitchener, ON N2N 3A2',
  ),
  createData(
    "St. Teresa's Catholic Church",
    'stteresakit.ca',
    '',
    '(519) 743-4525',
    '44 Leonard St, Kitchener, ON N2H 6C8',
  ),
  createData(
    'Sacred Heart Church',
    'kitchenercr.com',
    'heartparish@gmail.com',
    '(519) 742-5831',
    '66 Shanley St, Kitchener, ON N2H 5N9',
  ),
  createData(
    'St. Mary Our Lady of the Seven Sorrows Church',
    'stmarysrcchurch.ca',
    'stmarys@rogers.com',
    '(519) 576-3860',
    '56 Duke St W, Kitchener, ON N2H 3W7',
  ),
  createData(
    'St. Aloysius Parish',
    'staloysius.on.ca',
    'info@staloysius.on.ca',
    '(519) 893-1220',
    '11 Traynor Ave, Kitchener, ON N2C 1W1',
  ),
  createData(
    'Blessed Sacrament Parish',
    'blessed-sacrament.ca',
    'blessedsacrament1977@rogers.com',
    '(519) 742-5061',
    '305 Laurentian Dr, Kitchener, ON N2E 2N6',
  ),
  createData(
    'St. Anthony Daniel Parish',
    'stanthonydaniel.ca',
    'stanthonydanielkitchener@hamiltondiocese.com',
    '(519) 893-6960',
    '29 Midland Dr, Kitchener, Ontario  N2A 2A9',
  ),
  createData(
    "St. John's Parish",
    'stjohnskitchener.ca',
    'office@stjohnskitchener.ca',
    '(519) 745-7855',
    '85 Strange St, Kitchener, ON N2G 1R4',
  ),
  createData(
    'Parroquia Nuestra Señora de Guadalupe',
    'parroquiaguadalupe.ca',
    'office@stjohnskitchener.ca',
    '(519) 745-7855',
    '85 Strange St, Kitchener, ON N2G 1R4',
  ),
  createData(
    'St Anne Parish',
    'stanneskitchener.ca',
    'stannekitchener@hamiltondiocese.com',
    '(519) 745-5302',
    '268 East Ave, Kitchener, ON N2H 1Z4',
  ),
]

function StickyHeadTable() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 540 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
