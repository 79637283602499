import React, { useState, useEffect } from 'react'
import ActionAreaCard from '../ActionAreaCard'
import { Roots } from '../../static/Root'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { LangF } from '../Main'

// https://es.churchpop.com/2022/09/11/video-viral-pueblo-se-conmociona-por-una-imagen-de-la-virgen-que-lloraria-aceite/
export default function D2022_09_11_Sonora() {
  return (
    <div
      style={{
        backgroundColor: 'lightBlue',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '',
        flexDirection: 'column',
        padding: '3%',
      }}
    >
      <Typography
        style={{ textAlign: 'center', padding: '0' }}
        gutterBottom
        variant="h4"
        component="div"
      >
        {LangF() === 'es' && `Imagen de la Virgen María llora aceite.`}
        {LangF() === 'en' && `Image of the Virgin Mary cries oil.`}
        {LangF() === 'it' && `Immagine della Vergine Maria piange olio.`}
      </Typography>

      <Grid container style={{ padding: '5%', alignItems: 'center' }}>
        <iframe
          width="100%"
          height="300"
          src="https://www.youtube.com/embed/8pU6i184TUc"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Grid>

      <Typography
        style={{ textAlign: 'center' }}
        gutterBottom
        variant="h4"
        component="div"
      >
        {LangF() === 'es' && `Iglesia de San Felipe de Jesus`}
        {LangF() === 'en' && `Church of San Felipe de Jesus`}
        {LangF() === 'it' && `Chiesa di San Felipe de Jesus`}
        <br />
        Sonora, Mexico
        <br />
        Sep 11, 2022
      </Typography>

      <br />
    </div>
  )
}
