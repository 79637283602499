import React, { useState, useEffect } from 'react';
import ActionAreaCard from '../ActionAreaCard';
import { Roots } from '../../static/Root';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { LangF } from '../Main';

export default function D2022_05_26_Ostia_Palpita() {

    return (
        <div style={{ backgroundColor: "lightBlue", display: "flex", justifyContent: "center", alignItems: "center", height: "", flexDirection: "column", padding: "3%" }}>

            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Milagro Eucarístico de Dronero`}        
                {LangF() === 'en' && `Eucharistic Miracle of Dronero`}       
                {LangF() === 'it' && `Miracolo Eucaristico di Dronero`}
            </Typography>
            
            <Grid item lg={4} md={6} sm={12} xs={12}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                    <ActionAreaCard url="/miracles/D1631_08_03_Dronero" img={Roots().D1631_08_03_Dronero}/>
                </div>
            </Grid>

            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h5" component="div" >
                {LangF() === "es" && `En 1631, una joven campesina prendió fuego imprudentementea un poco de paja seca. Esto causóun incendio que ayudado por elviento invadió parte de la ciudad de Dronero. Fue inútil intentar dominar el fuego. El padre capuchino, Mauricio de Ceva, animado por un gran amor a laEucaristía, tomó la custodia conla Hostia Magna de la iglesia deSanta Brígida y se dirigió haciael lugar del incendio. Inmedia-tamente el fuego cesó y el pueblo,conmovido, empezó a alabar aDios por el Milagro concedido.`}        
                {LangF() === 'en' && `In 1631, a young farm girl, foolishly kindled a fire with dryhay. Because of a heavy wind,the fire spread to the town of Dronero. Every attempt to extinguish the flames proved useless. A Capuchin friar,Maurice da Ceva, inspired by his great love of the Blessed Sacrament took the monstrance containing the large Host fromthe Church of St. Brigid and in procession walked to wherethe fire was raging. The fire atonce subsided.`}       
                {LangF() === 'it' && `Miracolo Eucaristico di Nel 1631, una giovane contadina, poco prudentemente, appiccò il fuoco a della paglia secca e da questa divampò subito un incendio che per il fortevento invase tutto il borgodella cittadina di Dronero.Tutti i tentativi di domare il fuoco si rivelarono inutili. Solo dopo che il Padre Maurizío da Ceva impartí la benedizione con il SS. Sacramento, l’incendiocessó miracolosamente.`}
            </Typography>

            <Grid item lg={4} md={6} sm={12} xs={12}>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "7% 6%", height: 220 }}>
                    <ActionAreaCard url="/miracles/D1631_08_03_Dronero" img={Roots().D1631_08_03_Dronero_1}/>
                </div>
            </Grid>
            
            <Typography  style={{ textAlign: "center" , padding: "0"}} gutterBottom variant="h6" component="div" >
                {LangF() === "es" && `Un domingo 3 de agosto de 1631, en tornoa la hora de las Vísperas, se desencadenóun gran incendio en la pequeña ciudadde  Dronero,  en  el  marquesado  de  Saluzzo.  Unajoven campesina, imprudentemente encendió fuegoa un poco de paja seca en el preciso instante en elque  venía  un  fuerte  viento  que  anunciaba  la tempestad. Luego de pocos instantes, las llamas seexpandieron violentamente hasta llegar a las casasdel Barrio Maira. La población luchaba contra elfuego, pero todo tentativo resultaba inútil. Mientrastanto  las  llamas  seguían  avanzando.  El  PadreMaurizio de Ceva, Capuchino, tuvo la inspiraciónde recurrir a la fuerza del Salvador velado bajo lasespecies  eucarísticas.  Organizó  velozmente  unaprocesión  solemne  con  el  Santísimo  Sacramento y  seguido  por  todos  los  ciudadanos,  se  dirigió  al lugar del incendio. Ante la presencia del SantísimoSacramento,  el  fuego  dejó  de  avanzar  milagrosa-mente. Una inscripción en mármol de la iglesita deSanta Brígida en Dronero, describe detalladamenteel  Milagro.  Cada año,  en ocasión  de la  fiesta delCorpus Domini, los ciudadanos de Dronero honranla  memoria  del  Prodigio  con  una  procesión solemne llevando el Santísimo Sacramento.`}        
                {LangF() === 'en' && `In  the  afternoon  of  Sunday,  August  3,1631,  a  great  fire  broke  out  in  the commercial  district  of  Saluzzo  in  thetown  of  Dronero.  A  young  farm  girl  foolishly kindled a fire with dry hay at the very moment anup  wind  was  developing  into  a  thunderstorm.The  flames  quickly  and  violently  spread  to  thehome  of  the  Borgo  Maira.  The  townspeople desperately attempted to extinguish the fire, butall their efforts proved useless as the fire develo-ped further. Friar Maurice da Ceva, a Capuchin,was inspired to have recourse to the power of theLord in the Blessed Sacrament. He immediately organized  a  procession  with  the  Blessed Sacrament  –  followed  by  all  the  townspeople  – to  the  location  of  the  fire.  The  moment  theBlessed  Sacrament  arrived,  the  flames  subsidedand miraculously were extinguished ! This mira-culous  event  is  described  in  details  on  a  stonetablet in the small Church of St. Brigid. On theFeast of Corpus Christi, the citizens of Dronero keep  alive  the  memory  of  this  miracle  with  asolemn  annual  procession  with  the  Blessed Sacrament.`}       
                {LangF() === 'it' && `La domenica del 3 agosto del 1631, versol’ora  dei  Vespri,  si  sprigionò  un  grandeincendio nella cittadina di Dronero, nelmarchesato  di  Saluzzo.  Una  giovane  contadina,imprudentemente  appiccò  il  fuoco  a  della  pagliasecca proprio nel momento in cui il vento si stavaalzando  a  causa  del  sopraggiungere  di  una  tem-pesta. In pochi istanti le fiamme si espansero vio-lentemente  fino  a  raggiungere  le  abitazioni  delBorgo Maira. La popolazione cercò in ogni mododi domare il fuoco, ma ogni tentativo si rivelò vano.Il fuoco intanto aumentava sempre di più. Il PadreMaurizio da Ceva, Cappuccino, ebbe l’inspirazionedi ricorrere alla potenza del Salvatore velato sotto lespecie  eucaristiche.  Subito  organizzò  una  solenneprocessione con il Santissimo Sacramento e seguitoda tutti i cittadini, si diresse nel luogo dell’incendio.All’avanzare del Santissimo Sacramento il fuoco siarrestò miracolosamente. Una lapide presente nellachiesetta  di  Santa  Brigida  a  Dronero  descrive  inmodo dettagliato il Miracolo e ogni anno, in occa-sione della festa del Corpus Domini, i cittadini diDronero onorano la memoria del Prodigio con unasolenne processione con il Santissimo Sacramento.`}
            </Typography>

            <br/>

            <Typography  style={{ textAlign: "center"}} gutterBottom variant="h4" component="div" >
                {LangF() === "es" && `Dronero`}
                {LangF() === "en" && `Dronero`}
                {LangF() === "it" && `Dronero`}
                <br/>
                Italy<br/>
                Aug 3, 1631
            </Typography>
           
            <br />
        </div>
    );
}