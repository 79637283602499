import * as React from "react";
import { Roots } from "../static/Root";
import { LangOb, GetSt } from "../static/Languages";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function Dominican() {
  return (
    <div
      style={{
        backgroundColor: "lightBlue",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "",
        flexDirection: "column",
        padding: "10%",
      }}
    >
      <Typography gutterBottom variant="h5" component="div">
        {GetSt("st479")}
      </Typography>
      <br />
      <Typography gutterBottom variant="h5" component="div">
        {GetSt("st891")}
      </Typography>
      <br />
      <Typography gutterBottom variant="h5" component="div">
        {GetSt("st892")}
      </Typography>
      <br />
      <Typography gutterBottom variant="h5" component="div">
        {GetSt("st893")}
      </Typography>
      <br />
      <br />
      <Typography gutterBottom variant="h5" component="div">
        {GetSt("Aquino")}
      </Typography>

      <br />
    </div>
  );
}
